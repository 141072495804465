import * as i0 from "@angular/core";
export class ErrorService {
    constructor() {
        // ---------------------------------------------------------------------------
        // -----------------------   Key/Value Dictionary   ------------------------------------
        // ---------------------------------------------------------------------------
        this.message = [
            //
            // ngrx effects. /api
            //
            { key: 'failed_account', value: 'Cannot retrieve Accounts List 😞' },
            { key: 'failed_impersonate', value: 'Cannot Impersonate User 👻' },
            { key: 'failed_password', value: 'Username and/or Password is Incorrect' },
            { key: 'failed_setOrg', value: 'Cannot Set Organization 🥺' },
            { key: 'failed_token', value: 'Cannot Resolve Token' },
            { key: 'failed_usernameGet', value: 'Cannot Resolve User record' },
            { key: 'failed_usernameVal', value: 'Cannot Verify User record' },
            { key: 'failed_mfaRequired', value: 'Multifactor Authentication (MFA) is required to access this account' },
            //
            // Resolvers
            //
            { key: 'resolve_password', value: 'Username and/or Password is Incorrect' },
            //
            // oAuth
            //
            { key: 'oidc_discovery_document_loaded', value: '' },
            { key: 'oidc_jwks_load_error', value: '' },
            { key: 'oidc_invalid_nonce_in_state', value: '' },
            { key: 'oidc_discovery_document_load_error', value: '' },
            { key: 'oidc_discovery_document_validation_error', value: '' },
            { key: 'oidc_token_error', value: '' },
            { key: 'oidc_token_refresh_error', value: '' },
            { key: 'oidc_silent_refresh_error', value: '' },
            { key: 'oidc_token_validation_error', value: '' },
            { key: 'oidc_token_expires', value: '' },
            { key: 'oidc_session_error', value: '' },
        ];
    }
    returnError(err) {
        for (let mess of this.message) {
            if (mess.key == err) {
                return mess.value;
            }
        }
        return 'Oops Unknown Error. Please REFRESH 🙂 ';
    }
}
ErrorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorService_Factory() { return new ErrorService(); }, token: ErrorService, providedIn: "root" });
