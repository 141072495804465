import { createAction, props } from '@ngrx/store';
import { GetAccountsData } from 'src/app/models/interfaces';
//
// account
//
export const getAccountsStart = createAction('[account] getAccountsStart');
export const getAccountsSuccess = createAction('[account] getAccountsSuccess',
    props<GetAccountsData>()
    );
export const getAccountsFail = createAction('[account] getAccountsFail',
    props<{ errorMessage: string }>()
    );
export const setOrgCodeStart = createAction('[account] setOrgCodeStart',

props<{ orgCode: string, orgCodes: string[], RoleId: string }>()
 
    );
export const setOrgCodeSuccess = createAction('[account] setOrgCodeSuccess',
    props<{ accountType: string }>()
    );
export const setOrgCodeFail = createAction('[account] setOrgCodeFail',
    props<{ errorMessage: string }>()
    );
//
// impersonate
//
export const getUsernameStart = createAction('[Impersonate] getUsernameStart');
export const getUsernameSuccess = createAction('[Impersonate] getUsernameSuccess',
    props<{ username: string; supportUsername: string, isSupportAdmin: boolean }>()
    );
export const getUsernameFail = createAction('[Impersonate] getUsernameFail',
    props<{ errorMessage: string }>()
    );
export const impersonateUserStart = createAction('[Impersonate] impersonateUserStart',
    props<{ username: string, overrideSupport: boolean }>()
    );
export const impersonateUserSuccess = createAction('[Impersonate] impersonateUserSuccess',
    props<{ username: string }>()
    );
export const impersonateUserFail = createAction('[Impersonate] impersonateUserFail',
    props<{ errorMessage: string }>()
    );
//
// password
//
export const validatePasswordStart = createAction('[password] validatePasswordStart',
    props<{ username: string; password: string; }>());

export const validatePasswordSuccess = createAction('[password] validatePasswordSuccess',
    props<{ username: string; isSupportUser: boolean; enableFullContractor: boolean; isEmailContractorUser: boolean }>());

export const validatePasswordFail = createAction('[password] validatePasswordFail',
    props<{ errorMessage: string; }>());
