import { Injectable } from '@angular/core';
import { environment } from 'src/app/../environments/environment';
import * as contentful from 'contentful';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContentfulService {
  private client = contentful.createClient({
    space : environment.contentful.spaceId,
    accessToken : environment.contentful.token
  });
  constructor() { }
  //
  // Contentful Client API
  // https://contentful.github.io/contentful.js/contentful/7.13.1/ContentfulClientAPI.html
  //
  getEntries_ContentTypeId(id) {
    return this.client.getEntries({
      'content_type': id,
    });
  }
  //
  // Get BOTH Background / Login Async
  //
  fetchContentful() {
    const content = async ()=> {
      const loginAd = await this.getEntries_ContentTypeId('loginAdvertisement');
      const backgroundImage = await this.getEntries_ContentTypeId('backgroundImage');
       return Promise.all([loginAd, backgroundImage]);
    }
    content().then(res => {
      // console.log(res);
      this.setAd(res[0]);
      let random = Math.floor(Math.random() * res[1].items.length);
      this.setBack(res[1].items[random]);
      localStorage.setItem('contentful-entries', JSON.stringify(res));
    })
  }
  //
  // Shared Services.
  //
  public back$ = new BehaviorSubject<any>(null);
  public getBack() {
    return this.back$.asObservable();
  }
  public setBack(value) {
    this.back$.next(value);
  }
  public ad$ = new BehaviorSubject<any>(null);
  public getAd() {
    return this.ad$.asObservable();
  }
  public setAd(value) {
    this.ad$.next(value);
  }
}
