import { authNet6ConfigCode } from './../../auth.net6.config.code';
import { Router } from '@angular/router';
import { JwksValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { Component, OnInit } from '@angular/core';
import { authNet6ConfigImplicit } from '../../auth.net6.config.implicit';
import { LcpService } from 'src/app/services/lcp/lcp.service';

@Component({
  selector: 'app-sso-net6-callback',
  templateUrl: './sso-net6-callback.component.html',
  styleUrls: ['./sso-net6-callback.component.scss']
})
export class SsoNet6CallbackComponent implements OnInit {

  constructor(
    private oauthService: OAuthService,
    private lcpService: LcpService,
    private router: Router
    ) {
    //
    // testing implicit flow
    //  
    // this.oauthService.configure(authNet6ConfigImplicit)

    this.oauthService.configure(authNet6ConfigCode)
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then(res => {
      console.log(res);
      if (res && this.oauthService.hasValidAccessToken()) {
        console.log('VALID TOKEN REDIRECT TO ACCOUNTS');
        this.router.navigateByUrl('/sso/net6/accounts');
        return Promise.resolve();
      }
      
    });

  }

  ngOnInit() {
    document.body.style.paddingTop = "0px"; // unit measured in Edge.
  }
}
