import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppState } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';
//
// ngrx / rxjs
//
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';


@Component({
  selector: 'app-lcptracker-redirect',
  templateUrl: './lcptracker-redirect.component.html',
  styleUrls: ['./lcptracker-redirect.component.css']
})
export class LcpTrackerRedirectComponent implements OnInit {

  constructor(
    private store: Store<AppState>, 
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    console.log('LcpTrackerRedirectComponent ngOnInit');
    console.log('route: ', this.route);
    this.store.dispatch(AllActions.authLcptracker({ redirect: true }));
    this.store.select('lcptrackerAuth')
      .pipe(
        filter(state => !!state.idToken),
        take(1)
      )
      .subscribe(state => {
        console.log('auth in LcpTrackerRedirectComponent', state);
        this.store.dispatch(AllActions.setIdToken({ idToken: state.idToken }));
        this.store.dispatch(AllActions.validateTokenStart({ token: state.idToken }));
      });
  }
}
