<div class="sso-page-container">
  <div class="sso-page-card sm:mx-2.28">
      <img src="assets/logo.png" alt="LCPtracker Logo">
      <div class="progress-spinner-container">
          <mat-progress-spinner mode="indeterminate" diameter="50">
          </mat-progress-spinner>
      </div>
      <h3>Verification</h3>
      <p>We are verifying your identity...</p>
  </div>
  <footer></footer>
</div>
