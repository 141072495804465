import { Component, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";

interface ValidationMessage {
  type: string;
  message: string;
}

interface FormValidationMessages {
  [x: string]: ValidationMessage[];
}

@Component({
  selector: "app-full-contractor-form",
  templateUrl: "./full-contractor-form.component.html",
  styleUrls: ["./full-contractor-form.component.scss"],
})
export class FullContractorFormComponent implements OnInit {
  // Constants and regular expressions
  FIELD_PHONE_MAX_LENGTH: number = 10;
  FIRST_AND_LAST_NAME_REGEX = /^([a-zA-Z]+[',.-]?[ ]?){1,2}$/;
  PHONE_REGEX = new RegExp(
    `^(?!0{${this.FIELD_PHONE_MAX_LENGTH}})\\d{${this.FIELD_PHONE_MAX_LENGTH}}$`
  );
  EMAIL_TRACKER_REGEX = new RegExp(
    "(^$)|^((([a-zA-Z]|\\d|[!#\\$%&'\\*\\+\\-/=\\?\\^_`{\\|}~]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+(\\.([a-zA-Z]|\\d|[!#\\$%&'\\*\\+\\-\\/=\\?\\^_`{\\|}~]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+)*)|((\\x22)((((\\x20|\\x09)*(\\x0d\\x0a))?(\\x20|\\x09)+)?(([\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x7f]|\\x21|[\\x23-\\x5b]|[\\x5d-\\x7e]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(\\\\([\\x01-\\x09\\x0b\\x0c\\x0d-\\x7f]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF]))))*(((\\x20|\\x09)*(\\x0d\\x0a))?(\\x20|\\x09)+)?(\\x22)))@((([a-zA-Z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(([a-zA-Z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])([a-zA-Z]|\\d|-||_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])*([a-zA-Z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])))\\.)+(([a-zA-Z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+|(([a-zA-Z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+([a-zA-Z]+|\\d|-|\\.{0,1}|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])?([a-zA-Z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])))$"
  );

  newFullContractorOnboarding: FormGroup; // Will store the new Full Contractor Onboarding Form

  formValidationMessages: FormValidationMessages = {
    firstName: [
      { type: 'required', message: 'First name is required' },
      {
        type: 'pattern',
        message: `First name should be from a-Z A-Z. Allowed special chars ('. -,) one time per word and not consecutive. Max two words.`,
      },
    ],
    lastName: [
      { type: 'required', message: 'Last name is required' },
      {
        type: 'pattern',
        message: `Last name should be from a-Z A-Z. Allowed special chars ('. -,) one time per word and not consecutive. Max two words.`,
      },
    ],
    email: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Email is not valid' },
      {
        type: 'pattern',
        message: `Email is not valid. Please enter a valid email.`,
      },
    ],
    confirmEmail: [
      { type: 'required', message: 'Confirm your email address' },
      { type: 'checkEmailIsMatched', message: 'Email confirmation does not match your email address.' },
      {
        type: 'pattern',
        message: `Email is not valid. Please enter a valid email.`,
      },
    ],
    phoneNumber: [
      { type: 'required', message: 'Please provide a contact number to update our system' },
      {
        type: 'pattern',
        message: `Phone should be only numbers. 10 digits length. No white spaces.`,
      },
    ],
  };

  /**
   * Constructor
   *
   * @param _formBuilder,
   * @param _router
   */
  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router
  ) {
  }

  /**
   * Shorthand to handle form controls
   */
  get fullContractorForm(): { [x: string]: AbstractControl } {
    return this.newFullContractorOnboarding.controls;
  }

  /**
   * On init hook
   */
  ngOnInit() {
    this.initFullContractorOnboardingForm();
  }

  /**
   * Creates the form to handle the contractor data
   */
  initFullContractorOnboardingForm(): void {
    this.newFullContractorOnboarding = this._formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: [
        '',
        [Validators.required, Validators.pattern(this.EMAIL_TRACKER_REGEX)],
      ],
      confirmEmail: ['',
        [Validators.required, Validators.pattern(this.EMAIL_TRACKER_REGEX)],
      ],
      phoneNumber: [
        '',
        [Validators.required, Validators.pattern(this.PHONE_REGEX)],
      ],
    }, {validators: this.compareEmailFields('email', 'confirmEmail')});//Evaluation in FormGroup validators.
  }

  /**
   *
   * @param emailCheck
   * @param confirmEmailCheck
   * @returns
   *
   * This method evaluates that email and confirm email match.
   */
  compareEmailFields(emailCheck: string, confirmEmailCheck: string): ValidationErrors {
    return (_formGroup: FormGroup) => {
      const controlEmail = _formGroup.controls[emailCheck];
      const controlConfirmEmail = _formGroup.controls[confirmEmailCheck];
      if (controlConfirmEmail.errors && !controlConfirmEmail.errors.checkEmailIsMatched){
        return;
      }
      if (controlEmail.value.trim().toLowerCase() !== controlConfirmEmail.value.trim().toLowerCase()) {
        controlConfirmEmail.setErrors({checkEmailIsMatched: true})
      } else {
        controlConfirmEmail.setErrors(null)
      }
    }
  }

  goBack(): void {
    this._router.navigate(['login']);
  }

  /**
   *
   * @param event
   * Does not allow copy in clipboardevents.
   */
  preventCopyingOnClipboardEvent(event: ClipboardEvent): void {
    event.preventDefault();
  }

  /**
   *
   * @param event
   * Does not allow paste in clipboardevents.
   */
  preventPasteOnClipboardEvent(event: ClipboardEvent): void {
    event.preventDefault();
  }

  /**
   * Gets called when user the wants to submit changes
   */
  submitForm(): void {
    delete this.newFullContractorOnboarding.value.confirmEmail;//Don't send confirmEmail in Object JSON.
    localStorage.setItem(
      'formFullContractor',
      JSON.stringify(this.newFullContractorOnboarding.value)
    );
    this._router.navigate(['v2/full-contractor/otp-email']);
  }

}
