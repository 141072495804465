import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { LcpService } from 'src/app/services/lcp/lcp.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'footer-links',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @ViewChild("apiCall", { static: true }) apiCall: TemplateRef<any>;
  api : boolean = false;
  type : string = '';
  data : any;
  expanded : boolean = true;
  
  constructor( 
    private bottomSheet: MatBottomSheet,
    private lcp : LcpService,
    private storage : StorageService
    ) { }

  ngOnInit() {
  }

  getStorage(action) {
    if ( this.storage.isExisted(action) !== null ) {
      return this.storage.getItem(action);
    }
    return null;
  }
  openBottomSheet() {

  }

// ---------------------------------------------------------------------------
// -----------------------   lcptracker /api  --------------------------------
// ---------------------------------------------------------------------------
getClaims() {
  this.lcp.getClaims( '1ae17645-bd7e-4985-81d8-7e245b195bd7','wageDataApi')
  .subscribe(res => {
    console.log('claims : ', res);
    this.type = 'getClaims(orgId, apiScope, impersonate)';
    this.data = res;
    this.bottomSheet.open(this.apiCall);
  });

}
impersonateUser(user) {
  this.lcp.impersonateUser(user)
  .subscribe(res => {
    //console.log(res);
    this.type = 'impersonateUser(user)';
    this.data = res;
    this.bottomSheet.open(this.apiCall);
  })
}
validateToken(token) {
  this.lcp.validateToken(token)
  .subscribe(res => {
    //console.log(res);
    this.type = 'validateToken(token)';
    this.data = res;
    this.bottomSheet.open(this.apiCall);
  })
}
validatePassword(user) {
  this.lcp.validatePassword(user)
  .subscribe(res => {
    //console.log(res);
    this.type = 'validatePassword(user)';
    this.data = res;
    this.bottomSheet.open(this.apiCall);
  })
}
validateUserName(user) {
  this.lcp.validateUserName(user)
  .subscribe(res => {
    //console.log(res);
    this.type = 'validateUserName(user)';
    this.data = res;
    this.bottomSheet.open(this.apiCall);
  })
}
getUserName() {
  this.lcp.getUserName()
  .subscribe(res => {
    //console.log(res);
    this.type = 'getUserName()';
    this.data = res;
    this.bottomSheet.open(this.apiCall);
  })
}
getAccounts() {
  this.lcp.getAccounts()
  .subscribe(res => {
    //console.log(res);
    this.type = 'getAccounts()';
    this.data = res;
    this.bottomSheet.open(this.apiCall);
  })
}
// setOrgCode(org) {
//   this.lcp.setOrgCode(org)
//   .subscribe(res => {
//     //console.log(res);
//     this.type = 'setOrgCode(org)';
//     this.data = res;
//     this.bottomSheet.open(this.apiCall);
//   })
// }


}
