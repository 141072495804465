import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { AppState } from 'src/app/models/interfaces';
import { BusyDialogComponent } from './dialog/busy-dialog.component';
//
// ngrx / rxjs
//
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-busy',
  templateUrl: './busy.component.html',
  styleUrls: ['./busy.component.scss']
})
export class BusyComponent implements OnInit, OnDestroy {
  private busySubscription: Subscription;
  private dialogRef: MatDialogRef<BusyDialogComponent, string> = null;

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.busySubscription = this.store.select('busy').subscribe(state => {
      if (this.dialogRef) {
        this.dialogRef.close();
        this.dialogRef = null;
      }
      if (state.message) {
        this.dialogRef = this.dialog.open<BusyDialogComponent, string>(BusyDialogComponent, {
          disableClose: true,
          data: state.message === '' || state.message === undefined ? 'Loading...' : state.message
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.busySubscription) {
      this.busySubscription.unsubscribe();
    }
  }
}
