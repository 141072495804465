import { BehaviorSubject, of } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ContractorSelectorAccountService {
    /**
     * Creates an instance of contractor selector account service.
     * @param _http
     */
    constructor(_http) {
        this._http = _http;
        this.dataCacheStream = new BehaviorSubject([]);
        this.dataCacheStream$ = this.dataCacheStream.asObservable();
        this.loadingSubject = new BehaviorSubject(true);
        this.loadingSubject$ = this.loadingSubject.asObservable();
        this.loadingSendInformationSubject = new BehaviorSubject(true);
        this.loadingSendInformationSubject$ = this.loadingSendInformationSubject.asObservable();
        this.userFullContractorFormStream = new BehaviorSubject(null);
        this.userNameStream = new BehaviorSubject("");
        this.responseFullContractor = new BehaviorSubject(null);
        this.responseFullContractor$ = this.responseFullContractor.asObservable();
    }
    getData() {
        this.loadingSubject.next(true);
        return this._http
            .get(`${environment.webAppUrl}/api/accounts`, {
            withCredentials: true,
        })
            .pipe(tap((items) => {
            console.log("Fetch todo");
            console.log(items);
            this.userNameStream.next(items["Username"]);
            this.dataCacheStream.next(items);
            this.loadingSubject.next(true);
        }));
    }
    /**
     * Makes a request to LCP to link a professional email with accounts
     * @param fullContractor
     * @param accounts
     * @returns
     */
    sendInformationAndAccountsFromFullContractorSelector(fullContractorForm, accounts) {
        console.table(fullContractorForm);
        console.table(accounts);
        let fullContractor = {
            user: fullContractorForm,
            accounts: accounts
        };
        return this._http.post(`${environment.webAppUrl}/api/fullContractorUser`, fullContractor, { withCredentials: true }).pipe(tap(() => {
            this.loadingSendInformationSubject.next(false);
        }));
    }
    /**
     * Makes a request to LCP to link a professional email
     * @param fullContractor
     * @returns
     */
    sendInformationFullContractor(fullContractor) {
        console.table(fullContractor);
        this.loadingSendInformationSubject.next(true);
        return this._http.post(`${environment.webAppUrl}/api/fullContractorUser`, fullContractor, { withCredentials: true }).pipe(tap(() => {
            this.loadingSendInformationSubject.next(false);
        }));
    }
    handleError(operation = "operation", result) {
        return (error) => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead
            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
ContractorSelectorAccountService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContractorSelectorAccountService_Factory() { return new ContractorSelectorAccountService(i0.ɵɵinject(i1.HttpClient)); }, token: ContractorSelectorAccountService, providedIn: "root" });
