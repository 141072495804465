/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i5 from "@angular/common";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./button.component";
var styles_ButtonComponent = [i0.styles];
var RenderType_ButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ButtonComponent, data: {} });
export { RenderType_ButtonComponent as RenderType_ButtonComponent };
function View_ButtonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.prefixIcon; _ck(_v, 2, 0, currVal_2); }); }
function View_ButtonComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).inline; var currVal_1 = (((i1.ɵnov(_v, 1).color !== "primary") && (i1.ɵnov(_v, 1).color !== "accent")) && (i1.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.suffixIcon; _ck(_v, 2, 0, currVal_2); }); }
export function View_ButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "button-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "button", [["mat-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.emitButtonWasClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatButton_0, i4.RenderType_MatButton)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵprd(512, null, i5.ɵNgStyleImpl, i5.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i5.NgStyle, [i5.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { "width": 0 }), i1.ɵdid(7, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ButtonComponent_1)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(10, 0, [" ", " "])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ButtonComponent_2)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.classes; _ck(_v, 3, 0, currVal_2); var currVal_3 = _ck(_v, 6, 0, (_co.isDemo ? "fit-content" : "")); _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.isDisabled; _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.prefixIcon; _ck(_v, 9, 0, currVal_5); var currVal_7 = _co.suffixIcon; _ck(_v, 12, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 7).disabled || null); var currVal_1 = (i1.ɵnov(_v, 7)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_6 = _co.label; _ck(_v, 10, 0, currVal_6); }); }
export function View_ButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-button", [], null, null, null, View_ButtonComponent_0, RenderType_ButtonComponent)), i1.ɵdid(1, 49152, null, 0, i9.ButtonComponent, [], null, null)], null, null); }
var ButtonComponentNgFactory = i1.ɵccf("app-button", i9.ButtonComponent, View_ButtonComponent_Host_0, { buttonType: "buttonType", isNegative: "isNegative", isDemo: "isDemo", isDisabled: "isDisabled", label: "label", prefixIcon: "prefixIcon", suffixIcon: "suffixIcon" }, { buttonClicked: "buttonClicked", enterKeyUp: "enterKeyUp" }, []);
export { ButtonComponentNgFactory as ButtonComponentNgFactory };
