import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AppState, GetAccountsData } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';
//
// ngrx / rxjs
//
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { take, map } from 'rxjs/operators';
import { merge } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccountResolverService implements Resolve<GetAccountsData> {

    constructor(
        private store: Store<AppState>,
        private actions$: Actions,
        private router: Router
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log( 'Account Resolver ActivatedRouteSnapshow' + JSON.stringify(route.data));

        this.store.select('password').subscribe(loginInfo => {
          if (loginInfo.enableFullContractor) {
            localStorage.setItem('IsEmailContractorUser', JSON.stringify(loginInfo.isEmailContractorUser));
            this.router.navigate(['v2/full-contractor']);
          } else {
            this.store.dispatch(AllActions.getAccountsStart());
          }
        })

        const getAccountsSuccess$ = this.actions$
            .pipe(ofType(AllActions.getAccountsSuccess));

        const getAccountsFail$ = this.actions$.pipe(
            ofType(AllActions.getAccountsFail),
            map(() => ({ username: '', supportUsername: '', accounts: [] })),
        );

        return merge(getAccountsSuccess$, getAccountsFail$).pipe(take(1));
    }
}
