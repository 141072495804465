import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, take, map } from 'rxjs/operators';
import jwt_decode from "jwt-decode";
import { Observable } from 'rxjs';

import { RBSClaim, OrgCode, AppState } from 'src/app/models/interfaces';
import { FortifyOAuthService } from 'src/app/auth/fortify/fortify-oauth.service';
import { RBS_EXTENDED_CLAIMS_KEYS, ORG_TYPES } from './../../models/consts';
import { environment } from './../../../environments/environment';

interface UserTokenInfo {
  uuid: string;
  firstName: string;
  lastName: string;
  email: string;
}
@Injectable({
  providedIn: 'root'
})
export class MonetizationService {
  headers: HttpHeaders;
  idDecodedToken: {
    sub: string,
    email: string,
    family_name: string,
    given_name: string
  };
  idToken: string;
  contractorsAccounts: OrgCode[] = [];
  user: UserTokenInfo = {
    uuid: '',
    firstName: '',
    lastName: '',
    email: '',
  };
  isAContractorUser: boolean = false;
  hasLicenses: boolean = false;

  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    private fortifyOAuthService: FortifyOAuthService
  ) {
    this.getIdToken();

    this.headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${this.fortifyOAuthService.getAccessToken()}`,
    });
  }

  /**
   * Gets ID Token from the store
   */
  getIdToken(): void {
    this.store.select('fortifyAuth')
      .pipe(
        filter(state => !!state.idToken),
        map(state => state.idToken),
        take(1)
      )
      .subscribe(idToken => {
        this.idDecodedToken = jwt_decode(idToken);
        this.idToken = idToken;
        this.setUserInfo();
      });
  }

  /**
   * Sets the main user info from the ID Token
   */
  setUserInfo(): void {
    this.user.uuid = this.idDecodedToken.sub;
    this.user.firstName = this.idDecodedToken.given_name ? this.idDecodedToken.given_name : '';
    this.user.lastName = this.idDecodedToken.family_name ? this.idDecodedToken.family_name : '';
    this.user.email = this.idDecodedToken.email;
  }


  /**
   * Checks if the user is a contractor based on the RBS extended claims
   * If the RBS contains the CONTRACTOR_LICENSES key, it means that the user is a contractor. Otherwise, it is not.
   * And if the user has contractor information in the Account List (OrgType4)
   * @param claims
   * @returns Wether the user is a contractor or not
   */
  isAContractor(claims: RBSClaim[]): boolean {
    const contractorLicenseClaim = claims.find(claim => claim.type == RBS_EXTENDED_CLAIMS_KEYS.CONTRACTOR_LICENSES);

    this.contractorsAccounts = this.getContractorAccountsFromRBS(claims);

    console.log(`%c  Contractors Accounts  `, "background-color: black; color: white; padding: 4px; border-radius: 7px, display: inline-block;");
    console.table(this.contractorsAccounts);

    this.isAContractorUser = (contractorLicenseClaim && this.contractorsAccounts) ? true : false;

    return this.isAContractorUser;
  }

  /**
   * Determines if a user has expired licenses by checking the CONTRACTOR_LICENSES key.
   * If the key exists and is and empty array it means that the user is a contractor and has expired license
   * @param claims
   * @returns Wether the user has expired licenses or not
   */
  hasActiveLicenses(claims: RBSClaim[]): boolean {
    if (!this.isAContractor(claims)) {
      return false;
    }

    const contractorLicenseClaim = claims.find(claim => claim.type == RBS_EXTENDED_CLAIMS_KEYS.CONTRACTOR_LICENSES);
    console.log({contractorLicenseClaim}, "MTC")
    if (contractorLicenseClaim) {
      if (contractorLicenseClaim.value.length) {
        console.log(`%c  Contractors Accounts  `, "background-color: black; color: white; padding: 4px; border-radius: 7px, display: inline-block;");
        this.hasLicenses = contractorLicenseClaim.value.some(claim => claim.lic.length > 0);

        return this.hasLicenses;
      }
    }

    return false;
  }

  get getContractorAccounts(): OrgCode[] {
    return this.contractorsAccounts
  }

  get getUUID(): string {
    return this.idDecodedToken.sub;
  }

  get isUserAContractor(): boolean {
    return this.isAContractorUser;
  }

  get shouldPurchaseALicense(): boolean {
    return this.isAContractorUser && !this.hasLicenses;
  }

  /**
   * Checks if the user has many contractor accounts
   * @param claims
   * @returns
   */
  hasManyContractorAccounts(claims: RBSClaim[]): boolean {
    const contractorAccounts = this.getContractorAccountsFromRBS(claims);

    if (contractorAccounts) {
      console.log('HAS MANY contractor ACCOUNTS:', contractorAccounts.length > 1);
      return contractorAccounts.length > 1;
    }

    return false;
  }

  hasOnlyOneContractorAccount(claims: RBSClaim[]): boolean {
    const contractorAccounts = this.getContractorAccountsFromRBS(claims);

    return contractorAccounts.length == 1;
  }

  /**
   * Checks if a banner to purchase a license should be displayed.
   * @param claims
   * @returns If a banner to purchase a license should be displayed.
   */
  checkShouldPurchaseALicenses(claims: RBSClaim[]): boolean {
    return this.isAContractor(claims) && !this.hasActiveLicenses(claims);
  }

  /**
   * Calls the RBS API to build the needed rows in the backend
   */
  prePaymentSetupCreateIds(orgId: string, orgName: string): Observable<any> {
    console.log('Calling RBS /build-contractors API and waiting Salesforces ID')

    const params = new HttpParams({
      fromObject: { UserId: this.user.uuid, OrgId: orgId },
    });

    const options = {
      headers: this.headers
    }

    return this.http.post<any>(
      `${environment.fortifyAPIUrl}/api/SalesForceIntegration/CreateIds`, params.toString(), options)
      .pipe(
        map(response => response.data),
      )
  }

  /**
   * Redirects the user to Chargify with a custom URL
   */
  redirectToChargify(saleforceId: string, orgName: string): void {
    const publicChargifyUrl: string = `${environment.chargifyUrl}?first_name=${this.user.firstName}&last_name=${this.user.lastName}&email=${this.user.email}&reference=${saleforceId}&organization=${orgName}`
    window.location.href = publicChargifyUrl;
  }

  /**
   * Checks if the logged user has a valid license by consulting Fortify
   * @returns if the user has a valid license
   */
  hasFoundLicenseByUser(): Observable<any> {
    if (!this.user.uuid) {
      return;
    }

    const options = {
      headers: this.headers
    }

    return this.http.get<any>(
      `${environment.fortifyAPIUrl}/api/SalesForceIntegration/GetLicForUser/${this.user.uuid}`, options)
      .pipe(
        map(response => response.data),
      )
  }

  /**
   * Checks if the logged user has a valid license by checking the contractor license
   * @returns if the contractor has a valid license
   */
  hasFoundLicenseByOrg(orgId: string, emailParam = null): Observable<any> {
    const options = {
      headers: this.headers
    }

    let email = (this.user.email) ? this.user.email : emailParam;

    return this.http.get<any>(
      `${environment.fortifyAPIUrl}/api/SalesForceIntegration/GetLicForOrg/${orgId}/${email}`, options)
      .pipe(
        map(response => response.data),
      )
  }

  /**
   * Returns the contractors account based on the RBS Claim object
   * @param claims
   * @returns
   */
  getContractorAccountsFromRBS(claims: RBSClaim[]) {
    let orgCodeFromRBS: RBSClaim = claims.find(claim => claim.type == RBS_EXTENDED_CLAIMS_KEYS.ORG_CODES);
    let orgCode: OrgCode[] = (orgCodeFromRBS) ? orgCodeFromRBS.value : [];

    return orgCode.filter(account => account.orgType == ORG_TYPES.CONTRACTOR);
  }

}
