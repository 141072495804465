import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'top-page',
  templateUrl: './top-page.component.html',
  styleUrls: ['./top-page.component.scss']
})
export class TopPageComponent implements OnInit {
  @Input() position:number = 400;
  @Input() showSpeed:number = 500;
  @Input() moveSpeed:number = 1000;
  @ViewChild('TopPageComponent', { static: true }) _selector:ElementRef;
  constructor() { }

  ngOnInit() {
    this._onWindowScroll();
  }


  @HostListener('click')
  _onClick():boolean {
      var top = document.querySelector("body");
      top.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      return false;
  }

  @HostListener('window:scroll')
  _onWindowScroll():void {
    //console.log('scrolling')
      let el = this._selector.nativeElement;
      // console.log(el);
      // console.log(window.scrollY);
      if (window.scrollY > this.position) {
        el.style.display = 'block';
      } else {
        el.style.display = 'none';
      }
  }

}
