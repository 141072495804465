import { Component, OnInit } from '@angular/core';
import { AuthNet6CodeService } from '../../services/auth-net-6-code.service';
import { AuthNet6ImplicitService } from '../../services/auth-net-6-implicit.service';

@Component({
  selector: 'app-sso-net6',
  templateUrl: './sso-net6.component.html',
  styleUrls: ['./sso-net6.component.scss']
})
export class SsoNet6Component implements OnInit {

  // Code Flow
  constructor(
    private authService: AuthNet6CodeService,
    private authImpService : AuthNet6ImplicitService
    ) {
    // Once the User Reaches /sso/net6 route inits the login process
    this.authService.login()
    //
    // testing implicit flow environments can change / break these.
    //
    //this.authImpService.login()
  }

  ngOnInit() {
    document.body.style.paddingTop = "0px"; // unit measured in Edge.
  }
}
