import { NgZone } from '@angular/core';
import { OAuthService, OAuthLogger, UrlHelperService, ValidationHandler, AuthConfig } from 'angular-oauth2-oidc';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./lcptracker-oauth-storage";
import * as i3 from "angular-oauth2-oidc";
import * as i4 from "../../models/crypto-handler";
export class LcptrackerOAuthService extends OAuthService {
    constructor(ngZone, http, storage, tokenValidationHandler, config, urlHelper, logger, crypto) {
        super(ngZone, http, storage, tokenValidationHandler, config, urlHelper, logger, crypto);
        this.config = config;
    }
}
LcptrackerOAuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LcptrackerOAuthService_Factory() { return new LcptrackerOAuthService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LcpTrackerOAuthStorage, 8), i0.ɵɵinject(i3.ValidationHandler, 8), i0.ɵɵinject(i3.AuthConfig, 8), i0.ɵɵinject(i3.UrlHelperService), i0.ɵɵinject(i3.OAuthLogger), i0.ɵɵinject(i4.CryptoHandler)); }, token: LcptrackerOAuthService, providedIn: "root" });
