import { AuthConfig } from "angular-oauth2-oidc";

export const authNet6ConfigImplicit: AuthConfig = {
  // Url of the Identity Provider
  issuer: 'https://devv2.fortify.lcptracker.net',

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/login/sso/net6/callback',

  responseType: 'id_token token',

  // The SPA's id. The SPA is registered with this id at the auth-server
  clientId: 'LCPtrackerSPA.implicit',

  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'openid profile email',
}