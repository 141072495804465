import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { LcpService } from './../../../services/lcp/lcp.service';
import { environment } from 'src/environments/environment';
import * as AllActions from 'src/app/store/actions/index';
import { AppState } from 'src/app/models/interfaces';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
  passwordForm: FormGroup;
  showHide: boolean = false;
  username: string = '';
  webAppUrl: string = '';
  isLoadingUserInfo: boolean = true;
  userUsesFortify: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private lcpService: LcpService
  ) {
    this.getUsernameLabelFromRouteParams();
  }

  ngOnInit() {
    this.passwordForm = this.fb.group({
      password: ['', Validators.required]
    });

    console.log('PasswordComponent route', this.route);

    this.webAppUrl = environment.webAppUrl;

    this.handlePasswordInputDisplay();
  }

  /**
   * Gets the username from the URL to display it on the HTML template
   */
  getUsernameLabelFromRouteParams(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.username = paramMap.get('username');
    })
  }

  /**
   * Validates if the password input should be shown based on if the user uses Fortify
   * @param username
   */
  handlePasswordInputDisplay(): void {
    this.lcpService.validateUserName(this.username).subscribe(response => {
      this.userUsesFortify = response.UsesFortify;
      this.isLoadingUserInfo = false;
    })
  }

  /**
   * Navigates to the username input component
   */
  onPrevious(): void {
    this.router.navigate(['/login', this.username]);
  }

  /**
   * Dispatches an action to validate the password
   */
  onSubmit(): void {
    const password = this.passwordForm.value.password.trim()

    this.store.dispatch(AllActions.validatePasswordStart({ username: this.username, password: password }));
  }
}
