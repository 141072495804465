/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./alert.component";
import * as i3 from "@ngrx/store";
import * as i4 from "@angular/material/dialog";
var styles_AlertComponent = [i0.styles];
var RenderType_AlertComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertComponent, data: {} });
export { RenderType_AlertComponent as RenderType_AlertComponent };
export function View_AlertComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_AlertComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert", [], null, null, null, View_AlertComponent_0, RenderType_AlertComponent)), i1.ɵdid(1, 245760, null, 0, i2.AlertComponent, [i3.Store, i4.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertComponentNgFactory = i1.ɵccf("app-alert", i2.AlertComponent, View_AlertComponent_Host_0, {}, {}, []);
export { AlertComponentNgFactory as AlertComponentNgFactory };
