import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { ErrorService } from 'src/app/services/error/error.service';
import { AppState, ImpersonateResponse } from 'src/app/models/interfaces';
//
// ngrx / rxjs
//
import * as AllActions from 'src/app/store/actions/index';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { switchMap, catchError, map, tap, take } from 'rxjs/operators';
import { of } from 'rxjs';



@Injectable()
export class ImpersonateEffects {
    constructor(
        private actions$: Actions,
        private error: ErrorService,
        private http: HttpClient,
        private router: Router,
        private snackBar: MatSnackBar,
        private store: Store<AppState>) {}

    getUsername$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.getUsernameStart),
            tap(() => this.store.dispatch(AllActions.setBusyMessage({ message: 'Getting user information' }))),
            switchMap(action =>
                this.http.get<{ Username: string; SupportUsername: string, IsSupportAdmin: boolean }>(
                    `${environment.webAppUrl}/api/user`, {
                        withCredentials: true,
                    }).pipe(
                        take(1),
                        map(response => AllActions.getUsernameSuccess({
                            username: response.Username, 
                            supportUsername: response.SupportUsername,
                            isSupportAdmin: response.IsSupportAdmin
                        })),
                        catchError(error => {
                            console.log('validateTokenFail$ :', error);
                            let message = error.message;

                            const UNAUTHORIZED_ACCESS = 403;

                            if (error.status === UNAUTHORIZED_ACCESS) {
                              message = `You do not have the proper privilege's to access this page`
                            }

                            if (error.state === 403) {
                                message = 'Unauthorized';
                            }                            
                            return of(AllActions.getUsernameFail({ errorMessage: message }));
                        })
                    )
                )
        )
    );

    getUsernameSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.getUsernameSuccess),
            switchMap(() => of(AllActions.setBusyMessage({ message: '' })))
        )
    );

    getUsernameFail$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.getUsernameFail),
            tap(action => {
                let err = this.error.returnError('failed_usernameVal');
                this.snackBar
                .open(err, 'OK', { duration: 10000, verticalPosition: "top"})
                .onAction()
                .subscribe(() => {
                    // Keep this here for when you want to "undo" a functionality.
                });
                this.store.dispatch(AllActions.showAlert({ message: action.errorMessage }));
                this.router.navigate(['/login']);
            }),
        ), { dispatch: false }
    );

    impersonateUser$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.impersonateUserStart),
            tap(action => console.log('impersonateUser$ effect dispatched', action)),
            switchMap(action =>
                this.http.put<ImpersonateResponse>(`${environment.webAppUrl}/api/login`, {
                    Username: action.username,
                    OverrideSupport: action.overrideSupport
                }, {
                    withCredentials: true,
                }).pipe(
                    take(1),
                    map(response => {
                        if (response.Success) {
                            return AllActions.impersonateUserSuccess({ username: action.username });
                        } else {
                            return AllActions.impersonateUserFail({ errorMessage: response.Message });
                        }
                    }),
                    catchError(error => {
                        console.log(error);
                        return of(AllActions.impersonateUserFail({ errorMessage: error.message }));
                    })
                ))
        )
    );

    impersonateUserSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.impersonateUserSuccess),
            tap(() => this.router.navigate(['/login', 'account']))
        ), { dispatch: false }
    );

    impersonateUserFail$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.impersonateUserFail),
            take(1),
            tap(action => {
                console.log('impersonateUserFail$ :', action);
                let err = this.error.returnError('failed_impersonate');
                this.snackBar
                .open(err, 'OK', { duration: 10000, verticalPosition: "top"})
                .onAction()
                .subscribe(() => {
                    // Keep this here for when you want to "undo" a functionality.
                });
                this.store.dispatch(AllActions.setBusyMessage({ message: '' }));
                this.router.navigate(['/login']);
            }),
        ), { dispatch: true }
    );
}
