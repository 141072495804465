import { OAuthService } from 'angular-oauth2-oidc';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class Net6AccountsService {

  constructor(private http: HttpClient, private oAuthService: OAuthService) {}

  /**
   * Extended claims from .NET6 (https://{{host}}/api/RbsClaims/V02/json/GetExtendedIdentityClaims)
   * @returns
   */
  getRBSExtendedNET6Claims(): Observable<any> {
    const token = this.oAuthService.getAccessToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);

    return this.http.get<any>('https://devv2.fortify.lcptracker.net/api/RbsClaims/V02/json/GetExtendedIdentityClaims',  {headers}).pipe(map(res => res.data))
  }

  /**
   * Get accounts from Tracker
   * @returns
   */
  getAccounts(): Observable<any> {
    return this.http.get<any>('https://httpbin.org/delay/1500?arg=')
  }
}
