
<main>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="login-project">
                    <mat-progression-bar [show]="showProgressBar"></mat-progression-bar>
                    <div class="fortify-logout">
                        <a tabindex="0" class="fortify-link"
                            (click)="logoutFortify()"
                            (keyup.enter)="logoutFortify()"
                            >
                            <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
                            Switch Fortify User / Logout
                        </a>
                    </div>
                    <div class="row margin-0">
                        <div class="col-12">
                            <div class="company-logo">
                                <img src="./assets/logo.png" alt="Sign In to Lcptracker" />
                            </div>
                            <div class="instructions">
                                <p class="h6">For Electronic Submission and Management of Certified Payroll Reports</p>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-7">
                            <h5 class="project-header">
                                <a class="go-back" title="Go Back to Previous" (click)="onPrevious()">
                                    <i class="fa fa-chevron-circle-left"></i>
                                </a>
                                Select an Account :
                            </h5>
                            <div class="filter-search">
                                <i class="fa fa-search filter-search-icon" aria-hidden="true"></i>
                                <input #filterby id="filterby" appAutoFocus
                                    (keyup)="applyFilter($event.target.value)"
                                    class="filter-search-input"
                                    placeholder="Filter Accounts"
                                    />
                                <label for="filterby">Search Filter</label>
                            </div>

                            <ul id="filter-list" class="project-list">
                                <li *ngFor="let link of accounts; let i = index;">
                                    <a tabindex="0" (click)="selectProject($event, link)">{{link.orgCode}}</a>
                                </li>
                            </ul>

                        </div>
                        <div class="col-12 col-md-6 col-lg-5 text-center">
                            <a #marketingAd class="marketing-ad sticky-top" tabindex="0" [href]="imgLink" target="blank">
                                <img [id]="imgId" [src]="imageURLAsset" [attr.alt]="imgLabel" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>







<footer-links></footer-links>
