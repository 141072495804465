import { Component } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ContentfulService } from './services/contentful/contentful.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  imgloaded : boolean = false;

  constructor (
    private router : Router,
    private contentful : ContentfulService
  ) {
    
  }
  ngOnInit() {  
    //
    // Listen to Contentful Background
    //
    this.contentful.getBack()
    .subscribe(data => {
      if ( data != (null || undefined) ) {
        const doc = document.querySelector('body');
        doc.classList.add(data.fields.color);
        let styles = 'background-size: ' + data.fields.size + '; ';
        styles += 'background-image: url("' + data.fields.image + '"); ';
        styles += 'background-position : ' + data.fields.position + '; ';
        styles += 'background-repeat : ' + data.fields.repeat + '; ';
        styles += 'background-blend-mode : ' + data.fields.blend + '; ';
        if (data.fields.repeat == 'no-repeat') {
            styles += 'background-attachment : fixed;';
        }
        doc.setAttribute('style', styles);
      }
    })
    //
    // Listen to incoming Routes
    //
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // console.log('You hit NavigationEnd')
        // console.log(this.router.url);
        switch (this.router.url) { 
          case '/login':
            this.contentful.fetchContentful();
            break;
          default :
            this.decideContentful();
            break;
        }
      }
    })



  }


  // ---------------------------------------------------------------------------
  // -----------------------   UX Experience   ---------------------------------
  // ---------------------------------------------------------------------------
  decideContentful() {
    if ( this.imgloaded == false ) { // once loaded this is skipped.
      if ( localStorage.getItem('contentful-entries') != (null || undefined)) {
        let storage = JSON.parse(localStorage.getItem('contentful-entries'));
        // console.log('storage :: ', storage);
        this.contentful.setAd(storage[0]);
        let random = Math.floor(Math.random() * storage[1].items.length);
        this.contentful.setBack(storage[1].items[random]);
        this.imgloaded = true;
      } else {
        this.contentful.fetchContentful();
        this.imgloaded = true;
      }
    } 
  }
  
}
