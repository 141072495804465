import * as AllActions from 'src/app/store/actions/index';
import { ActivatedRoute, Router } from '@angular/router';
import { map, tap, take, filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { MonetizationService } from './../../../services/monetization/monetization.service';
import { AppState, GetAccountsData, stateImpersonate } from 'src/app/models/interfaces';
import { GetUserResponseData, OrgCode, RBSClaim, SelectedContractor } from './../../../models/interfaces';
import { FortifyOAuthService } from './../../../auth/fortify/fortify-oauth.service';
import { RBS_EXTENDED_CLAIMS_KEYS, ORG_TYPES, SESSION_STORAGE } from 'src/app/models/consts';
import { StorageService } from 'src/app/services/storage/storage.service';
import { SharedService } from 'src/app/services/shared/shared.service';
import { LcpService } from 'src/app/services/lcp/lcp.service';
import { Account } from 'src/app/models/account.model';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  auth: any;
  routeAuth: string;
  //
  // Staff
  //
  username: string;
  supportUsername: string;
  // impersonate : stateImpersonate;
  impersonate: any;
  staffAccounts: Account[] = [];
  //
  // Fortify
  //
  fortifyAuth: any;
  orgCodes: OrgCode[] = [];
  tpaOrgCodes: any = [];
  claimAccounts: any = [];
  notTPASatisdfiedAccounts: string[] = [];
  //
  // lcpTracker
  //
  lcptrackerAuth: any;
  accounts: Account[] = [];
  accountsMerged: Account[] = [];
  hasFinishedMergingAccounts: boolean = false;

  //
  // UX variables.
  //
  isArray: boolean;
  noMatches: boolean = false;
  expand: boolean = false;
  showExpandArrows: boolean = false;
  isSupportUser: boolean = false;

  shouldPurchaseALicense: boolean = false;
  hasFoundLicense: boolean = false;
  contractorAccounts: OrgCode[] = [];

  private initialization;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private shared: SharedService,
    private storage: StorageService,
    private lcpService: LcpService,
    private fortifyOAuthService: FortifyOAuthService,
    private monetizationService: MonetizationService
  ) {
    this.initialization = this.init();
  }

  async init() {
    await this.getRBSClaims();
    this.shared.setlayoutProject(true);
    this.getAuths();
    this.handleAccountsPopulation();

    this.store.select('password')
      .pipe(
        take(1)
      ).subscribe(state => {
        console.log('State in constructor', state)
        this.isSupportUser = state.isSupportUser
      });
  }

  /**
   * Gets the contractor accounts
   */
  getContractorAccounts(): void {
    this.contractorAccounts = this.monetizationService.getContractorAccounts
  }

  /**
   * Interval that verifies by making an HTTP request if the user has a valid license.
   * This will run every 6 seconds 48 times.
   */
  licenseChecker(rbs: RBSClaim[]): void {
    if (!this.shouldPurchaseALicense) {
      return;
    }

    let contractorId: string = null;

    if (this.monetizationService.hasOnlyOneContractorAccount(rbs)) {
      contractorId = this.monetizationService.getContractorAccountsFromRBS(rbs)[0].id;
    } else {
      let selectedContractor: SelectedContractor = JSON.parse(localStorage.getItem(SESSION_STORAGE.SELECTED_CONTRACTOR_ID));
      contractorId = selectedContractor ? selectedContractor.contractorId : null;
    }

    if (!contractorId) {
      return;
    }

    const MAX_TIMES_TO_RUN = 48;
    let timesRun = 0;

    this.contractorAccounts = this.monetizationService.getContractorAccounts;

    console.log('Checking license by org...')
    let checkLicenseInterval = setInterval(() => {

      if (timesRun === MAX_TIMES_TO_RUN) {
        clearInterval(checkLicenseInterval);
        return;
      }

      timesRun += 1;

      this.monetizationService.hasFoundLicenseByOrg(this.contractorAccounts[0].id).subscribe(res => {
        this.shouldPurchaseALicense = false;
        clearInterval(checkLicenseInterval)

        if (this.accountsMerged.length == 1) {
          this.store.dispatch(AllActions.setOrgCodeStart({ orgCode: this.accountsMerged[0].OrgCode, orgCodes: this.accountsMerged.map(account => account.OrgCode), RoleId:JSON.stringify(this.accountsMerged[0].RoleId)  }));
        }
      }, error => {
        console.log(error)
        this.shouldPurchaseALicense = true;
      })
    }, 6000)
  }

  /**
   * Gets the RBS Claims from the RBS API
   */
  async getRBSClaims(): Promise<void> {
    if (this.isSupportUser) {
      return;
    }

    if (!this.fortifyOAuthService.hasValidIdToken()) {
      console.error('MTC: not a valid token while trying to call RBS');
      return;
    }

    const rbs: RBSClaim[] = await this.lcpService.getRBSClaims(this.fortifyOAuthService.getAccessToken())
      .toPromise()
      .then(rbsClaims => rbsClaims)
    let orgCodes: RBSClaim = rbs.find(claim => claim.type == RBS_EXTENDED_CLAIMS_KEYS.ORG_CODES);


    this.orgCodes = (orgCodes) ? orgCodes.value : [];
    this.orgCodes = this.orgCodes.filter(account => account.orgType != ORG_TYPES.CONTRACTOR)

    this.shouldPurchaseALicense = this.monetizationService.checkShouldPurchaseALicenses(rbs);

    if (this.shouldPurchaseALicense) {
      this.licenseChecker(rbs);
    }

    console.warn('------------ From RBS API ------------')
    console.table(this.orgCodes)
    console.warn('--------------------------------------')
  }

  ngOnInit() {
    setTimeout(() => {
      this.mergeAccounts();
    }, 4000);
  }

  /**
   * Merges claimAccounts, accounts and staffAccounts into a single one. Handling duplicated ones.
   */
  mergeAccounts(): void {
    console.log('B')
    console.log(`%cStarting accounts merge process... 🔽 `, "background-color: #01579b; color: white; padding: 7px");

    console.warn(`List: accounts from api/accounts fixed (${this.accounts.length})`)
    console.table(this.accounts);
    this.accounts.forEach((account: Account) => {

      const isAlreadyInMergedArray = this.accountsMerged.find(accountEl => {
        if (accountEl.OrgCode === account.OrgCode && accountEl.RoleId === account.RoleId) {

          return true;
        }

        return false;
      });
      if (!isAlreadyInMergedArray) {
        const accountToPush: Account = {
          OrgCode: account.OrgCode,
          Name: account.Name,
          FortifyEnabled: account.FortifyEnabled,
          RoleId: account.RoleId
        }

        this.accountsMerged.push(accountToPush)
      }
    });

    console.warn(`List: from RBS fixed (${this.claimAccounts.length})`)
    console.table(this.claimAccounts);
    this.claimAccounts.forEach(claimAccount => {
      const isAlreadyInMergedArray = this.accountsMerged.find(account => {
        if (claimAccount.OrgCode === account.OrgCode && claimAccount.RoleId === account.RoleId) {
           return true;
        }

        return false;
      });
      if (!isAlreadyInMergedArray) {
        const account: Account = {
          OrgCode: claimAccount.code,
          Name: claimAccount.name,
          FortifyEnabled: claimAccount.FortifyEnabled,
          RoleId: claimAccount.RoleId
        }
        this.accountsMerged.push(account)
      }
    });

    console.warn(`List: staffAccounts (${this.staffAccounts.length})`)
    console.table(this.staffAccounts);
    this.staffAccounts.forEach((staffAccounts: Account) => {
      const isAlreadyInMergedArray = this.accountsMerged.find(account => {account.OrgCode === staffAccounts.OrgCode && account.RoleId === staffAccounts.RoleId});
      if (!isAlreadyInMergedArray) {
        const account: Account = {
          OrgCode: staffAccounts.OrgCode,
          Name: staffAccounts.Name,
          FortifyEnabled: staffAccounts.FortifyEnabled,
          RoleId: staffAccounts.RoleId
        }

        this.accountsMerged.push(account)
      }
    });

    this.accountsMerged = this.sortAccountListDescending(this.accountsMerged);

    console.warn(`Merged list of accounts quantity: (${this.accountsMerged.length})`)
    console.table(this.accountsMerged);

    this.handleAccountListContainerExpansionButton(this.accountsMerged);

    this.hasFinishedMergingAccounts = true;

    console.log(`%cMerge process finished 🏁`, "background-color: #01579b; color: white; padding: 7px");
    console.warn(this.notTPASatisdfiedAccounts, 'Not satisfied TPA accounts')
  }

  /**
   * Handles the account population based on if the user uses Fortify or not
   */
  handleAccountsPopulation() {
    console.log('A')
    return this.route.data.pipe(
      map(data => data.resolveData),
      tap((data: GetAccountsData) => {
        this.lcpService.validateUserName(data.username).subscribe((userDataInfo: GetUserResponseData) => {
          if (!userDataInfo.UsesFortify) {
            this.populateStaffAccountListWithAllAccounts(data.accounts);
          } else {
            this.populateList();
          }
        })
      }),
      take(1)
    ).subscribe();
  }

  /**
   * Sorts an array of accounts alphabetically descending (A-Z)
   * @param accounts
   * @returns An array of accounts ordered alphabetically descending
   */
  sortAccountListDescending(accounts: Account[]): Account[] {
    return accounts.sort((accountA, accountB) => accountA.Name.localeCompare(accountB.Name))
  }

  /**
   * Manages the template expand arrow button based on the accounts merged array length
   * @param accounts
   */
  handleAccountListContainerExpansionButton(accounts: Account[]): void {
    if (accounts.length > 4) {
      this.expand = true;
      this.showExpandArrows = true;
    } else {
      this.expand = false;
      this.showExpandArrows = false;
    }
  }

  /**
   * Populates the staff account array with all accounts
   * @returns All the accounts
   */
  populateStaffAccountListWithAllAccounts(accounts: Account[]): void {
    this.accounts = [];
    this.staffAccounts = accounts
  }

  /**
   * Gets data from the route component resolver
   */
  getRouteData(): void {
    this.route.data.pipe(
      map(data => data.resolveData),
      tap((data: GetAccountsData) => {
        console.log('Route Data Coming from Tracker : ');
        console.log(JSON.stringify(data));
        this.username = data.username;
        this.supportUsername = data.supportUsername;
        this.accounts = data.accounts;
      }),
      take(1)
    ).subscribe();
  }

  /**
   * Returns the specified portion of the state
   * @param featureState
   * @returns the specified portion of the state
   */
  getState(featureState) {
    let selectedState;
    this.store.select(featureState)
      .pipe(
        filter(state => !!state.idToken),
        take(1)
      ).subscribe(stateResponse => {
        selectedState = stateResponse;
      });

    return selectedState;
  }


  /**
   * Returns the array of accounts from the store without filters
   * @returns The array of accounts from the store without filters
   */
  getAccounts(): Account[] {
    let accounts;
    this.store.select('account')
      .pipe(
        take(1)
      ).subscribe(state => {
        accounts = state.accounts;
      });
    return accounts;
  }

  /**
   * Gets the impersonate support username from the state
   * @returns the impersonate support username
   */
  getImpersonate(): string {
    let impersonateSupportUsername;

    this.store.select('impersonate')
      .pipe(
        take(1)
      ).subscribe(state => {
        impersonateSupportUsername = state.supportUsername;
      });

    return impersonateSupportUsername;
  }

  /**
   * Handles the route redirection based on the route authorization and Fortify authorization
   */
  authRedirect(): void {
    let authRoute = this.storage.getItem('auth-route');
    if (!authRoute) {
      return;
    }
    this.routeAuth = JSON.parse(this.storage.getItem('auth-route'));
    console.log('auth-route coming from localstorage : ' + this.routeAuth);
    if (!this.routeAuth) { // for those who like to delete the session.
      this.router.navigate(['/login']);
    } else {
      if (this.routeAuth == 'direct' && this.fortifyAuth == undefined) {
        console.log('TPA enabled')
        this.router.navigate(['/fortify-direct']);
      } else if (this.routeAuth == 'redirect' && this.fortifyAuth == undefined) {
        console.log('MFA enabled')
        this.router.navigate(['/login/fortify-redirect']);
      }
    }
  }

  getAuths() {
    //
    // Check for tpa_org_codes and substring out into array.
    //
    console.log('--------------------------------------')
    console.log('%c -----------  AUTH DATA  --------------', 'color: black; background-color: yellow;')
    console.log('--------------------------------------');
    this.auth = this.getState('auth');


    this.fortifyAuth = this.getState('fortifyAuth');
    this.lcptrackerAuth = this.getState('lcptrackerAuth');
    this.supportUsername = this.getImpersonate();
    console.log('%c Impersonate : ', 'color: lightgreen; font-weight: bold; background-color: black;');
    console.log(JSON.stringify(this.supportUsername));
    this.accounts = this.getAccounts();
    console.log('%c Accounts : ', 'color: lightgreen; font-weight: bold; background-color: black;');
    console.table(this.accounts);
    console.log(JSON.stringify(this.accounts));
    console.log('%c AUTH : ', 'color: pink; font-weight: bold; background-color: black;');
    console.log(JSON.stringify(this.auth));
    console.log('%c FORTIFY auth : ', 'color: #8DB9E3; font-weight: bold; background-color: black;');
    // console.log(JSON.stringify(this.fortifyAuth));
    console.log('%c lcptracker AUTH : ', 'color: #dc3545; font-weight: bold; background-color: black;');
    console.log(JSON.stringify(this.lcptrackerAuth));
    //
    // Check on Auth and see where they came from, and let them repopulate Auth.
    //
    this.authRedirect();
  }

  /**
   * Populates the account list based on if the user uses Fortify
   */
  populateList(): void {
    // isSupportAdmin ? overrideSupport : lcptrackerAuth List.
    if (this.supportUsername != "") {
      console.log('%c Login Path # 6, 7, 8 ', 'color: white; font-weight: bold; background-color: green; padding: 5px; display: inline-block;');
      // 6. lcptracker shorthand name.
      // 7. SSO 'lcpgeneralstaff' + 'lcpdevstaff'
      // 8. fortify user @lcptracker.com
      this.checkStaffAdmin();
    }
    //
    // if NOT support, grab both Auth to display both versions (fortifyenabled == true is handled with Fortify).
    //
    else {
      if (this.fortifyAuth != undefined) {
        if (this.fortifyAuth.tpaOrgCodes &&
          this.fortifyAuth.tpaOrgCodes != ''

        ) {

          console.log('%c Login Path # 3 ', 'color: white; font-weight: bold; background-color: green; padding: 5px; display: inline-block;');
          // 3. SSO with tpaOrgCodes /fortify-direct.
          this.checkSSO();
        }
        else {
          console.log('%c Login Path # 2, 4 ', 'color: white; font-weight: bold; background-color: green; padding: 5px; display: inline-block;');
          // 2. Non-Tpa => MFA /fortify-redirect. Really gets from lcptrackerAuth();
          // 4. SSO with NO tpaOrgCodes /fortify-direct.
          this.checkFortifyAuth();
        }
      }
      console.log('%c Login Path # 1 ', 'color: white; font-weight: bold; background-color: green; padding: 5px; display: inline-block;');
      //
      // Look at Account Route resolver for List.
      //
      this.checklcptrackerAuth();
    }
  }

  checkClaimsMatches() {
    let count: number;
    console.info(':)' ,this.claimAccounts);
    if (this.claimAccounts != (null || undefined)) {
      count = this.claimAccounts.length + this.accounts.length;
    } else {
      count = this.accounts.length;
    }
    console.log('<-- List Count');
    console.log(count);
    //
    // Check IF there is ONLY 1.
    //
    if (count === 1 && !this.monetizationService.shouldPurchaseALicense) {
      if (this.claimAccounts.length && this.claimAccounts.length == 1) {
        console.log('<-- this.claimAccounts.length == 1');
        this.noMatches = false;
        // this.store.dispatch(AllActions.setOrgCodeStart({ orgCode: this.claimAccounts[0].code, orgCodes: this.claimAccounts.map(account => account.code), RoleId:JSON.stringify(this.accountsMerged[0].RoleId) }));
      } else {
        console.log('<-- this.accounts.length == 1');
        this.noMatches = false;
        this.store.dispatch(AllActions.setOrgCodeStart({ orgCode: this.accounts[0].OrgCode, orgCodes: this.accounts.map(account => account.OrgCode), RoleId:JSON.stringify(this.accountsMerged[0].RoleId) }));
      }
    }
    //
    // Check if it's greater than 4.
    //
    else if (count > 1) {
      console.log('<-- count > 1');
      this.noMatches = false;
      if (count > 4) {
        console.log('<-- count > 4');
        this.expand = true;
        this.showExpandArrows = true;
      }
    }
    //
    // 0 or -1 shows for both combined.
    //
    else {
      console.log('<-- NOTHING comes back.. 🐛🚀');
      this.noMatches = true;
    }
  }

  //
  // -----------------------   Staff -- GOD Mode --------------------
  //
  checkStaffAdmin() {
    this.route.data.pipe(
      map(data => data.resolveData),
      tap((data: GetAccountsData) => {
        console.log('Route Data Coming from Tracker : ');
        console.log(JSON.stringify(data));
        this.username = data.username;
        this.supportUsername = data.supportUsername;
        this.accounts = data.accounts;
        console.log('--------------------------------------')
        console.log('%c STAFF DATA', 'color: black; background-color: yellow;')
        console.log('--------------------------------------')
        console.log(JSON.stringify(this.accounts));
        this.staffAccounts = this.accounts;
        this.accounts = [];
        if (this.staffAccounts.length == 1) {
          //
          // Shows link and starts logging in the account.
          this.noMatches = false;
          this.store.dispatch(AllActions.setOrgCodeStart({ orgCode: this.staffAccounts[0].OrgCode, orgCodes: this.staffAccounts.map(account => account.OrgCode), RoleId:JSON.stringify(this.accountsMerged[0].RoleId) }));
        }
        else if (this.staffAccounts.length > 1) {
          this.noMatches = false;
          if (this.staffAccounts.length > 4) {
            this.expand = true;
            this.showExpandArrows = true;
          }
        } else {
          this.noMatches = true;
        }
      }),
      take(1)
    ).subscribe();
  }

  //
  // -----------------------   Fortify  -----------------------------
  //
  checkFortifyAuth() {
    this.accounts = [];
    // this.orgCodes = this.fortifyAuth.orgCodes;

    console.log('--------------------------------------')
    console.log('%c CLAIM DATA', 'color: black; background-color: yellow;')
    console.log('--------------------------------------')
    // 2. && 4.
    console.log('Initial Org Codes (checkFortifyAuth): ');
    console.table(this.orgCodes);
    for (let orgs of this.orgCodes) {
      switch (orgs.tpaRule) {
        case 0:
          console.log(orgs.name + ' HITS THE SWITCH tpaRule: ' + orgs.tpaRule);
          this.claimAccounts.push(orgs);
          break;
        case 1:
          console.log(orgs.name + ' HITS THE SWITCH tpaRule: ' + orgs.tpaRule);
          if (orgs.tpaRuleSatisfied === true) {
            this.claimAccounts.push(orgs);
          } else {
            this.notTPASatisdfiedAccounts.push(orgs.name)
          }
          break;
        case 2:
          console.log(orgs.name + ' HITS THE SWITCH tpaRule: ' + orgs.tpaRule);
          if (orgs.tpaRuleSatisfied === true) {
            this.claimAccounts.push(orgs);
          } else {
            this.notTPASatisdfiedAccounts.push(orgs.name)
          }
          break;
        case 3:
          console.log(orgs.name + ' HITS THE SWITCH tpaRule: ' + orgs.tpaRule);
          if (orgs.tpaRuleSatisfied === true) {
            this.claimAccounts.push(orgs);
          } else {
            this.notTPASatisdfiedAccounts.push(orgs.name)
          }
          break;
        default:
          console.log('Your not in the list Buddy!!!');
          break;
      }
    }
    console.log('Adjusted Org Codes (checkFortifyAuth): ');
    console.table(this.claimAccounts);
  }

  parseTPAs() {
    let parse = this.tpaOrgCodes.split(',');
    this.tpaOrgCodes = parse;
    console.log('TPA ORG CODES :');
    console.log(this.tpaOrgCodes);
  }

  compareTPAOrgCodes() {
    console.log('Initial Org Codes (compareTPAOrgCodes): ');
    console.table(this.orgCodes);
    console.table(this.tpaOrgCodes);

    if (this.tpaOrgCodes != (null || undefined)) {
      console.table(this.orgCodes);
      console.table(this.tpaOrgCodes);
      for (let tpa of this.tpaOrgCodes) {
        for (let orgs of this.orgCodes) {
          // check if the orgcode matches in both tpa and org_codes
          if (orgs.code == tpa) {
            switch (orgs.tpaRule) {
              case 0:
                console.log(orgs.name + ' HITS THE SWITCH tpaRule: ' + orgs.tpaRule);
                this.claimAccounts.push(orgs);
                break;
              case 1:
                console.log(orgs.name + ' HITS THE SWITCH tpaRule: ' + orgs.tpaRule);
                if (orgs.tpaRuleSatisfied === true) {
                  this.claimAccounts.push(orgs);
                }
                break;
              case 2:
                console.log(orgs.name + ' HITS THE SWITCH tpaRule: ' + orgs.tpaRule);
                if (orgs.tpaRuleSatisfied === true) {
                  this.claimAccounts.push(orgs);
                }
                break;
              case 3:
                console.log(orgs.name + ' HITS THE SWITCH tpaRule: ' + orgs.tpaRule);
                if (orgs.tpaRuleSatisfied === true) {
                  this.claimAccounts.push(orgs);
                }
                break;
              default:
                console.log('Your not in the list Buddy!!!');
            }
          }
        }
      }
    }
    console.log('Adjusted Org Codes (compareTPAOrgCodes): ');
    console.log(this.claimAccounts);
  }

  checkSSO() {
    // this.orgCodes = this.fortifyAuth.orgCodes;
    this.tpaOrgCodes = this.fortifyAuth.tpaOrgCodes;
    console.log('--------------------------------------')
    console.log('%c CLAIM DATA', 'color: black; background-color: yellow;')
    console.log('--------------------------------------')
    this.parseTPAs();
    this.compareTPAOrgCodes();
    this.accounts = [];
  }

  //
  // -----------------------   lcptracker  --------------------------
  //
  checklcptrackerAuth() {
    //
    // Grab Accounts data from Route Resolver.
    //
    this.route.data.pipe(
      map(data => data.resolveData),
      tap((data: GetAccountsData) => {
        console.log('Route Data Coming from Tracker : ');
        console.log(JSON.stringify(data));
        this.username = data.username;
        this.supportUsername = data.supportUsername;
        this.accounts = data.accounts
        console.table(this.accounts);
        console.log(JSON.stringify(this.accounts));
        this.checkClaimsMatches();
      }),
      take(1)
    ).subscribe();
  }

  /**
   * Returns the list of accounts that have Fortify disabled
   * @param accounts
   * @returns the list of accounts that have Fortify disabled
   * @deprecated this method is not been used as of July 2023, due to the implementation of RoleId
   * @previously it was implemented in checklcptrackerAuth method
   */
  getAccountsWithFortifyDisabled(accounts: Account[]): Account[] {
    return accounts.filter(account => !account.FortifyEnabled);
  }

  // ---------------------------------------------------------------------------
  // -----------------------   UX Experience   ---------------------------------
  // ---------------------------------------------------------------------------

  /**
   * Dispatches an action to set an organization code
   * @param event
   * @param account
   */
  selectAccount(account: Account): void {
    this.store.dispatch(AllActions.setOrgCodeStart({ orgCode: account.OrgCode, orgCodes: this.accountsMerged.map(account => account.OrgCode), RoleId: JSON.stringify(account.RoleId) }));
  }

  onPrevious() {
    if (this.supportUsername) {
      this.shared.setlayoutProject(false);
      this.router.navigate(['/login', 'impersonate']);
    } else {
      this.shared.setlayoutProject(false);
      this.router.navigate(['/login']);
    }
  }

  /**
   * Triggers an action to logout from Fortify
   */
  logoutFortify(): void {
    this.store.dispatch(AllActions.logoutFortify());
  }

  /**
   * Toggles the expand flag
   */
  toggleComplete(): void {
    this.expand = !this.expand;
  }

  /**
   * Filters the accounts list by manipulating the DOM
   * https://www.w3schools.com/howto/howto_js_filter_lists.asp
   * @param value
   */
  applyFilterMobile(value: string) {
    // Declare variables
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById('mobileFilterby');
    filter = input.value.toUpperCase();
    ul = document.getElementById("filter-list-mobile");
    li = ul.getElementsByTagName('li');
    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }

  /**
   * Filters the accounts list by manipulating the DOM
   * @param value
   */
  applyFilterDesktop(value: string): void {
    // Declare variables
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById('desktopFilterby');
    filter = input.value.toUpperCase();
    ul = document.getElementById("filter-list-desktop");
    li = ul.getElementsByTagName('li');
    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.innerText || a.textContent;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
    // *if filter has value remove multi-list.
    // *if NOT then check on length's and add multi-list claim.
    if (filter != '') {
      this.expand = false;
      this.showExpandArrows = false;
    } else {
      if (li.length > 4) {
        this.expand = true;
        this.showExpandArrows = true;
      }
    }
  }
}
