/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./custom-snack-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./custom-snack-bar.component";
import * as i7 from "@angular/material/snack-bar";
var styles_CustomSnackBarComponent = [i0.styles];
var RenderType_CustomSnackBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CustomSnackBarComponent, data: {} });
export { RenderType_CustomSnackBarComponent as RenderType_CustomSnackBarComponent };
export function View_CustomSnackBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "custom-snackbar-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "actions-buttons-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["color", "primary"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeSnackBar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Ok"]))], function (_ck, _v) { var currVal_3 = "primary"; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.html; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 5).disabled || null); var currVal_2 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_1, currVal_2); }); }
export function View_CustomSnackBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-snack-bar", [], null, null, null, View_CustomSnackBarComponent_0, RenderType_CustomSnackBarComponent)), i1.ɵdid(1, 49152, null, 0, i6.CustomSnackBarComponent, [i7.MAT_SNACK_BAR_DATA, i7.MatSnackBarRef], null, null)], null, null); }
var CustomSnackBarComponentNgFactory = i1.ɵccf("app-custom-snack-bar", i6.CustomSnackBarComponent, View_CustomSnackBarComponent_Host_0, {}, {}, []);
export { CustomSnackBarComponentNgFactory as CustomSnackBarComponentNgFactory };
