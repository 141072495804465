/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./busy.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./busy.component";
import * as i3 from "@ngrx/store";
import * as i4 from "@angular/material/dialog";
var styles_BusyComponent = [i0.styles];
var RenderType_BusyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BusyComponent, data: {} });
export { RenderType_BusyComponent as RenderType_BusyComponent };
export function View_BusyComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_BusyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-busy", [], null, null, null, View_BusyComponent_0, RenderType_BusyComponent)), i1.ɵdid(1, 245760, null, 0, i2.BusyComponent, [i3.Store, i4.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BusyComponentNgFactory = i1.ɵccf("app-busy", i2.BusyComponent, View_BusyComponent_Host_0, {}, {}, []);
export { BusyComponentNgFactory as BusyComponentNgFactory };
