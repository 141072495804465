import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { createDefaultLogger, createDefaultStorage } from 'src/app/models/factories';
import { CryptoHandler } from 'src/app/models/crypto-handler';
import { FortifyOAuthService } from './fortify-oauth.service';
import {
    OAuthModuleConfig,
    NullValidationHandler,
    UrlHelperService,
    OAuthLogger,
    OAuthStorage,
    ValidationHandler,
    OAuthResourceServerErrorHandler,
    OAuthNoopResourceServerErrorHandler,
    DefaultOAuthInterceptor,
    JwksValidationHandler,
    OAuthModule
} from 'angular-oauth2-oidc';

@NgModule({
    imports: [
        CommonModule,
        OAuthModule
    ]
})
export class FortifyOAuthModule {
    static forRoot(config?: OAuthModuleConfig, validationHandlerClass = NullValidationHandler): ModuleWithProviders {
        return {
            ngModule: FortifyOAuthModule,
            providers: [
                FortifyOAuthService,
                UrlHelperService,
                { provide: OAuthLogger, useFactory: createDefaultLogger },
                { provide: OAuthStorage, useFactory: createDefaultStorage },
                { provide: ValidationHandler, useClass: validationHandlerClass },
                { provide: CryptoHandler, useClass: JwksValidationHandler },
                {
                    provide: OAuthResourceServerErrorHandler,
                    useClass: OAuthNoopResourceServerErrorHandler
                },
                { provide: OAuthModuleConfig, useValue: config },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: DefaultOAuthInterceptor,
                    multi: true
                }
            ]
        };
    }
}
