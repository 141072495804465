<!-- Fortify Accounts List Container -->
<div class="sso-page-container">

  <!-- Fortify Accounts List Card -->
  <div class="sso-page-card sm:mx-2.28">

    <!-- LCPtracker Logo -->
    <img src="assets/logo.png" alt="LCPtracker Logo">

    <!-- Card Title -->
    <h4>Accounts</h4>

    <!-- Loading Spinner Container -->
    <div class="progress-spinner-container">

      <!-- Loading Spinner Component -->
      <mat-progress-spinner *ngIf="loading === true"
                            class="example-margin"
                            color="primary"
                            mode="indeterminate"
                            diameter="50">
      </mat-progress-spinner>

    </div>

    <!-- Account List Container -->
    <div class="list-container">

      <!-- Account List Table -->
      <ul class="table-style" *ngIf="loading === false">
        <div class="search-bar">
          <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Search accounts</mat-label>
            <input matInput type="text" placeholder="Search accounts" #value [formControl]="searchCriteria">
            <button type="button" mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchInputAndResetList()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <!-- Account List Items Iterator -->
        <ng-container *ngFor="let organization of orgListFiltered; let i = index;">

          <!-- Account List Row/Item -->
          <li class="table-row">

            <!-- Account List Row/Item Click Functionality -->
            <a tabindex="0"
               class="account-list lcp-hyperlink"
               (click)="setOrgCode(organization)"
               (keyup.enter)="setOrgCode(organization)">

              <!-- Account List Row/Item - Organization Name -->
              {{organization.Name}}

              <!-- Account List Row/Item Icon -->
              <span *ngIf="!isTPARuleSatisfied(organization)" class="material-icons">
                error
              </span>

            </a>

            <!-- TPA Rules Info Banner Container -->
            <ng-container *ngIf="displayTpaInfoBanner[i]">

              <!-- TPA Rules Info Banner Component -->
              <app-tpa-info-banner [organizationName]="organization.Name || organization.name">
              </app-tpa-info-banner>

            </ng-container>

          </li>

        </ng-container>

        <!-- No Results Found Banner for search bar -->
        <ng-container *ngIf="!orgListFiltered.length">
          <div class="no-results-banner">
            <mat-icon aria-hidden="false">search_off</mat-icon>
            <span>No results found</span>
          </div>
        </ng-container>

      </ul>

    </div>

    <a tabindex="0" class="logout"
       (click)="logout()"
       (keyup.enter)="logout()">Logout
    </a>
  </div>

  <!-- Footer - Blue bar -->
  <footer></footer>
</div>

