/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./progress.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "@angular/material/progress-bar";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./progress.component";
var styles_ProgressComponent = [i0.styles];
var RenderType_ProgressComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProgressComponent, data: {} });
export { RenderType_ProgressComponent as RenderType_ProgressComponent };
export function View_ProgressComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "mat-progress-bar"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "mat-progress-bar": 0, "hide": 1 }), i1.ɵdid(4, 4374528, [["progress", 4]], 0, i4.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i5.ANIMATION_MODULE_TYPE], [2, i4.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _ck(_v, 3, 0, true, (_co.show == false)); _ck(_v, 2, 0, currVal_3); var currVal_4 = _co.progressType; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 4).mode === "indeterminate") || (i1.ɵnov(_v, 4).mode === "query")) ? null : i1.ɵnov(_v, 4).value); var currVal_1 = i1.ɵnov(_v, 4).mode; var currVal_2 = i1.ɵnov(_v, 4)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ProgressComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progression-bar", [], null, null, null, View_ProgressComponent_0, RenderType_ProgressComponent)), i1.ɵdid(1, 114688, null, 0, i6.ProgressComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProgressComponentNgFactory = i1.ɵccf("mat-progression-bar", i6.ProgressComponent, View_ProgressComponent_Host_0, { show: "show" }, {}, []);
export { ProgressComponentNgFactory as ProgressComponentNgFactory };
