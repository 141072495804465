import { HttpHeaders, HttpParams } from '@angular/common/http';
import { filter, take, map } from 'rxjs/operators';
import jwt_decode from "jwt-decode";
import { RBS_EXTENDED_CLAIMS_KEYS, ORG_TYPES } from './../../models/consts';
import { environment } from './../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/common/http";
import * as i3 from "../../auth/fortify/fortify-oauth.service";
export class MonetizationService {
    constructor(store, http, fortifyOAuthService) {
        this.store = store;
        this.http = http;
        this.fortifyOAuthService = fortifyOAuthService;
        this.contractorsAccounts = [];
        this.user = {
            uuid: '',
            firstName: '',
            lastName: '',
            email: '',
        };
        this.isAContractorUser = false;
        this.hasLicenses = false;
        this.getIdToken();
        this.headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Bearer ${this.fortifyOAuthService.getAccessToken()}`,
        });
    }
    /**
     * Gets ID Token from the store
     */
    getIdToken() {
        this.store.select('fortifyAuth')
            .pipe(filter(state => !!state.idToken), map(state => state.idToken), take(1))
            .subscribe(idToken => {
            this.idDecodedToken = jwt_decode(idToken);
            this.idToken = idToken;
            this.setUserInfo();
        });
    }
    /**
     * Sets the main user info from the ID Token
     */
    setUserInfo() {
        this.user.uuid = this.idDecodedToken.sub;
        this.user.firstName = this.idDecodedToken.given_name ? this.idDecodedToken.given_name : '';
        this.user.lastName = this.idDecodedToken.family_name ? this.idDecodedToken.family_name : '';
        this.user.email = this.idDecodedToken.email;
    }
    /**
     * Checks if the user is a contractor based on the RBS extended claims
     * If the RBS contains the CONTRACTOR_LICENSES key, it means that the user is a contractor. Otherwise, it is not.
     * And if the user has contractor information in the Account List (OrgType4)
     * @param claims
     * @returns Wether the user is a contractor or not
     */
    isAContractor(claims) {
        const contractorLicenseClaim = claims.find(claim => claim.type == RBS_EXTENDED_CLAIMS_KEYS.CONTRACTOR_LICENSES);
        this.contractorsAccounts = this.getContractorAccountsFromRBS(claims);
        console.log(`%c  Contractors Accounts  `, "background-color: black; color: white; padding: 4px; border-radius: 7px, display: inline-block;");
        console.table(this.contractorsAccounts);
        this.isAContractorUser = (contractorLicenseClaim && this.contractorsAccounts) ? true : false;
        return this.isAContractorUser;
    }
    /**
     * Determines if a user has expired licenses by checking the CONTRACTOR_LICENSES key.
     * If the key exists and is and empty array it means that the user is a contractor and has expired license
     * @param claims
     * @returns Wether the user has expired licenses or not
     */
    hasActiveLicenses(claims) {
        if (!this.isAContractor(claims)) {
            return false;
        }
        const contractorLicenseClaim = claims.find(claim => claim.type == RBS_EXTENDED_CLAIMS_KEYS.CONTRACTOR_LICENSES);
        console.log({ contractorLicenseClaim }, "MTC");
        if (contractorLicenseClaim) {
            if (contractorLicenseClaim.value.length) {
                console.log(`%c  Contractors Accounts  `, "background-color: black; color: white; padding: 4px; border-radius: 7px, display: inline-block;");
                this.hasLicenses = contractorLicenseClaim.value.some(claim => claim.lic.length > 0);
                return this.hasLicenses;
            }
        }
        return false;
    }
    get getContractorAccounts() {
        return this.contractorsAccounts;
    }
    get getUUID() {
        return this.idDecodedToken.sub;
    }
    get isUserAContractor() {
        return this.isAContractorUser;
    }
    get shouldPurchaseALicense() {
        return this.isAContractorUser && !this.hasLicenses;
    }
    /**
     * Checks if the user has many contractor accounts
     * @param claims
     * @returns
     */
    hasManyContractorAccounts(claims) {
        const contractorAccounts = this.getContractorAccountsFromRBS(claims);
        if (contractorAccounts) {
            console.log('HAS MANY contractor ACCOUNTS:', contractorAccounts.length > 1);
            return contractorAccounts.length > 1;
        }
        return false;
    }
    hasOnlyOneContractorAccount(claims) {
        const contractorAccounts = this.getContractorAccountsFromRBS(claims);
        return contractorAccounts.length == 1;
    }
    /**
     * Checks if a banner to purchase a license should be displayed.
     * @param claims
     * @returns If a banner to purchase a license should be displayed.
     */
    checkShouldPurchaseALicenses(claims) {
        return this.isAContractor(claims) && !this.hasActiveLicenses(claims);
    }
    /**
     * Calls the RBS API to build the needed rows in the backend
     */
    prePaymentSetupCreateIds(orgId, orgName) {
        console.log('Calling RBS /build-contractors API and waiting Salesforces ID');
        const params = new HttpParams({
            fromObject: { UserId: this.user.uuid, OrgId: orgId },
        });
        const options = {
            headers: this.headers
        };
        return this.http.post(`${environment.fortifyAPIUrl}/api/SalesForceIntegration/CreateIds`, params.toString(), options)
            .pipe(map(response => response.data));
    }
    /**
     * Redirects the user to Chargify with a custom URL
     */
    redirectToChargify(saleforceId, orgName) {
        const publicChargifyUrl = `${environment.chargifyUrl}?first_name=${this.user.firstName}&last_name=${this.user.lastName}&email=${this.user.email}&reference=${saleforceId}&organization=${orgName}`;
        window.location.href = publicChargifyUrl;
    }
    /**
     * Checks if the logged user has a valid license by consulting Fortify
     * @returns if the user has a valid license
     */
    hasFoundLicenseByUser() {
        if (!this.user.uuid) {
            return;
        }
        const options = {
            headers: this.headers
        };
        return this.http.get(`${environment.fortifyAPIUrl}/api/SalesForceIntegration/GetLicForUser/${this.user.uuid}`, options)
            .pipe(map(response => response.data));
    }
    /**
     * Checks if the logged user has a valid license by checking the contractor license
     * @returns if the contractor has a valid license
     */
    hasFoundLicenseByOrg(orgId, emailParam = null) {
        const options = {
            headers: this.headers
        };
        let email = (this.user.email) ? this.user.email : emailParam;
        return this.http.get(`${environment.fortifyAPIUrl}/api/SalesForceIntegration/GetLicForOrg/${orgId}/${email}`, options)
            .pipe(map(response => response.data));
    }
    /**
     * Returns the contractors account based on the RBS Claim object
     * @param claims
     * @returns
     */
    getContractorAccountsFromRBS(claims) {
        let orgCodeFromRBS = claims.find(claim => claim.type == RBS_EXTENDED_CLAIMS_KEYS.ORG_CODES);
        let orgCode = (orgCodeFromRBS) ? orgCodeFromRBS.value : [];
        return orgCode.filter(account => account.orgType == ORG_TYPES.CONTRACTOR);
    }
}
MonetizationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MonetizationService_Factory() { return new MonetizationService(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.FortifyOAuthService)); }, token: MonetizationService, providedIn: "root" });
