import { HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "angular-oauth2-oidc";
export class Net6AccountsService {
    constructor(http, oAuthService) {
        this.http = http;
        this.oAuthService = oAuthService;
    }
    /**
     * Extended claims from .NET6 (https://{{host}}/api/RbsClaims/V02/json/GetExtendedIdentityClaims)
     * @returns
     */
    getRBSExtendedNET6Claims() {
        const token = this.oAuthService.getAccessToken();
        const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        return this.http.get('https://devv2.fortify.lcptracker.net/api/RbsClaims/V02/json/GetExtendedIdentityClaims', { headers }).pipe(map(res => res.data));
    }
    /**
     * Get accounts from Tracker
     * @returns
     */
    getAccounts() {
        return this.http.get('https://httpbin.org/delay/1500?arg=');
    }
}
Net6AccountsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Net6AccountsService_Factory() { return new Net6AccountsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.OAuthService)); }, token: Net6AccountsService, providedIn: "root" });
