
<!-- V2 Impersonation Card -->
<div class="sso-page-container">
    <div class="sso-page-card sm:mx-2.28">
        <!-- LCPtracker logo -->
        <img src="assets/logo.png" alt="LCPtracker Logo">       
        <!-- V2 Impersonation Form -->
        <div class="error-actions">        
            <!-- V2 Impersonation Form Title -->
            <span>
                <strong for="username">Impersonate User </strong>
                <mat-icon>
                    <span class="tooltiptext" title="To impersonate a Support Admin access enter FMIADMIN To impersonate a specific users access, enter an email address.">error_outline</span>    
                </mat-icon>
            </span>
            <form class="impersonate-form" (ngSubmit)="onSubmit()" [formGroup]="impersonateForm">
                <div>
                    <mat-form-field class="example-full-width">
                        <mat-label>username</mat-label>
                        <input matInput formControlName="username">
                    </mat-form-field>
                </div>

                <div class="error-links">

                    <app-button (buttonClicked)="goBack()" (enterKeyUp)="goBack()" label="Dashboard" buttonType="flat" prefixIcon="arrow_back" ></app-button>
                    <div class="button-wrapper storybook-button">
                        <button id="next" mat-button class="storybook-button--flat" type="submit" [disabled]="impersonateForm.invalid">Next 
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <footer></footer>
</div>