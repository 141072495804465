import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ContentfulService } from '../../services/contentful/contentful.service';
import { SharedService } from '../../services/shared/shared.service';
import { AppState } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';
//
// ngrx / rxjs
//
import { Store } from '@ngrx/store';
import { StorageService } from 'src/app/services/storage/storage.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  errorClose : boolean = false;
  showProgressBar : boolean = false;
  projectLayout : boolean = false;
  showFortifyLink : boolean = false;
  random : number = 0;
  img : string;
  imgId : string;
  imgLabel: string;
  imgLink : string;
  lcpCertifiedUrl: string;
  imageURLAsset: string;

  constructor(
    private store: Store<AppState>,
    private contentful : ContentfulService,
    private shared : SharedService,
    private storage : StorageService
  ) {
  }

  ngOnInit() {

    if (environment.EnableClarityTracking) { this.shared.clarityTracking(); }
    this.lcpCertifiedUrl = environment.lcpCertifiedUrl;

    this.contentful.getAd().subscribe(data => {
      if ( data != (null || undefined)) {
        this.img = data.items[0].fields.image;
        this.imgId = data.items[0].fields.id;
        this.imgLabel = data.items[0].fields.label;
        this.imgLink = data.items[0].fields.link;
        this.imageURLAsset = data.items[0].fields.asset.fields.file.url;
      }
    })
    this.shared.getError().subscribe(data => {
      if ( data != (null || undefined)) {
        this.errorClose = data;
      }
    })
    this.shared.getLinkFortifyLogout().subscribe(data => {
      if ( data != (null || undefined)) {
        this.showFortifyLink = data;
      }
    })
    this.shared.getProgressBar().subscribe(data => {
      if ( data != (null || undefined)) {
        this.showProgressBar = data;
      }
    })
    this.shared.getlayoutProject().subscribe(data => {
      if ( data != (null || undefined)) {
        this.projectLayout = data;
      }
    })



  }


  // ---------------------------------------------------------------------------
  // -----------------------   UX Experience   ---------------------------------
  // ---------------------------------------------------------------------------
  closeErrorAnimation() : void {
    this.errorClose = true;
  }
  logoutFortify() {
    this.store.dispatch(AllActions.logoutFortify());
  }

}
