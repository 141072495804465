export const RBS_EXTENDED_CLAIMS_KEYS = {
  ORG_CODES: 'org_codes',
  CONTRACTOR_LICENSES: 'lcptp_Contractor_Licenses'
}
export const ORG_TYPES = {
  FEDERAL: 0,
  STATE: 1,
  AGENCY: 2,
  PRIME: 3,
  CONTRACTOR: 4,
  MILITARY: 5,
  REHABILITATION: 6,
  TRAINING: 7
}

export const SESSION_STORAGE = {
  SELECTED_CONTRACTOR_ID : 'selectedContractorId'
}