import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MessageFromBackEnd } from "../interfaces/response-message";

@Injectable({
  providedIn: "root",
})
export class OtpEmailService {
  loadingSendEmailSubject = new BehaviorSubject<boolean>(true);
  loadingSendEmailSubject$ = this.loadingSendEmailSubject.asObservable();

  constructor(private _http: HttpClient) {}

  /**
   * Send code called OTP via Email for Email Authenticated (Double check in UI)
   * @param email
   * @returns
   */
  sendCodeViaEmail(email: string): Observable<MessageFromBackEnd> {
    const formData = new FormData();
    formData.append("emailaddress", email);
    this.loadingSendEmailSubject.next(true);
    return this._http
      .post<MessageFromBackEnd>(
        `${environment.fortifyV2APIUrl}/IPPolicy/GetVerificationCode`,
        formData
      )
      .pipe(
        tap((): void => {
          this.loadingSendEmailSubject.next(false);
        })
      );
  }
}
