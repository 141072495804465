import { Action, createReducer, on } from '@ngrx/store';
import { statePassword } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';

const initialState: statePassword = {
    loading: false,
    errorMessage: '',
    username: null,
    isSupportUser: false,
    enableFullContractor: null,
    isEmailContractorUser: false
};

const passwordReducer = createReducer(
    initialState,

    on(AllActions.validatePasswordStart,
        state => ({ ...state, errorMessage: '', loading: true })),
    on(AllActions.validatePasswordSuccess,
        (state, action) => ({ ...state, loading: false, username: action.username, isSupportUser: action.isSupportUser, enableFullContractor: action.enableFullContractor, isEmailContractorUser: action.isEmailContractorUser })),
    on(AllActions.validatePasswordFail,
        (state, action) => ({ ...state, loading: false, errorMessage: action.errorMessage })),
);

export function reducer(state: statePassword | undefined, action: Action) {
    return passwordReducer(state, action);
}
