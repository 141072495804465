import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { filter, take } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LcpService } from 'src/app/services/lcp/lcp.service';
import { FullContractor, FullContractorResponse, AppState } from 'src/app/models/interfaces';
import { StorageService } from 'src/app/services/storage/storage.service';
import { GetUserResponseData } from './../../../models/interfaces';

interface ValidationMessage {
  type: string;
  message: string;
}

interface FormValidationMessages {
  [x: string] : ValidationMessage[];
}

@Component({
  selector: 'app-full-contractor',
  templateUrl: './full-contractor.component.html',
  styleUrls: ['./full-contractor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FullContractorComponent implements OnInit {
  FIELD_NAME_MAX_LENGTH: number = 30;
  FIELD_NAME_MIN_LENGTH: number = 2;
  FIELD_PHONE_MAX_LENGTH: number = 10;
  FIRST_LAST_NAME_REGEX = new RegExp("^([a-zA-Z]+[',.-]?[ ]?){1,2}$");
  PHONE_REGEX = new RegExp(`^(?!0{${this.FIELD_PHONE_MAX_LENGTH}})\\d{${this.FIELD_PHONE_MAX_LENGTH}}$`);
  FIELD_EMAIL_TRACKER_REGEX = new RegExp("(^$)|^((([a-zA-Z]|\\d|[!#\\$%&'\\*\\+\\-\/=\\?\\^_`{\\|}~]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+(\\.([a-zA-Z]|\\d|[!#\\$%&'\\*\\+\\-\\/=\\?\\^_`{\\|}~]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+)*)|((\\x22)((((\\x20|\\x09)*(\\x0d\\x0a))?(\\x20|\\x09)+)?(([\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x7f]|\\x21|[\\x23-\\x5b]|[\\x5d-\\x7e]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(\\\\([\\x01-\\x09\\x0b\\x0c\\x0d-\\x7f]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF]))))*(((\\x20|\\x09)*(\\x0d\\x0a))?(\\x20|\\x09)+)?(\\x22)))@((([a-zA-Z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])|(([a-zA-Z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])([a-zA-Z]|\\d|-||_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])*([a-zA-Z]|\\d|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])))\\.)+(([a-zA-Z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+|(([a-zA-Z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])+([a-zA-Z]+|\\d|-|\\.{0,1}|_|~|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])?([a-zA-Z]|[\\u00A0-\\uD7FF\\uF900-\\uFDCF\\uFDF0-\\uFFEF])))$");

  responseFullContractor: FullContractorResponse;

  isSendingRequest: boolean = false;
  isRequestSent: boolean = false;

  routeAuth: string;
  fortifyAuth: any;

  usesFortify: boolean = null;

  enableFullContractorForm = this.fb.group({
    contractorLicense: ['', Validators.required],
    firstName: ['', [Validators.required, Validators.minLength(this.FIELD_NAME_MIN_LENGTH), Validators.maxLength(this.FIELD_NAME_MAX_LENGTH), Validators.pattern(this.FIRST_LAST_NAME_REGEX)]],
    lastName: ['', [Validators.required, Validators.minLength(this.FIELD_NAME_MIN_LENGTH), Validators.maxLength(this.FIELD_NAME_MAX_LENGTH), Validators.pattern(this.FIRST_LAST_NAME_REGEX)]],
    email: ['', [Validators.required, Validators.pattern(this.FIELD_EMAIL_TRACKER_REGEX)]],
    phoneNumber: ['', [Validators.required, Validators.pattern(this.PHONE_REGEX)]],
  });

  formValidationMessages: FormValidationMessages = {
    'firstName': [
      { type: 'required', message: 'First name is required'},
      { type: 'minlength', message: `First name must be greater that ${this.FIELD_NAME_MIN_LENGTH} characters`},
      { type: 'maxlength', message: `First name must not exceed ${this.FIELD_NAME_MAX_LENGTH} characters`},
      { type: 'pattern', message: `First name should be from a-Z A-Z. Allowed special chars ('. -,) one time per word and not consecutive. Max two words.`}
    ],
    'lastName': [
      { type: 'maxlength', message: `Last name must not exceed ${this.FIELD_NAME_MAX_LENGTH} characters`},
      { type: 'minlength', message: `First name must be greater that ${this.FIELD_NAME_MIN_LENGTH} characters`},
      { type: 'required', message: 'Last name is required'},
      { type: 'pattern', message: `Last name should be from a-Z A-Z. Allowed special chars ('. -,) one time per word and not consecutive. Max two words.`}
    ],
    'email': [
      { type: 'required', message: 'Email is required'},
      { type: 'email', message: 'Email is not valid'},
      { type: 'pattern', message: 'Email is not valid. Please enter a valid email.'},
    ],
    'phoneNumber': [
      { type: 'required', message: 'Phone number is required'},
      { type: 'pattern', message: `Phone should be only numbers. 10 digits length. No white spaces.`}
    ],
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private lcpService: LcpService,
    private storage: StorageService,
    private store: Store<AppState>,
    private snackBar: MatSnackBar
  ) {
    this.handleReload();
  }

  ngOnInit() {
    let userId: string = this.route.snapshot.paramMap.get("username");
    this.enableFullContractorForm.get('contractorLicense').setValue(userId);
    this.checkUser(userId);
  }

  /**
   * Shorthand to handle form controls
   */
  get f(): {[x: string]: AbstractControl} {
    return this.enableFullContractorForm.controls
  }

  /**
   * Disables all form inputs
   */
  disableFormInputs(): void {
    for (var control in this.enableFullContractorForm.controls) {
      this.enableFullContractorForm.controls[control].disable();
      this.enableFullContractorForm.controls[control].markAsUntouched();
    }
  }

  /**
   * Enables all form values
   */
  enableFormInputs(): void {
    for (var control in this.enableFullContractorForm.controls) {
      this.enableFullContractorForm.controls[control].enable();
    }
  }

  /**
   * Makes the request to send the user info to be linked with the contractor license
   */
  enableFullContractor(): void {
    if (!this.enableFullContractorForm.valid) {
      this.snackBar.open('Form is invalid', 'CLOSE', { duration: 5000, verticalPosition: "top"});
      return;
    }

    this.isSendingRequest = true;
    this.disableFormInputs();
    this.trimInputs();

    let fullContractor: FullContractor = {
      UserName: this.enableFullContractorForm.get('contractorLicense').value,
      FirstName: this.enableFullContractorForm.get('firstName').value,
      LastName: this.enableFullContractorForm.get('lastName').value,
      Email: this.enableFullContractorForm.get('email').value.toLowerCase(),
      PhoneNumber: this.enableFullContractorForm.get('phoneNumber').value,
    }

    this.lcpService.enableFullContractorUser(fullContractor).subscribe((response: FullContractorResponse) => {
      this.responseFullContractor = response;

      this.isSendingRequest = false;
      this.isRequestSent = true;

      this.enableFormInputs();
    }, error => {
      console.log(error)

      this.isSendingRequest = false;
      this.isRequestSent = true;

      this.enableFormInputs();
    })
  }

  /**
   * Goes back to the previous page
   */
  goBack(): void {
    this.router.navigate(['/login', this.enableFullContractorForm.get('contractorLicense').value]);
  }

  /**
   * Cleans up the vars to allow the user fill up the form again
   */
  tryFormAgain(): void {
    this.isRequestSent = false;
    this.isSendingRequest = false;
  }

  /**
   * Handles the component reload, route redirection based on the route authorization and Fortify authorization
   * Note: Code based on accounts.component.ts route redirection
   */
  handleReload(): void {
    const authRoute = this.storage.getItem('auth-route');

    if (!authRoute) {
      this.router.navigate(['/login']);
    }

    this.fortifyAuth = this.getState('fortifyAuth');
    this.routeAuth = JSON.parse(authRoute);

    if (this.routeAuth == 'redirect' && this.fortifyAuth == undefined) {
      this.router.navigate(['/login/fortify-redirect']);
    }
  }

  /**
   * Returns the specified portion of the state
   * @param featureState
   * @returns the specified portion of the state
   */
  getState(featureState) {
    let selectedState;
    this.store.select(featureState)
      .pipe(
        filter(state => !!state.idToken),
        take(1)
      ).subscribe(stateResponse => {
        selectedState = stateResponse;
      });

    return selectedState;
  }

  /**
   * Makes a request to check if the user uses Fortify
   * @param userId
   */
  checkUser(userId: string): void {
    this.lcpService.validateUserName(userId)
      .subscribe((response: GetUserResponseData) => {
        this.usesFortify = response.UsesFortify
      }, error => {
        this.router.navigate(['/login']);
      })
  }

  /**
   * Trims each input of the form contractor
   */
  trimInputs(): void {
    for (var control in this.enableFullContractorForm.controls) {
      let trimInput = this.enableFullContractorForm.controls[control].value.trim();
      this.enableFullContractorForm.controls[control].setValue(trimInput);
    }
  }
}
