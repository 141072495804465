<!-- Full Contractor Onboarding Card -->
<div class="full-contractor-onboarding-card">

  <!-- LCPtracker logo -->
  <img src="assets/logo.png" alt="LCPtracker Logo">

  <!-- Full Contractor Onboarding Form -->
  <div class="full-contractor-onboarding-form">

    <!-- Full Contractor Onboarding Form Title -->
    <h4>Contractor License</h4>
    <div class="full-contractor-onboarding-form-advertisement mb-2">
      <!-- Full Contractor Onboarding Form Advertisement OTP Email Message -->
      <p>
        In this step, you'll be able to update your current Username to an email address. The email will now be your new
        Username.
      </p>
    </div>
    <!-- Full Contractor Onboarding Form Advertisement -->
    <div class="full-contractor-onboarding-form-advertisement">

      <!-- Icon Wrapper -->
      <mat-icon>

        <!-- "Warning" Icon -->
        <span class="notification-icon">error_outline</span>

      </mat-icon>

      <!-- Full Contractor Onboarding Form Advertisement Message -->
      <p>Please make sure to fill out all the required fields provided below.</p>

    </div>

    <!-- Full Contractor Onboarding Form Inputs -->
    <div class="full-contractor-onboarding-form-inputs-container" [formGroup]="newFullContractorOnboarding">

      <!-- First name field -->
      <mat-form-field appearance="standard">

        <!-- Field Label -->
        <mat-label>First name</mat-label>

        <!-- Input - Text -->
        <input aria-label="firstName" matInput color="primary" formControlName="firstName" type="text" required>

        <!-- Error Hint -->
        <mat-hint>
          <ng-container *ngFor="let validation of formValidationMessages.firstName">
            <p class="error-message" *ngIf="
            fullContractorForm.firstName.hasError(validation.type) &&
            (fullContractorForm.firstName.dirty || fullContractorForm.firstName.touched)
          ">{{validation.message}}</p>
          </ng-container>
        </mat-hint>

      </mat-form-field>

      <!-- Last name field - Text -->
      <mat-form-field appearance="standard">

        <!-- Field Label -->
        <mat-label>Last name</mat-label>

        <!-- Input - Text -->
        <input aria-label="lastName" matInput color="primary" formControlName="lastName" type="text" required>

        <!-- Error Hint -->
        <mat-hint>
          <ng-container *ngFor="let validation of formValidationMessages.lastName">
            <p class="error-message" *ngIf="
              fullContractorForm.lastName.hasError(validation.type) &&
              (fullContractorForm.lastName.dirty || fullContractorForm.lastName.touched)
            ">{{validation.message}}</p>
          </ng-container>
        </mat-hint>

      </mat-form-field>

      <!-- Email field - Text -->
      <mat-form-field appearance="standard">

        <!-- Field Label -->
        <mat-label>Email</mat-label>

        <!-- Input - Text | Email -->
        <input aria-label="email" matInput color="primary" formControlName="email" type="text"
          (copy)="preventCopyingOnClipboardEvent($event)" required>

        <!-- Error Hint -->
        <mat-hint>
          <ng-container *ngFor="let validation of formValidationMessages.email">
            <p class="error-message" *ngIf="
              fullContractorForm.email.hasError(validation.type) &&
              (fullContractorForm.email.dirty || fullContractorForm.email.touched)
            ">
              {{validation.message}}
            </p>
          </ng-container>
        </mat-hint>

      </mat-form-field>

      <!-- Confirm Email field - Text -->
      <mat-form-field appearance="standard">

        <!-- Field Label -->
        <mat-label>Confirm email</mat-label>

        <!-- Input - Text | Confirm Email -->
        <input aria-label="confirmEmail" matInput color="primary" formControlName="confirmEmail" type="text"
          (paste)="preventPasteOnClipboardEvent($event)" required>

        <!-- Error Hint -->
        <mat-hint>
          <ng-container *ngFor="let validation of formValidationMessages.confirmEmail">
            <p class="error-message" *ngIf="
            fullContractorForm.confirmEmail.hasError(validation.type) &&
              (fullContractorForm.confirmEmail.dirty || fullContractorForm.confirmEmail.touched)
            ">
              {{validation.message}}
            </p>
          </ng-container>
        </mat-hint>

      </mat-form-field>

      <!-- Phone field - Text -->
      <mat-form-field appearance="standard">

        <!-- Field Label -->
        <mat-label>Phone</mat-label>

        <!-- Input - Number -->
        <input aria-label="phoneNumber" matInput color="primary" formControlName="phoneNumber" type="number" required>

        <!-- Error Hint -->
        <mat-hint>
          <ng-container *ngFor="let validation of formValidationMessages.phoneNumber">
            <p class="error-message" *ngIf=" fullContractorForm.phoneNumber.hasError(validation.type) &&
              (fullContractorForm.phoneNumber.dirty || fullContractorForm.phoneNumber.touched)
              ">{{validation.message}}</p>
          </ng-container>
        </mat-hint>

      </mat-form-field>

    </div>

    <!-- Full Contractor Onboarding Form Buttons -->
    <div class="full-contractor-onboarding-form-buttons-container">

      <!-- Cancel Button -->
      <app-button buttonType="flat" label="Cancel" (buttonClicked)="goBack()"></app-button>

      <!-- Confirm Button -->
      <app-button buttonType="primary" label="Confirm" [isDisabled]="!newFullContractorOnboarding.valid"
        (buttonClicked)="submitForm()"></app-button>

    </div>

  </div>
