<!-- Component Wrapper -->
<div class="account-selector-card">

  <!-- LCPtracker Logo Container -->
  <div class="text-center">

    <!-- LCPtracker Logo -->
    <img src="assets/logo.png" alt="LCPtracker Logo">

  </div>
  <div *ngIf="!(showMessageFromBackEnd$ | async); else messageFromBackEnd">
    <section class="selector-database">

      <div class="selector-database-title">
        <h4>Account Selection</h4>
        <p>In this step, you must select the accounts that you need to continue accessing with your updated Username <b>{{userEmail}}</b>.
          <br/>
           <b>Note:</b> You will no longer have access for unselected accounts.
        </p>
      </div>
      <mat-progress-bar *ngIf="(loadingSubject$ | async)" mode="indeterminate" value="40"></mat-progress-bar>
      <div class="table-account-container">
        <cdk-virtual-scroll-viewport itemSize="15" class="virtual-scroll-container">
          <table aria-describedby="table body" class="table-content">
            <thead>
              <tr>
                <th class="mat-header-cell mat-header-lcp-cell" [style.top]="inverseOfTranslation">
                  <div class="select-all-column-header">
                    <p (click)="$event ? toggleAllRows() : null">{{ labelSelectAll }}</p>
                    <mat-checkbox
                      [aria-label]="checkboxColumnTitle()"
                      [disabled]="(loadingSendInformationSubject$ | async) || (loadingSubject$ | async)"
                      [checked]="rowsSelection.hasValue() && isAllSelected()"
                      [indeterminate]="rowsSelection.hasValue() && !isAllSelected()"
                      (change)="$event ? toggleAllRows() : null"
                    >
                    </mat-checkbox>
                  </div>
                </th>
                <th class="mat-header-cell mat-header-lcp-cell" [style.top]="inverseOfTranslation">
                  <div class="account-column-header">
                    <span>
                      Account Name
                    </span>
                    <div class="selected-accounts-counter">
                      <span class="selected-accounts-counter-label">
                        Selected Items:
                      </span>
                      <span class="selected-accounts-counter-value">
                        {{selectedItems}} of {{userAccounts}}
                      </span>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *cdkVirtualFor="let row of itemsDataSource">
                <tr>
                  <td class="w-28 text-center">
                    <mat-checkbox [disabled]="(loadingSendInformationSubject$ | async) || (loadingSubject$ | async)"
                      onkeypress="javascript:void(0)" (click)="$event.stopPropagation()"
                      (change)="$event ? rowsSelection.toggle(row) : null" [checked]="rowsSelection.isSelected(row)"
                      [aria-label]="checkboxColumnTitle(row)">
                    </mat-checkbox>
                  </td>
                  <td class="w-9/12 cell-wrap">{{ row.Name}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </cdk-virtual-scroll-viewport>
      </div>

      <div *ngIf="!(loadingSubject$ | async)" class="legal-labels">
        <mat-checkbox [disabled]="(loadingSendInformationSubject$ | async) || (loadingSubject$ | async)"
          [(ngModel)]="checkboxLegalAgreements" (change)="acceptLegalAgreementsChange()">
          <p class="checkbox-legal">
              The chosen checked accounts above are those to which
              I am entitled to access and need to continue accessing
          </p>
        </mat-checkbox>
      </div>

      <!-- Full Contractor Onboarding Form Buttons -->
      <div class="action-buttons">

        <!-- Cancel Button -->
        <app-button buttonType="flat" label="Cancel" (buttonClicked)="cancelAccountSelection()"></app-button>

        <!-- Confirm Button -->
        <app-button buttonType="primary" label="Confirm"
          [isDisabled]="(isCountButtonToConfirm) || (loadingSubject$ | async)"
          (buttonClicked)="confirmSelectionToSendDB()"></app-button>

      </div>

    </section>
  </div>

  <ng-template #messageFromBackEnd>
    <div class="container-message">
      <p [innerHTML]="messageBackEnd | emailHighlighter"></p>
      <a [tabIndex]="1" (keydown.enter)="goToUserPortal()" (click)="goToUserPortal()">Go to User Portal</a>
    </div>
  </ng-template>
</div>
