import * as tslib_1 from "tslib";
import { environment } from 'src/app/../environments/environment';
import * as contentful from 'contentful';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class ContentfulService {
    constructor() {
        this.client = contentful.createClient({
            space: environment.contentful.spaceId,
            accessToken: environment.contentful.token
        });
        //
        // Shared Services.
        //
        this.back$ = new BehaviorSubject(null);
        this.ad$ = new BehaviorSubject(null);
    }
    //
    // Contentful Client API
    // https://contentful.github.io/contentful.js/contentful/7.13.1/ContentfulClientAPI.html
    //
    getEntries_ContentTypeId(id) {
        return this.client.getEntries({
            'content_type': id,
        });
    }
    //
    // Get BOTH Background / Login Async
    //
    fetchContentful() {
        const content = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const loginAd = yield this.getEntries_ContentTypeId('loginAdvertisement');
            const backgroundImage = yield this.getEntries_ContentTypeId('backgroundImage');
            return Promise.all([loginAd, backgroundImage]);
        });
        content().then(res => {
            // console.log(res);
            this.setAd(res[0]);
            let random = Math.floor(Math.random() * res[1].items.length);
            this.setBack(res[1].items[random]);
            localStorage.setItem('contentful-entries', JSON.stringify(res));
        });
    }
    getBack() {
        return this.back$.asObservable();
    }
    setBack(value) {
        this.back$.next(value);
    }
    getAd() {
        return this.ad$.asObservable();
    }
    setAd(value) {
        this.ad$.next(value);
    }
}
ContentfulService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContentfulService_Factory() { return new ContentfulService(); }, token: ContentfulService, providedIn: "root" });
