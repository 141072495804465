import { Component, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationDialogService } from '../../../shared/dialog/services/confirmation-dialog.service';
import { ContractorSelectorAccountService } from '../account-selector/service/contractor-selector-account.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FullContractorAccountResponse } from '../account-selector/interfaces/database-interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-contractor-user-portal',
  templateUrl: './contractor-user-portal.component.html',
  styleUrls: ['./contractor-user-portal.component.scss'],
})
export class ContractorUserPortalComponent implements OnInit, OnDestroy {
  messageBackEnd: string;
  _subscriptionToDestroy$ = new Subject();

  constructor(
    private _dialogService: ConfirmationDialogService,
    private _router: Router,
    private _accountFullContractorService: ContractorSelectorAccountService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this._accountFullContractorService.responseFullContractor$.pipe(takeUntil(this._subscriptionToDestroy$)).subscribe(
      (response: FullContractorAccountResponse): void => {
        if (response !== null) {
          if (response.Success === true) {
            this.messageBackEnd = response.Message;
          } else {
            this._snackBar.open(response.Message, 'OK', {
              verticalPosition: 'top',
            });
            this._accountFullContractorService.loadingSendInformationSubject.next(
              false
            );
            this._accountFullContractorService.loadingSubject.next(false);
            this._router.navigate(['v2/full-contractor']);
          }
        } else {
          const responseLocal = JSON.parse(
            sessionStorage.getItem('responseFullContractor')
          );
          if(responseLocal){
            if (responseLocal.Success === true) {
              this.messageBackEnd = responseLocal.Message;
            } else {
              this._snackBar.open(responseLocal.Message, 'OK', {
                verticalPosition: 'top',
              });
              this._accountFullContractorService.loadingSendInformationSubject.next(
                false
              );
              this._accountFullContractorService.loadingSubject.next(false);
              sessionStorage.removeItem('responseFullContractor');
              this._router.navigate(['v2/full-contractor']);
            }
          }else {
            this._accountFullContractorService.loadingSendInformationSubject.next(
              false
            );
            this._accountFullContractorService.loadingSubject.next(false);
            sessionStorage.removeItem('responseFullContractor');
            this._router.navigate(['v2/full-contractor']);
          }
        }
      }
    );
  }

  cancelAccountSelection(): void {
    this._router.navigate(['v2/full-contractor']);
  }

  returnToLoginPage(): void {
    this._router.navigate(['../login']);
  }

  goToUserPortal(): void {
    window.location.href = environment.userPortal;
  }

  ngOnDestroy(): void {
    //...methods that you want to destroy
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    localStorage.removeItem('formFullContractor');
    sessionStorage.removeItem('responseFullContractor');
    this._subscriptionToDestroy$.next(true);
    this._subscriptionToDestroy$.complete();
  }
}
