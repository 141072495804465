<form class="enable-contractor-license" [formGroup]="enableFullContractorForm" (ngSubmit)="enableFullContractor()" *ngIf="!isRequestSent">
  <div class="info">
    <i class="fa fa-info-circle" aria-hidden="true"></i>
    <div>
      <h6>UPDATE USER ID</h6>
      <p>You need to update your current User ID with a professional email address. By submitting this form your User ID will change to the entered email address.</p>
    </div>
  </div>
  <div class="field-group">
    <label for="username">Contractor License</label>
    <div id="saveChanges"  class="username" >
      <button class="go-back" title="Go Back to Previous" (click)="goBack()" onKeyEnter="goBack()" [disabled]="isSendingRequest">
        <i class="fa fa-chevron-circle-left"></i>
      </button>
      <div class="username-label">{{f.contractorLicense.value}}</div>
    </div>
  </div>
  <div class="field-group" [ngClass]="{ error: f.firstName.touched && !f.firstName.valid }">
    <label for="firstName">First name</label>
    <input type="text" name="firstName" formControlName="firstName" required/>
    <div *ngFor="let validation of formValidationMessages.firstName">
      <p class="error-message" *ngIf="f.firstName.hasError(validation.type) && (f.firstName.dirty || f.firstName.touched)">{{validation.message}}</p>
    </div>
  </div>
  <div class="field-group" [ngClass]="{ error: f.lastName.touched && !f.lastName.valid }">
    <label for="lastName">Last name</label>
    <input type="text" name="lastName" formControlName="lastName" required/>
    <div *ngFor="let validation of formValidationMessages.lastName">
      <p class="error-message" *ngIf="f.lastName.hasError(validation.type) && (f.lastName.dirty || f.lastName.touched)">{{validation.message}}</p>
    </div>
  </div>
  <div class="field-group" [ngClass]="{ error: f.email.touched && !f.email.valid }">
    <label for="email">Email</label>
    <input type="text" name="email" formControlName="email" required/>
    <div *ngFor="let validation of formValidationMessages.email">
      <p class="error-message" *ngIf="f.email.hasError(validation.type) && (f.email.dirty || f.email.touched)">{{validation.message}}</p>
    </div>
  </div>
  <div class="field-group" [ngClass]="{ error: f.phoneNumber.touched && !f.phoneNumber.valid }">
    <label for="phoneNumber">Phone Number</label>
    <input type="text" name="phoneNumber" formControlName="phoneNumber" required [maxlength]="FIELD_PHONE_MAX_LENGTH"/>
    <div *ngFor="let validation of formValidationMessages.phoneNumber">
      <p class="error-message" *ngIf="f.phoneNumber.hasError(validation.type) && (f.phoneNumber.dirty || f.phoneNumber.touched)">{{validation.message}}</p>
    </div>
  </div>

  <div class="button-group">
    <button (click)="goBack()" class="cancel">Cancel</button>
    <button type="submit" id="next" class="btn btn-lg btn-info" [disabled]="!enableFullContractorForm.valid || isSendingRequest">
      Link account
        <i [ngClass]="isSendingRequest ? 'fa fa-spinner fa-spin' : 'fa fa-chevron-right'"></i>
    </button>
  </div>
</form>

<app-response-banner
  *ngIf="isRequestSent"
  [usesFortify]="usesFortify"
  [responseFullContractor]="responseFullContractor"
  (tryFormAgain)="tryFormAgain()">
</app-response-banner>
