import { DialogComponent } from '../dialog.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
export class ConfirmationDialogService {
    constructor(dialog) {
        this.dialog = dialog;
    }
    confirmationDialog(title, body, confirmButton, cancelButtton) {
        return this.dialog.open(DialogComponent, {
            width: '430px',
            height: 'auto',
            autoFocus: false,
            disableClose: true,
            panelClass: 'custom-mat-dialog-container',
            data: {
                title,
                body,
                confirmButton,
                cancelButtton
            },
        });
    }
}
ConfirmationDialogService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConfirmationDialogService_Factory() { return new ConfirmationDialogService(i0.ɵɵinject(i1.MatDialog)); }, token: ConfirmationDialogService, providedIn: "root" });
