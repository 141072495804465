import { NgZone, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CryptoHandler } from 'src/app/models/crypto-handler';
import { FortifyOAuthStorage } from './fortify-oauth-storage';
import { 
    OAuthService, 
    OAuthLogger, 
    UrlHelperService, 
    OAuthStorage, 
    ValidationHandler, 
    AuthConfig 
} from 'angular-oauth2-oidc';




@Injectable({
    providedIn: 'root'
})
export class FortifyOAuthService extends OAuthService {
    constructor(
        ngZone: NgZone,
        http: HttpClient,
        @Optional() storage: FortifyOAuthStorage,
        @Optional() tokenValidationHandler: ValidationHandler,
        @Optional() protected config: AuthConfig,
        urlHelper: UrlHelperService, logger: OAuthLogger, crypto: CryptoHandler) {
        super(ngZone, http, storage, tokenValidationHandler, config, urlHelper, logger, crypto);
    }
}
