/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./account.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tpa-info/tpa-info.component.ngfactory";
import * as i4 from "./tpa-info/tpa-info.component";
import * as i5 from "./purchase-license/purchase-license.component.ngfactory";
import * as i6 from "./purchase-license/purchase-license.component";
import * as i7 from "../../../services/monetization/monetization.service";
import * as i8 from "@angular/material/snack-bar";
import * as i9 from "./account.component";
import * as i10 from "@angular/router";
import * as i11 from "@ngrx/store";
import * as i12 from "../../../services/shared/shared.service";
import * as i13 from "../../../services/storage/storage.service";
import * as i14 from "../../../services/lcp/lcp.service";
import * as i15 from "../../../auth/fortify/fortify-oauth.service";
var styles_AccountComponent = [i0.styles];
var RenderType_AccountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccountComponent, data: {} });
export { RenderType_AccountComponent as RenderType_AccountComponent };
function View_AccountComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "go-back-no-matches"], ["title", "Go Back to Previous"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPrevious() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fa fa-chevron-circle-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" No available accounts were found "]))], null, null); }
function View_AccountComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["tabindex", "0"]], null, [[null, "click"], [null, "keyup.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectAccount(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("keyup.enter" === en)) {
        var pd_1 = (_co.selectAccount(_v.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), _v.parent.context.$implicit.Name)); _ck(_v, 2, 0, currVal_0); }); }
function View_AccountComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.RoleId; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AccountComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "filter-mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h5", [["class", "project-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "go-back"], ["title", "Go Back to Previous"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPrevious() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-chevron-circle-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Select an Account: "])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "filter-search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-search filter-search-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "input", [["class", "filter-search-input"], ["id", "mobileFilterby"], ["placeholder", "Filter Accounts"]], null, [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.applyFilterMobile($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "label", [["for", "mobileFilterby"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Search Filter"])), (_l()(), i1.ɵeld(10, 0, null, null, 2, "ul", [["class", "project-mobile-list"], ["id", "filter-list-mobile"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_5)), i1.ɵdid(12, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.accountsMerged; _ck(_v, 12, 0, currVal_0); }, null); }
function View_AccountComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["tabindex", "0"]], null, [[null, "click"], [null, "keyup.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectAccount(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("keyup.enter" === en)) {
        var pd_1 = (_co.selectAccount(_v.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), _v.parent.context.$implicit.Name)); _ck(_v, 2, 0, currVal_0); }); }
function View_AccountComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_9)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.RoleId; _ck(_v, 2, 0, currVal_0); }, null); }
function View_AccountComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-caret-up"]], null, null, null, null, null))], null, null); }
function View_AccountComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-caret-down"]], null, null, null, null, null))], null, null); }
function View_AccountComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "expanding-arrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "expand-button"], ["tabindex", "0"]], null, [[null, "click"], [null, "keyup.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleComplete() !== false);
        ad = (pd_0 && ad);
    } if (("keyup.enter" === en)) {
        var pd_1 = (_co.toggleComplete() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_11)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_12)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.expand == false); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.expand == true); _ck(_v, 5, 0, currVal_1); }, null); }
function View_AccountComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "filter-desktop"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "h5", [["class", "project-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["class", "go-back"], ["title", "Go Back to Previous"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onPrevious() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-chevron-circle-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Select an Account: "])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "filter-search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-search filter-search-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "input", [["class", "filter-search-input"], ["id", "desktopFilterby"], ["placeholder", "Filter Accounts"]], null, [[null, "keyup"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keyup" === en)) {
        var pd_0 = (_co.applyFilterDesktop($event.target.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "label", [["for", "desktopFilterby"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Search Filter"])), (_l()(), i1.ɵeld(10, 0, null, null, 5, "ul", [["id", "filter-list-desktop"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(12, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(13, { "project-list": 0, "multi-list": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_8)), i1.ɵdid(15, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_10)), i1.ɵdid(17, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 13, 0, true, (_co.expand == true)); _ck(_v, 12, 0, currVal_0); var currVal_1 = _co.accountsMerged; _ck(_v, 15, 0, currVal_1); var currVal_2 = _co.showExpandArrows; _ck(_v, 17, 0, currVal_2); }, null); }
function View_AccountComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.accountsMerged.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.accountsMerged.length; _ck(_v, 4, 0, currVal_1); }, null); }
function View_AccountComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tpa-info", [], null, null, null, i3.View_TpaInfoComponent_0, i3.RenderType_TpaInfoComponent)), i1.ɵdid(1, 114688, null, 0, i4.TpaInfoComponent, [], { notTPASatisdfiedAccounts: [0, "notTPASatisdfiedAccounts"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notTPASatisdfiedAccounts; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AccountComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-purchase-license", [], null, null, null, i5.View_PurchaseLicenseComponent_0, i5.RenderType_PurchaseLicenseComponent)), i1.ɵdid(1, 114688, null, 0, i6.PurchaseLicenseComponent, [i7.MonetizationService, i8.MatSnackBar, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_AccountComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_13)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["purchaseALicenseBanner", 2]], null, 0, null, View_AccountComponent_14))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.accountsMerged.length; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.shouldPurchaseALicense; var currVal_2 = i1.ɵnov(_v, 7); _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = _co.notTPASatisdfiedAccounts.length; _ck(_v, 6, 0, currVal_3); }, null); }
function View_AccountComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "accounts-load-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-spinner fa-pulse fa-3x"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Loading accounts"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The account list load is in progress..."]))], null, null); }
export function View_AccountComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.UpperCasePipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AccountComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["loadingAccounts", 2]], null, 0, null, View_AccountComponent_15))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasFinishedMergingAccounts; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_AccountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-account", [], null, null, null, View_AccountComponent_0, RenderType_AccountComponent)), i1.ɵdid(1, 114688, null, 0, i9.AccountComponent, [i10.ActivatedRoute, i10.Router, i11.Store, i12.SharedService, i13.StorageService, i14.LcpService, i15.FortifyOAuthService, i7.MonetizationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccountComponentNgFactory = i1.ɵccf("app-account", i9.AccountComponent, View_AccountComponent_Host_0, {}, {}, []);
export { AccountComponentNgFactory as AccountComponentNgFactory };
