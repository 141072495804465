<!-- TPA Info Banner Component Wrapper -->
<section class="tpa-info-banner-component-wrapper">

  <!-- TPA Info Banner Header Wrapper -->
  <div class="tpa-info-banner-header">

    <!-- Lock Icon -->
    <mat-icon>

      <!-- "Warning" Icon -->
      <span class="material-icons-outlined">
        lock
      </span>

    </mat-icon>

    <!-- Title -->
    <span>Security Enhancement</span>

  </div>

  <!-- Account Name Container -->
  <div class="tpa-info-banner-account-name-container">

    <!-- Account Name -->
    <span class="tpa-info-banner-account-name">{{organizationName}}</span>

  </div>

  <!-- TPA Info Banner Message Container -->
  <div class="tpa-info-banner-message-container">

    <!-- Message -->
    <p class="tpa-info-banner-message">
      To access this account you will need to link the external provider to
      your Profile by <a [href]="redirectionLink">clicking here</a> or reaching
      out to your administrator for guidance.
    </p>

  </div>


</section>
