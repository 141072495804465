import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, ViewChildren, ElementRef, QueryList, Renderer2 } from '@angular/core';

import { OrgCode } from 'src/app/models/interfaces';
import { MonetizationService } from './../../../../services/monetization/monetization.service';
import { SelectedContractor } from './../../../../models/interfaces';
import { SESSION_STORAGE } from 'src/app/models/consts';

interface requestStatus {
  requestMessage: string,
  isSendingRequest: boolean,
  isRequestSent: boolean
}
@Component({
  selector: 'app-purchase-license',
  templateUrl: './purchase-license.component.html',
  styleUrls: ['./purchase-license.component.scss']
})
export class PurchaseLicenseComponent implements OnInit {

  @ViewChildren('purchaseALicenseButton', { read: ElementRef }) purchaseALicenseButtonRef: QueryList<ElementRef>

  requestStatus: requestStatus = {
    requestMessage: 'Purchase a license',
    isSendingRequest: false,
    isRequestSent: false,
  }
  contractorAccounts: OrgCode[] = [];

  constructor(
    private monetizationService: MonetizationService,
    private snackBar: MatSnackBar,
    private renderer: Renderer2
  ) {
    this.getContractorAccounts();
  }

  ngOnInit() {
  }

  /**
   * Gets the contractor accounts
   */
  getContractorAccounts(): void {
    console.log(`%c  Contractors Accounts in accounts `, "background-color: black; color: white; padding: 4px; border-radius: 7px, display: inline-block;");
    this.contractorAccounts = this.monetizationService.getContractorAccounts
  }

  /**
   * Calls the service to star the contractors pre configuration and the payment process
   */
  purchaseLicense(contractorId: string, orgName: string, buttonIndex: number): void {
    this.requestStatus.isSendingRequest = true;

    let selectedContractorId: SelectedContractor = { contractorId };
    localStorage.setItem(SESSION_STORAGE.SELECTED_CONTRACTOR_ID, JSON.stringify(selectedContractorId));

    this.handleButtonStyle(buttonIndex);

    this.monetizationService.prePaymentSetupCreateIds(contractorId, orgName).subscribe(res => {
      const sfId: string = res.sfId;
      this.monetizationService.redirectToChargify(sfId, orgName);
    }, error => {
      this.resetButtonStyle(buttonIndex);
      this.resetRequestStatus();

      const message = (error && error.error && error.error.message) ? error.error.message : 'Something went wrong when generating payment URL';

      this.snackBar.open(message, 'CLOSE', {
        duration: 10000,
        verticalPosition: "top"
      })

      console.error(error);
    })
  }

  /**
   * Sets the initial values of the request status
   */
  resetRequestStatus(): void {
    this.requestStatus.requestMessage = 'Purchase a license',
    this.requestStatus.isSendingRequest = false;
    this.requestStatus.isRequestSent = false;
  }

  /**
   * Manages dynamically the button style
   * @param buttonIndex
   */
  handleButtonStyle(buttonIndex: number): void {
    let purchaseLicenseButton = this.purchaseALicenseButtonRef.toArray()[buttonIndex].nativeElement;

    const icon = this.renderer.createElement('i');
    const span = this.renderer.createElement('span');

    purchaseLicenseButton.innerText = '';
    span.innerText = ' Generating payment URL';

    this.renderer.addClass(icon,"fa");
    this.renderer.addClass(icon,"fa-spinner");
    this.renderer.addClass(icon,"fa-spin");

    this.renderer.addClass(purchaseLicenseButton, 'generating-URL')
    this.renderer.appendChild(purchaseLicenseButton, span)
    this.renderer.appendChild(purchaseLicenseButton, icon)
  }

  /**
   * Cleans up the button style
   * @param buttonIndex
   */
  resetButtonStyle(buttonIndex: number): void {
    let purchaseLicenseButton = this.purchaseALicenseButtonRef.toArray()[buttonIndex].nativeElement;
    purchaseLicenseButton.innerText = 'Purchase a license';

    this.renderer.removeClass(purchaseLicenseButton, 'generating-URL')
  }
}
