import { authNet6ConfigImplicit } from '../auth.net6.config.implicit';
import * as i0 from "@angular/core";
import * as i1 from "angular-oauth2-oidc";
export class AuthNet6ImplicitService {
    constructor(oauthService) {
        this.oauthService = oauthService;
    }
    login() {
        this.oauthService.configure(authNet6ConfigImplicit);
        this.oauthService.loadDiscoveryDocument().then(res => {
            this.oauthService.initImplicitFlow();
        });
    }
}
AuthNet6ImplicitService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthNet6ImplicitService_Factory() { return new AuthNet6ImplicitService(i0.ɵɵinject(i1.OAuthService)); }, token: AuthNet6ImplicitService, providedIn: "root" });
