import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorService } from 'src/app/services/error/error.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { environment } from 'src/app/../environments/environment';
import { AppState, ValidatePasswordResponseData } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';
//
// ngrx / rxjs
//
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';


@Injectable()
export class PasswordEffects {
    constructor(
        private actions$: Actions,
        private error: ErrorService,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private store: Store<AppState>,
        private storage : StorageService,
        private router: Router
    ) { }

    validatePassword$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.validatePasswordStart),
            tap(() => this.store.dispatch(AllActions.setBusyMessage({ message: 'Checking Password' }))),
            switchMap(action =>
                this.http.post<ValidatePasswordResponseData>(`${environment.webAppUrl}/api/login`, {
                    Username: action.username,
                    Password: action.password,
                }, {
                    withCredentials: true,
                }).pipe(
                    take(1),
                    map(response => {
                        if (response.Success) {
                            this.storage.remove('auth-route');
                            this.storage.setItem('auth-route', 'non-tpa')

                            return AllActions.validatePasswordSuccess({
                                username: action.username,
                                isSupportUser: response.IsSupportUser,
                                enableFullContractor: response.EnableFullContractor,
                                isEmailContractorUser:response.IsEmailContractorUser
                            });
                        } else {
                            return AllActions.validatePasswordFail({ errorMessage: response.Message });
                        }
                    }),
                    catchError(error => {
                        return of(AllActions.validatePasswordFail({ errorMessage: error.message }));
                    })
                )
            )
        )
    );

    validatePasswordSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.validatePasswordSuccess),
            tap(action => {
                this.store.dispatch(AllActions.setBusyMessage({ message: '' }));
                console.log( 'AllActions.validatePasswordSuccess : ' + JSON.stringify(action) )
                //
                // Look for Claim on Fortify to also make that suggestion.
                //
                if (action.isSupportUser) {
                    this.router.navigate(['/login', 'impersonate']);
                } else {
                    this.router.navigate(['/login', 'account']);
                }
            })
        ), { dispatch: false }
    );

    validatePasswordFail$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.validatePasswordFail),
            tap(action => {
                console.log('validatePasswordFail$ :', action);
                const err = action.errorMessage;
                this.snackBar
                .open(err, 'OK', { duration: 10000, verticalPosition: "top"})
                .onAction()
                .subscribe(() => {
                    // Keep this here for when you want to "undo" a functionality.
                });
                this.store.dispatch(AllActions.setBusyMessage({ message: '' }));
                this.router.navigate(['/login']);
            }),
        ), { dispatch: false }
    );
}
