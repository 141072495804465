import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared/shared.service';
import { Account } from 'src/app/models/account.model';
import { AppState, GetAccountsData } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';
//
// ngrx / rxjs
//
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { ContentfulService } from 'src/app/services/contentful/contentful.service';

@Component({
  selector: 'fortify-accounts',
  templateUrl: './fortify-accounts.component.html',
  styleUrls: ['./fortify-accounts.component.scss']
})
export class FortifyAccountsComponent implements OnInit {
  errorClose : boolean = false;
  showProgressBar : boolean = false;
  img : string;
  imgId : string;
  imgLabel: string;
  imgLink : string;
  accounts : any;
  claimOrgs : any = [];
  usrnm : string;
  state : any;
  imageURLAsset: string;

  constructor(
    private contentful : ContentfulService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<AppState>,
    private shared: SharedService
  ) {
   }

  ngOnInit() {
    this.contentful.getAd().subscribe(data => {
      if ( data != (null || undefined)) {
        this.img = data.items[0].fields.image;
        this.imgId = data.items[0].fields.id;
        this.imgLabel = data.items[0].fields.label;
        this.imgLink = data.items[0].fields.link;
        this.imageURLAsset = data.items[0].fields.asset.fields.file.url;
      }
    })
    this.shared.getError().subscribe(data => {
      if ( data != (null || undefined)) {
        this.errorClose = data;
      }
    })
    this.shared.getProgressBar().subscribe(data => {
      if ( data != (null || undefined)) {
        this.showProgressBar = data;
      }
    })

    //
    // Check for tpa_org_codes and substring out into array.
    //
    let auth = this.getState('auth');
    let fortifyAuth = this.getState('fortifyAuth');
    let lcptrackerAuth = this.getState('lcptrackerAuth');

    console.log('--------------------------------------')
    console.log('%c -----------  AUTH DATA  --------------', 'color: black, background-color: yellow;')
    console.log('--------------------------------------')
    console.log('%c AUTH : ', 'color: pink; font-weight: bold; background-color: black;');
    console.log(JSON.stringify(auth));
    console.log('%c FORTIFY auth : ', 'color: #8DB9E3; font-weight: bold; background-color: black;');
    console.log(JSON.stringify(fortifyAuth));
    console.log('%c lcptracker AUTH : ', 'color: #dc3545; font-weight: bold; background-color: black;');
    console.log(JSON.stringify(lcptrackerAuth));

    //
    // START grabbing from the store
    //
    this.store.select('fortifyAuth')
      .pipe(
        filter(state => !!state.idToken),
        take(1)
      )
      .subscribe(state => {
        console.log('auth in FortifyAccountsComponent', state);
        this.showProgressBar = false;
        this.state = state;
        this.usrnm = state.username;
        this.accounts = state.claims['org_codes'];
        console.log(this.accounts);
        //
        // BACK TO FUNKY TOWN with a NON-standard dictionary instead of an object.
        //
        for ( let acc of this.accounts) {
          let a = JSON.parse(acc);
          let x = {
            orgCode: a['orgCode'],
            orgId : a['orgId'],
          }
          this.claimOrgs.push(x);
        }
        console.log(this.claimOrgs);
        this.accounts = this.claimOrgs;

        //
        // Actions to observe workflow
        //
        //this.store.dispatch(AllActions.loginStartFortify({ username: state.username}))
        // this.store.dispatch(AllActions.setIdToken({ idToken: state.idToken }));
        // this.store.dispatch(AllActions.setUsername({ username: state.username}));
        // this.store.dispatch(AllActions.setUsedMFA({ usedMFA: state.usedMFA}));
        // this.store.dispatch(AllActions.setClaims({ claims: state.claims}));


        //
        //  FortifyAuth Store? Not exactly....
        //  TODO :: populate the fortifyAuth store and start which one should log in or not.
        // this.store.dispatch(AllActions.setIdToken({ idToken: state.idToken }));
        // this.store.dispatch(AllActions.validateTokenStart({ token: state.idToken }));
      });


  }


  // ---------------------------------------------------------------------------
  // -----------------------   UX Experience   ---------------------------------
  // ---------------------------------------------------------------------------
  logoutFortify() {
    this.store.dispatch(AllActions.logoutFortify());
  }
  onPrevious() {
    this.router.navigateByUrl('login/fortify-direct');
  }
  selectProject(event, data) {
    console.log(event, data);
    this.store.dispatch(AllActions.validateTokenStart({ token : this.state.idToken }));
    // this.store.dispatch(AllActions.authLcptracker({ redirect : false }));
    // this.store.dispatch(AllActions.loginStartLcptracker({ username: this.usrnm }));
    // this.store.dispatch(AllActions.setOrgCodeStart({ orgCode: data.orgCode }));


    //
        // Actions to observe workflow
        //
        // this.store.dispatch(AllActions.loginStartFortify({ username: state.username}))
        // this.store.dispatch(AllActions.setIdToken({ idToken: state.idToken }));
        // this.store.dispatch(AllActions.setUsername({ username: state.username}));
        // this.store.dispatch(AllActions.setUsedMFA({ usedMFA: state.usedMFA}));
        // this.store.dispatch(AllActions.setClaims({ claims: state.claims}));;

  }
  getState(type) {
    let s;
    this.store.select(type)
    .pipe(
      filter(state => !!state.idToken),
      take(1)
    ).subscribe(state => {
      s = state;
    });
    return s;
  }


  //
  // https://www.w3schools.com/howto/howto_js_filter_lists.asp
  //
  applyFilter( value ) {
    // Declare variables
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById('filterby');
    filter = input.value.toUpperCase();
    ul = document.getElementById("filter-list");
    li = ul.getElementsByTagName('li');

    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }





  // accounts = [
  //   {"OrgCode":"cert_testfederal","Name":"(A) TEST DATABASE - FEDERAL"},
  //   {"OrgCode":"clarkconstruction5",
  //   "Name":"113263 - CAPITAL RAIL CONSTRUCTORS - DULLES METRORAIL"},
  //   {"OrgCode":"clarkconstruction50","Name":"113828 - CLARK - LAX RS-X"},
  //   {"OrgCode":"ccmilcp37","Name":"ALAMEDA COUNTY"},
  //   {"OrgCode":"alliantconsulting","Name":"ALLIANT CONSULTING"},
  //   {"OrgCode":"amwater","Name":"AMERICAN WATER ENTERPRISES"},
  //   {"OrgCode":"arizona_dot_prod","Name":"ARIZONA DOT"},
  //   {"OrgCode":"lcptracker31","Name":"B2GNOW SANDBOX"},
  //   {"OrgCode":"balfourbeattyus","Name":"BALFOUR BEATTY CONSTRUCTION"},
  //   {"OrgCode":"dotca1","Name":"CALTRANS"},
  //   {"OrgCode":"sfgov","Name":"CITY & COUNTY OF SAN FRANCISCO"},
  //   {"OrgCode":"qaonsitedenver","Name":"CITY OF DENVER - ONSITE BETA"},
  //   {"OrgCode":"miamibeachfl","Name":"CITY OF MIAMI BEACH"},
  //   {"OrgCode":"qaonsitemiamibeachfl","Name":"CITY OF MIAMI BEACH - ONSITE BETA"},
  //   {"OrgCode":"milwaukee1","Name":"CITY OF MILWAUKEE"},
  //   {"OrgCode":"cityofminneapolis","Name":"CITY OF MINNEAPOLIS"},
  //   {"OrgCode":"minneapolismn","Name":"CITY OF MINNEAPOLIS_B2G"},
  //   {"OrgCode":"seattle","Name":"CITY OF SEATTLE"},
  //   {"OrgCode":"demo_fed_christina","Name":"CN - LCPTRACKER FEDERAL"},
  //   {"OrgCode":"state","Name":"COLORADO DOT"},
  //   {"OrgCode":"erie","Name":"COUNTY OF ERIE"},
  //   {"OrgCode":"cnageltestlcp","Name":"DOUGLAS CONSTRUCTION"},
  //   {"OrgCode":"hazardconstruction","Name":"HAZARD CONSTRUCTION"},
  //   {"OrgCode":"aecom","Name":"HUNT/GIBBS/BOH/METRO JV"},
  //   {"OrgCode":"villagegid","Name":"INCLINE VILLAGE GID"},
  //   {"OrgCode":"kgwalters","Name":"KG WALTERS"},
  //   {"OrgCode":"kiewit12","Name":"KIEWIT - CENTRAL"},
  //   {"OrgCode":"kiewit9","Name":"KIEWIT - EASTERN DISTRICT"},
  //   {"OrgCode":"kiewit13","Name":"KIEWIT - PCCP CONSTRUCTORS, A JV"},
  //   {"OrgCode":"kiewit3","Name":"KIEWIT - SOUTHEAST & TIC HM&C"},
  //   {"OrgCode":"kingcounty1","Name":"KING COUNTY"},
  //   {"OrgCode":"koneinc","Name":"KONE CERTIFIED"},
  //   {"OrgCode":"certified","Name":"LCPCERTIFIED"},
  //   {"OrgCode":"lcptracker1615","Name":"LCPTRACKER MP-CIKUME TEST ACCOUNT"},
  //   {"OrgCode":"demo_fed_trial","Name":"LCPTRACKER SALES TEAM DATABASE"},
  //   {"OrgCode":"lendlease","Name":"LEND LEASE AMERICAS INC."},
  //   {"OrgCode":"losangelescounty","Name":"LOS ANGELES COUNTY"},
  //   {"OrgCode":"m1b","Name":"MARKET ONE BUILDERS, INC."},
  //   {"OrgCode":"mdstad","Name":"MARYLAND STADIUM AUTHORITY- B2GNOW"},
  //   {"OrgCode":"massport","Name":"MASSACHUSETTS PORT AUTHORITY"},
  //   {"OrgCode":"gmail","Name":"MDG DESIGN & CONSTRUCTION LLC"},
  //   {"OrgCode":"miamidade","Name":"MIAMI DADE COUNTY"},
  //   {"OrgCode":"tcco13","Name":"MIDFIELD SATELLITE CONCOURSE NORTH – TURNER-PCL JV"},
  //   {"OrgCode":"amwater1","Name":"MISSOURI AMERICAN WATER COMPANY"},
  //   {"OrgCode":"dsenergysolutions","Name":"Motive Energy Telecommunications"},
  //   {"OrgCode":"nd","Name":"NORTH DAKOTA DOT - B2GNOW"},
  //   {"OrgCode":"lcptracker3000","Name":"PAYCHEX TESTING"},
  //   {"OrgCode":"pgnconsulting","Name":"PGN CONSULTING"},
  //   {"OrgCode":"pima","Name":"PIMA COUNTY COMMUNITY WORKFORCE & DEVELOPMENT DEPARTMENT"},
  //   {"OrgCode":"pppl","Name":"PRINCETON PLASMA PHYSICS LABORATORY"},
  //   {"OrgCode":"qacikumedailyreporter","Name":"QA CIKUME DAILY REPORTER"},
  //   {"OrgCode":"qacvenz","Name":"QA CVenz"},
  //   {"OrgCode":"qafederal","Name":"QA FEDERAL"},
  //   {"OrgCode":"qafedautotest","Name":"QA FEDERAL AUTOMATED TEST ACCOUNT"},
  //   {"OrgCode":"qajesus","Name":"QA JESUS"},
  //   {"OrgCode":"qalcpv2","Name":"QA LCP V2"},
  //   {"OrgCode":"qalcpcertifiedpro","Name":"QA LCPCERTIFIED PRO"},
  //   {"OrgCode":"qaadhoctestingsofiagonzalez","Name":"QA TEST CELVIN RIVAS"},
  //   {"OrgCode":"qatrainingdb","Name":"QA TRAINING"},
  //   {"OrgCode":"qaadhoctestingcarson","Name":"QAADHOCTESTINGCARSON"},
  //   {"OrgCode":"qaadhoctestingdavidchandroo","Name":"QAADHOCTESTINGDAVIDCHANDROO"},
  //   {"OrgCode":"qaadhoctestingwilsonlin","Name":"QAADHOCTESTINGWILSONLIN"},
  //   {"OrgCode":"qaaparna","Name":"QAAPARNA"},
  //   {"OrgCode":"qaautomationforadmin","Name":"QAAUTOMATIONFORADMINTESTING"},
  //   {"OrgCode":"qaautomationsmartclientapi","Name":"QAAUTOMATIONSMARTCLIENTAPI"},
  //   {"OrgCode":"qaautomationsmartclientapi2","Name":"QAAUTOMATIONSMARTCLIENTAPI2"},
  //   {"OrgCode":"qaautomationtesting","Name":"QAAUTOMATIONTESTING"},
  //   {"OrgCode":"qakevinibanez","Name":"QAKEVINIBANEZ"},
  //   {"OrgCode":"rqconstruction","Name":"RQ CONSTRUCTION, LLC"},
  //   {"OrgCode":"demo_fed_RayanNeil","Name":"RYAN AND NEIL DEMO"},
  //   {"OrgCode":"soundtransit","Name":"SOUND TRANSIT"},
  //   {"OrgCode":"swinertoninc","Name":"SWINERTON BUILDERS"},
  //   {"OrgCode":"txdot","Name":"TXDOT - TEXAS DEPARTMENT OF TRANSPORTATION"},
  //   {"OrgCode":"uci","Name":"UNIVERSITY OF CALIFORNIA IRVINE"},
  //   {"OrgCode":"lcptracker101","Name":"ZARRICK DOLL INSPECTIONS, LLC"},
  //   {"OrgCode":"lcptracker6","Name":"ZIPPY QA FEDERAL - ALL APPLICATIONS"},
  //   {"OrgCode":"lcptracker2049","Name":"ZZZZ.FR-2049 ONLY"},
  //   {"OrgCode":"lcptracker1207","Name":"ZZZZ.STACEY EXTENDED WAGE - PLEASE NO ONE ELSE USE!"}
  // ];

}
