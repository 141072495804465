import { BehaviorSubject } from 'rxjs';
import { CustomSnackBarComponent } from 'src/app/components/shared/custom-snack-bar/custom-snack-bar.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
export class SharedService {
    constructor(snackBar) {
        this.snackBar = snackBar;
        //
        // ERROR Screen and Clear User Name.
        //
        this.error$ = new BehaviorSubject(null);
        this.clearUserName$ = new BehaviorSubject(null);
        //
        // Progress Bar
        //
        this.ProgressBar$ = new BehaviorSubject(null);
        //
        // Projects List Layout
        //
        this.layoutProject$ = new BehaviorSubject(null);
        //
        // Fortify Hybrid Link
        //
        this.linkFortifyLogout$ = new BehaviorSubject(null);
    }
    openSnackBar(message, action) {
        this.snackBar
            .open(message, action, {
            duration: 10000,
            verticalPosition: "top"
        })
            .onAction()
            .subscribe(() => {
            // Keep this here for when you want to "undo" a functionality.
        });
    }
    openCustomSnackBar(message) {
        this.snackBar.openFromComponent(CustomSnackBarComponent, {
            data: { html: message },
            duration: 0,
            verticalPosition: "top",
            panelClass: ['flex', 'flex-row']
        });
    }
    refreshScreen() {
        location.reload();
    }
    getError() {
        return this.error$.asObservable();
    }
    setError(value) {
        this.error$.next(value);
    }
    getUserName() {
        return this.clearUserName$.asObservable();
    }
    setUserName(value) {
        this.clearUserName$.next(value);
    }
    getProgressBar() {
        return this.ProgressBar$.asObservable();
    }
    setProgressBar(value) {
        this.ProgressBar$.next(value);
    }
    getlayoutProject() {
        return this.layoutProject$.asObservable();
    }
    setlayoutProject(value) {
        this.layoutProject$.next(value);
    }
    getLinkFortifyLogout() {
        return this.linkFortifyLogout$.asObservable();
    }
    setLinkFortifyLogout(value) {
        this.linkFortifyLogout$.next(value);
    }
    //
    // Microsoft Clarity
    //
    clarityTracking() {
        (function (c, l, a, r, i, t, y) {
            c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments); };
            t = l.createElement(r);
            t.async = 1;
            t.src = "https://www.clarity.ms/tag/" + i;
            t.onerror = function () { console.error('Failed to Load Microsoft Clarity'); };
            y = l.getElementsByTagName(r)[0];
            y.parentNode.insertBefore(t, y);
        })(window, document, "clarity", "script", "npqosztbs2");
    }
}
SharedService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SharedService_Factory() { return new SharedService(i0.ɵɵinject(i1.MatSnackBar)); }, token: SharedService, providedIn: "root" });
