<error-wiz *ngIf="errorClose == true"></error-wiz>
<app-busy></app-busy>
<app-alert></app-alert>
<main>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div [ngClass]="{'login-duel': projectLayout == false, 'login-project' : projectLayout == true }">
                    <mat-progression-bar [show]="showProgressBar"></mat-progression-bar>
                    <div class="fortify-logout"
                        *ngIf="showFortifyLink == true">
                        <a tabindex="0" class="fortify-link"
                            (click)="logoutFortify()"
                            (keyup.enter)="logoutFortify()"
                            >
                            <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
                            Switch Fortify User / Logout
                        </a>
                    </div>
                    <div class="row margin-0">
                        <div [ngClass]="{'col-12 col-md-6': true }">
                                <div class="company-logo">
                                    <img src="./assets/logo.png" alt="Sign In to Lcptracker" />
                                </div>
                                <div class="instructions" [ngClass]="{'instructions': true}">
                                    <p class="h6">For Electronic Submission and Management of Certified Payroll Reports</p>
                                </div>
                            <router-outlet></router-outlet>
                        </div>
                        <div [ngClass]="{'col-12 col-md-6 d-flex justify-content-center flex-column': true }">
                            <a #marketingAd [ngClass]="{'marketing-ad': true, 'sticky-top': projectLayout == true }"class="marketing-ad sticky-top" tabindex="0" [href]="imgLink" target="blank">
                              <img [id]="imgId" [src]="imageURLAsset" [attr.alt]="imgLabel" />
                            </a>

                            <a class="lcp-certified" [href]="lcpCertifiedUrl">
                              <img src="./assets/icon_LCPcertified.svg" alt="">
                              Looking for LCPcertified? <span>Login Here</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
<footer-links></footer-links>
<top-page></top-page>
