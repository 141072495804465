import { createReducer, on, Action } from '@ngrx/store';
import { stateBusy } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';

const initialState: stateBusy = {
    message: '',
};

const busyReducer = createReducer(
    initialState,
    on(AllActions.setBusyMessage, (state, action) => ({ ...state, message: action.message })),
);

export function reducer(state: stateBusy | undefined, action: Action) {
    return busyReducer(state, action);
}
