<div class="modal-wrapper">

  <main class="modal-content">

    <!-- Modal Title -->
    <h5 [innerHTML]="title | safeHtml"></h5>

    <!-- Modal Content -->
    <div mat-dialog-content [innerHTML]="htmlContent | safeHtml"></div>

    <!-- Buttons Container -->
    <div *ngIf="cancelButtton || confirmButton" mat-dialog-actions class="buttons-row">

      <!-- Cancel Button -->
      <app-button
        buttonType="flat"
        label="Cancel"
        (buttonClicked)="onClose()"
      ></app-button>

      <!-- Confirm Button -->
      <app-button
        buttonType="primary"
        label="Confirm"
        (buttonClicked)="onConfirm()"
      ></app-button>

    </div>

  </main>

</div>
