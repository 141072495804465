import { createReducer, Action, on } from '@ngrx/store';
import { stateFortifyAuth } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';
import * as fromAuthConfig from 'src/app/auth/fortify/auth.config';

//
// This is for 'fortify-redirect'
//
const initialState: stateFortifyAuth = {
    authConfig: fromAuthConfig.authConfig,
    idToken: null,
    username: null,
    claims: null,
    usedMFA: false,
    orgCodes : null,
    tpaOrgCodes: null
};

const authReducer = createReducer(
    initialState,
    on(AllActions.loginSuccessFortify, (state, action) =>
        ({ ...state, idToken: action.idToken, claims: action.claims, username: action.username, usedMFA: action.usedMFA, orgCodes: action.orgCodes, tpaOrgCodes: action.tpaOrgCodes })),
);

export function reducer(state: stateFortifyAuth | undefined, action: Action) {
    return authReducer(state, action);
}

//
// This is for 'fortify-direct'
// TODO:: Create New (!.loginSuccess) Action & Have Reducer Listen for Changes.

// const directState: State = {
//     authConfig: fromAuthConfig.authConfigDirect,
//     idToken: null,
//     username: null,
//     claims: null,
//     usedMFA: false,
// };

// const authDirectReducer = createReducer(
//     directState,
//     on(AuthActions.loginSuccess, (state, action) =>
//         ({ ...state, idToken: action.idToken, claims: action.claims, username: action.username, usedMFA: action.usedMFA })),
// );

// export function reducerDirect(state: State | undefined, action: Action) {
//     return authDirectReducer(state, action);
// }







