import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {
  FullContractorAccount,
  FullContractorAccountResponse,
  FullContractorUser,
  FullContractorUserWithAccounts,
  GetAccountsFullContractorResponseData,
} from "../interfaces/database-interface";

@Injectable({
  providedIn: "root",
})
export class ContractorSelectorAccountService {
  dataCacheStream: BehaviorSubject<GetAccountsFullContractorResponseData[]> =
    new BehaviorSubject([]);
  dataCacheStream$ = this.dataCacheStream.asObservable();
  loadingSubject = new BehaviorSubject<boolean>(true);
  loadingSubject$ = this.loadingSubject.asObservable();
  loadingSendInformationSubject = new BehaviorSubject<boolean>(true);
  loadingSendInformationSubject$ =
    this.loadingSendInformationSubject.asObservable();

  userFullContractorFormStream = new BehaviorSubject<FullContractorUser>(null);
  userNameStream = new BehaviorSubject<string>("");
  responseFullContractor = new BehaviorSubject<FullContractorAccountResponse>(null);
  responseFullContractor$ = this.responseFullContractor.asObservable();

  /**
   * Creates an instance of contractor selector account service.
   * @param _http
   */
  constructor(private _http: HttpClient) {}

  getData(): Observable<GetAccountsFullContractorResponseData[]> {

    this.loadingSubject.next(true);

    return this._http
      .get<GetAccountsFullContractorResponseData[]>(
        `${environment.webAppUrl}/api/accounts`,
        {
          withCredentials: true,
        }
      )
      .pipe(
        tap((items): void => {
          console.log("Fetch todo");
          console.log(items);
          this.userNameStream.next(items["Username"]);
          this.dataCacheStream.next(items);
          this.loadingSubject.next(true);
        }),
        // catchError(this.handleError("getData", []))
      );
  }

  /**
   * Makes a request to LCP to link a professional email with accounts
   * @param fullContractor
   * @param accounts
   * @returns
   */
  sendInformationAndAccountsFromFullContractorSelector(
    fullContractorForm: FullContractorUser,
    accounts: FullContractorAccount[]
  ): Observable<FullContractorAccountResponse> {
    console.table(fullContractorForm);
    console.table(accounts);
   let fullContractor : any = {
    user : fullContractorForm,
    accounts : accounts
   }
    return this._http.post<FullContractorAccountResponse>(
      `${environment.webAppUrl}/api/fullContractorUser`,
      fullContractor,

      { withCredentials: true }
    ).pipe(
      tap((): void => {
        this.loadingSendInformationSubject.next(false);
      })
    );
  }

  /**
   * Makes a request to LCP to link a professional email
   * @param fullContractor
   * @returns
   */
  sendInformationFullContractor(
    fullContractor: FullContractorUserWithAccounts
  ): Observable<FullContractorAccountResponse> {
    console.table(fullContractor);
    this.loadingSendInformationSubject.next(true);
    return this._http.post<FullContractorAccountResponse>(
      `${environment.webAppUrl}/api/fullContractorUser`,
      fullContractor,
      { withCredentials: true }
    ).pipe(
      tap((): void => {
        this.loadingSendInformationSubject.next(false);
      })
    );
  }

  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
