<div class="sso-page-container">
  <div class="sso-page-card sm:mx-2.28">
      <img src="assets/logo.png" alt="LCPtracker Logo">
      <div class="progress-spinner-container">
          <mat-progress-spinner class="example-margin" color="primary" mode="indeterminate" diameter="50">
          </mat-progress-spinner>
      </div>
      <h3>You've logged in</h3>
      <p>You will be redirected in a moment...</p>
  </div>
  <footer></footer>
</div>
