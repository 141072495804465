/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-wiz.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./error-wiz.component";
import * as i3 from "../../../services/shared/shared.service";
var styles_ErrorWizComponent = [i0.styles];
var RenderType_ErrorWizComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorWizComponent, data: {} });
export { RenderType_ErrorWizComponent as RenderType_ErrorWizComponent };
export function View_ErrorWizComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "a", [["class", "background show"], ["id", "errorBackground"], ["tabindex", "0"]], null, [[null, "click"], [null, "keyup.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeError() !== false);
        ad = (pd_0 && ad);
    } if (("keyup.enter" === en)) {
        var pd_1 = (_co.closeError() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "contain-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "symbol"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-exclamation-triangle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "message-contain"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "message-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There was an Error"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "message-desc"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You cannot use an employee email as a username"]))], null, null); }
export function View_ErrorWizComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "error-wiz", [], null, null, null, View_ErrorWizComponent_0, RenderType_ErrorWizComponent)), i1.ɵdid(1, 245760, null, 0, i2.ErrorWizComponent, [i3.SharedService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ErrorWizComponentNgFactory = i1.ɵccf("error-wiz", i2.ErrorWizComponent, View_ErrorWizComponent_Host_0, {}, {}, []);
export { ErrorWizComponentNgFactory as ErrorWizComponentNgFactory };
