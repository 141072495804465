import { Component, ElementRef, EventEmitter, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/services/shared/shared.service';

@Component({
  selector: 'error-wiz',
  templateUrl: './error-wiz.component.html',
  styleUrls: ['./error-wiz.component.scss']
})
export class ErrorWizComponent implements OnInit {
  interval : any;
  constructor(
    private shared : SharedService
  ) { }
  ngOnDestroy() {
    clearInterval(this.interval)
  }
  ngOnInit() {
    this.startCountdown(5);
  }
  // ---------------------------------------------------------------------------
  // -----------------------   UX Experience   ---------------------------------
  // ---------------------------------------------------------------------------
  closeError() : void {
    this.shared.setUserName(true);
    document.getElementById("username").focus();
    this.shared.setError(false);
  } 
  startCountdown(seconds) : void {
    let counter = seconds;
    this.interval = setInterval(() => {
      counter--;
      if (counter < 0 ) {
        clearInterval(this.interval);
        this.closeError();
      }
    }, 1000);
  }
  altRoute() {
    // example of production for staff admin.
    // https://prod.lcptracker.net/Accounts/SupportLogin/Index
    window.open(environment.webAppUrl + '/Accounts/SupportLogin/Index', '_self');
  }



}
