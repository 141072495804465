import { LcpService } from 'src/app/services/lcp/lcp.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AuthNet6ImplicitService } from '../../services/auth-net-6-implicit.service';
import { Net6AccountsService } from '../../services/net6-accounts.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sso-net6-accounts',
  templateUrl: './sso-net6-accounts.component.html',
  styleUrls: ['./sso-net6-accounts.component.scss']
})
export class SsoNet6AccountsComponent implements OnInit {
  loading: boolean = true;
  orgList : Array<any> = [];
  orgCodesFromFortify: any;
  loggedInUser: string;
  email: string;
  name: string;
  preferred_username: string;
  accounts_username: string;
  accounts_supporUsername: string;

  constructor(
    private net6AccountsService: Net6AccountsService,
    private authNet6ImplicitService: AuthNet6ImplicitService,
    private oAuthService: OAuthService,
    private lcpService: LcpService
  ) { }

  ngOnInit() {
    document.body.style.paddingTop = "0px"; // unit measured in Edge.
    //
    // Make API Calls.
    //
    this.validateUsername();
    // this.login();
    this.getExtendedClaims();
    //
    // Display User Identity.
    //
    const userInfo = this.oAuthService.getIdentityClaims();
    console.log('getIdentityClaims : ' + JSON.stringify(userInfo));
    this.email = userInfo['email'];
    this.name = userInfo['name'];
    this.preferred_username = userInfo['preferred_username'];

    //
    // testing to see accounts object
    // this.prepopulateFakeAccounts();
  }

  validateUsername(): void {
    const token = this.oAuthService.getIdentityClaims()
    token['email']
    console.log('Email:', token['email']);
    this.lcpService.validateUserName(token['email'])
    .subscribe(res => {
      console.warn('--------------------- api/user ---------------------');
      console.log(res);
      console.log('----------------------------------------------------');

      console.log('-> Continue with api/login');
      this.login()

    })
  }

  getAccounts(): void {
    this.lcpService.getAccounts()
    .subscribe(res => {
    //
    // Username: string;
    // SupportUsername: string;
    // Accounts: Account[];
    //
      console.log('getAccounts() : ' + JSON.stringify(res));
      this.accounts_username = res.Username;
      this.accounts_supporUsername = res.SupportUsername;
      this.orgList = res.Accounts;
      this.loading = false;
    })
  }

  login():void {
    let token = this.oAuthService.getAccessToken();
    console.log('token : ' + token);
    this.lcpService.validateToken(token)
    .subscribe(res => {
      console.warn('--------------------- api/login ---------------------');

      console.log(res)

      this.getAccounts();

      console.log('----------------------------------------------------');
    })
  }

  getExtendedClaims() {
    this.net6AccountsService.getRBSExtendedNET6Claims()
    .subscribe(res => {
      console.log(res)
      this.orgCodesFromFortify = res;
    })
  }

  getRBSExtendedClaimsAndAccounts() {
    //
    // TODO: Use this forkjoin refactor when discussing claims / actions.
    //
    const RBSExtendedClaims$ = this.net6AccountsService.getRBSExtendedNET6Claims();
    const trackerAccounts$ = this.net6AccountsService.getAccounts();

    forkJoin([RBSExtendedClaims$, trackerAccounts$]).subscribe(res => {
      console.log(res);
    })
  }

  /**
   * Merge the RBSExtendedClaims list and TrackerAccounts
   * Consider TPA rules and logic in existing component
   * This list is the one that has to be displayed in the account picker and sent back to Tracker
   */
  filterAccounts(listOfRBSExtendedClaimsAccounts, listOfTrackerAccounts) {
    // return mergedListOfAccounts
  }

  setOrgCode(org:any) {
    this.lcpService.setOrgCode(org.OrgCode, this.orgList, org.RoleId)
    .subscribe(res => {
      console.log('setOrgCode() : ' + JSON.stringify(res));
      if ( res.AccountType == 'Admin' ) { // Admin
        console.log('------ Logging as Administrator --------');
        window.location.href = `${environment.webAppUrl}/Projects/UserAgreement`;
      }
      if ( res.AccountType == 'Contractor' ) { // Contractor
        console.log('------ Logging as Contractor --------');
        window.location.href = `${environment.webAppUrl}/Projects/UserAgreement`;
      }
    })

  }

  prepopulateFakeAccounts() {
    this.orgList = [
      {"OrgCode":"49ers_tonic_output","Name":"TONIC LEVI'S STADIUM","FortifyEnabled":false},
      {"OrgCode":"aecom2","Name":"HUNT/CLAYCO JV","FortifyEnabled":false},
      {"OrgCode":"bmacdc","Name":"BMAC CONSULTING GROUP, LLC 2","FortifyEnabled":false},
      {"OrgCode":"cert_testfederal","Name":"(A) TEST DATABASE - FEDERAL","FortifyEnabled":true},
      {"OrgCode":"49ers_tonic_output","Name":"TONIC LEVI'S STADIUM","FortifyEnabled":false},
      {"OrgCode":"aecom2","Name":"HUNT/CLAYCO JV","FortifyEnabled":false},
      {"OrgCode":"bmacdc","Name":"BMAC CONSULTING GROUP, LLC 2","FortifyEnabled":false},
      {"OrgCode":"cert_testfederal","Name":"(A) TEST DATABASE - FEDERAL","FortifyEnabled":true},
      {"OrgCode":"49ers_tonic_output","Name":"TONIC LEVI'S STADIUM HUNT/CLAYCO JV (A) TEST DATABASE - FEDERAL","FortifyEnabled":false},
      {"OrgCode":"aecom2","Name":"HUNT/CLAYCO JV","FortifyEnabled":false},
      {"OrgCode":"bmacdc","Name":"BMAC CONSULTING GROUP, LLC 2","FortifyEnabled":false},
      {"OrgCode":"cert_testfederal","Name":"(A) TEST DATABASE - FEDERAL","FortifyEnabled":true},
      {"OrgCode":"49ers_tonic_output","Name":"TONIC LEVI'S STADIUM","FortifyEnabled":false},
      {"OrgCode":"aecom2","Name":"HUNT/CLAYCO JV","FortifyEnabled":false},
      {"OrgCode":"bmacdc","Name":"BMAC CONSULTING GROUP, LLC 2","FortifyEnabled":false},
      {"OrgCode":"cert_testfederal","Name":"(A) TEST DATABASE - FEDERAL","FortifyEnabled":true},
      {"OrgCode":"49ers_tonic_output","Name":"TONIC LEVI'S STADIUM","FortifyEnabled":false},
      {"OrgCode":"aecom2","Name":"HUNT/CLAYCO JV","FortifyEnabled":false},
      {"OrgCode":"bmacdc","Name":"BMAC CONSULTING GROUP, LLC 2","FortifyEnabled":false},
      {"OrgCode":"cert_testfederal","Name":"(A) TEST DATABASE - FEDERAL","FortifyEnabled":true},
      {"OrgCode":"49ers_tonic_output","Name":"TONIC LEVI'S STADIUM","FortifyEnabled":false},
      {"OrgCode":"aecom2","Name":"HUNT/CLAYCO JV","FortifyEnabled":false},
      {"OrgCode":"bmacdc","Name":"BMAC CONSULTING GROUP, LLC 2","FortifyEnabled":false},
      {"OrgCode":"cert_testfederal","Name":"(A) TEST DATABASE - FEDERAL","FortifyEnabled":true},
      {"OrgCode":"49ers_tonic_output","Name":"TONIC LEVI'S STADIUM","FortifyEnabled":false},
      {"OrgCode":"aecom2","Name":"HUNT/CLAYCO JV","FortifyEnabled":false},
      {"OrgCode":"bmacdc","Name":"BMAC CONSULTING GROUP, LLC 2","FortifyEnabled":false},
      {"OrgCode":"cert_testfederal","Name":"(A) TEST DATABASE - FEDERAL","FortifyEnabled":true}
    ];
    console.log(this.orgList)
  }

}
