import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'src/app/services/shared/shared.service';
import { AppState } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';
//
// ngrx / rxjs
//
import { Store } from '@ngrx/store';
import { StorageService } from 'src/app/services/storage/storage.service';



@Component({
  selector: 'app-username',
  templateUrl: './username.component.html',
  styleUrls: ['./username.component.scss']
})
export class UsernameComponent implements OnInit {
  usernameForm: FormGroup;
  userSubscribe : any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private shared: SharedService,
    private store: Store<AppState>,
    private storage : StorageService
  ) { 
    this.storage.clearAll();
    this.shared.setProgressBar(false);
    this.shared.setlayoutProject(false);
  }

  ngOnDestroy() {
    this.userSubscribe.unsubscribe();
  }

  ngOnInit() {
    //
    // Setup up Form
    //
    const username = this.route.snapshot.params.username;
    this.usernameForm = this.fb.group({
      username : [username, Validators.required]
    });
    //
    // Subscribe to Shared Service :: setUserName(value)
    //
    this.userSubscribe = 
    this.shared.getUserName().subscribe( data => {
      if (data != (null || undefined)) {
        this.clearUserName();
      }
    })
  }  
  
  // ---------------------------------------------------------------------------
  // -----------------------   UX Experience   ---------------------------------
  // ---------------------------------------------------------------------------
  clearUserName(): void {
    this.usernameForm.get('username').setValue('');
  }
  onSubmit() {
    //
    // Block lcptracker.com, allow others.
    //
    const username = (this.usernameForm.value.username as string).trim();
    if (/@lcptracker\.com/i.test(username)) {
      this.shared.setError(true);
    } else {
      this.router.navigate(['/login', username, 'password']);
    }
  }
  fortifyExternalProviders() {
    //
    // OIDC connection through ngrx action.
    //
    const username = ''; // more refactoring needed.
    this.storage.setItem('tpa-login', true);
    this.store.dispatch(AllActions.preLogin({ username }));
  }
  logoutOnBoth() {
    // this.store.dispatch(AllActions.logoutLcptracker());
    // this.store.dispatch(AllActions.logoutFortify());
  }



  

}
