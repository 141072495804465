<!-- Component Wrapper -->
<div class="account-selector-card">
  <form [formGroup]="codeEmailForm">
    <div class="full-contractor-onboarding-form">
      <!-- LCPtracker Logo Container -->
      <div class="text-center">
        <!-- LCPtracker Logo -->
        <img src="assets/logo.png" alt="LCPtracker Logo">
      </div>
      <!-- Full Contractor Onboarding Form Advertisement -->
      <div
        [ngClass]="attemptCounter > 0 ? 'full-contractor-onboarding-form-banner-warning' : 'full-contractor-onboarding-form-advertisement'">
        <!-- Icon Wrapper -->
        <mat-icon>
          <!-- "Warning" Icon -->
          <span class="notification-icon">error_outline</span>
        </mat-icon>
        <!-- Full Contractor Onboarding Form Advertisement OTP Email Message -->
        <p>
          {{ messageBanner }}
        </p>
      </div>
      <div class="section-input-otp-email">
        <mat-form-field appearance="outline" hintLabel="Max 9 characters">
          <mat-label>Enter email code</mat-label>
          <input matInput #input placeholder="Ex. 000000-EX" formControlName="code" maxlength="9"
            [errorStateMatcher]="matcher">
          <mat-icon matSuffix [ngClass]="{'icon-success': input.value?.length == 9}">verified_user</mat-icon>
          <mat-hint align="end">{{input.value?.length || 0}}/9</mat-hint>
          <mat-error
            *ngIf="codeEmailForm.controls['code'].hasError('maxlength') && !codeEmailForm.controls['code'].hasError('required')">
            Please enter a valid code
          </mat-error>
          <mat-error *ngIf="codeEmailForm.controls['code'].hasError('required')">
            Code is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div style="display: flex; flex-direction: row; gap: 1rem; align-self: flex-end;">
        <app-button buttonType="flat" label="Go back" (buttonClicked)="goBack()"></app-button>
          <div class="button-wrapper">
            <button
              mat-flat-button
              [class.spinner]="(loading$ | async)"
              type="button"
              [disabled]="!codeEmailForm.valid || input.value?.length !== 9"
              class="storybook-button storybook-button--primary"
              (click)="goToAccountSelector()">
              Continue
            </button>
          </div>
      </div>
    </div>
  </form>
</div>
