import { OAuthStorage } from 'angular-oauth2-oidc';

export class FortifyOAuthStorage extends OAuthStorage {
    getItem(key: string): string | null {
        return localStorage.getItem(`Fortify_${key}`);
    }

    removeItem(key: string): void {
        localStorage.removeItem(`Fortify_${key}`);
    }

    setItem(key: string, data: string): void {
        localStorage.setItem(`Fortify_${key}`, data);
    }
}

export function FortifyOAuthStorageFactory(): OAuthStorage { return new FortifyOAuthStorage(); }
