import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from "jwt-decode";

import { LcpService } from 'src/app/services/lcp/lcp.service';
import { FortifyOAuthService } from 'src/app/auth/fortify/fortify-oauth.service';
import { MonetizationService } from 'src/app/services/monetization/monetization.service';
import { SelectedContractor } from 'src/app/models/interfaces';
import { SESSION_STORAGE } from './../../../models/consts';

@Component({
  selector: 'app-purchase-callback',
  templateUrl: './purchase-callback.component.html',
  styleUrls: ['./purchase-callback.component.scss']
})
export class PurchaseCallbackComponent implements OnInit {
  idDecodedToken: {
    email: string,
  };

  constructor(
    private router: Router,
    private fortifyOAuthService: FortifyOAuthService,
    private lcpService: LcpService,
    private monetizationService: MonetizationService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.handleRedirection();
  }

  /**
   * Handles the redirection based on the RBS response
   */
  handleRedirection(): void {
    let selectedContractor: SelectedContractor = JSON.parse(localStorage.getItem(SESSION_STORAGE.SELECTED_CONTRACTOR_ID));
    let contractorId = selectedContractor ? selectedContractor.contractorId : null;

    if (!contractorId) {
      this.router.navigateByUrl('login/account');
      return;
    }

    this.lcpService.getRBSClaims(this.fortifyOAuthService.getAccessToken())
      .subscribe(rbs => {

        this.idDecodedToken = jwt_decode(this.fortifyOAuthService.getIdToken());

        if (this.monetizationService.isAContractor(rbs)) {
          this.monetizationService.hasFoundLicenseByOrg(contractorId, this.idDecodedToken.email).subscribe(res => {
            this.router.navigateByUrl('login/account')
          }, error => {
            this.openNotification('No licenses were found')
            this.router.navigateByUrl('login/account')
          })
        } else {
          this.router.navigateByUrl('login/account')
        }
      }, error => {
        this.openNotification(`Couldn't get user information`);
      })
  }

  /**
   * Opens the Snackbar with the specified message
   * @param message
   */
  openNotification(message: string) {
    this.snackBar.open(message, 'CLOSE', { duration: 5000, verticalPosition: "top"});
  }
}
