<!-- Component Wrapper -->
<div class="account-selector-card">

  <!-- LCPtracker Logo Container -->
  <div class="text-center">

    <!-- LCPtracker Logo -->
    <img src="assets/logo.png" alt="LCPtracker Logo">

  </div>

    <div class="container-message">
      <p [innerHTML]="messageBackEnd | emailHighlighter"></p>
      <a [tabIndex]="1" (keydown.enter)="goToUserPortal()" (click)="goToUserPortal()">Go to User Portal</a>
    </div>

</div>
