import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material';
import { AlertDialogComponent } from './dialog/alert-dialog.component';
import { AppState } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index'
//
// ngrx / rxjs
//
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {
  private alertSubscription: Subscription;
  private dialogRef: MatDialogRef<AlertDialogComponent, string> = null;

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.alertSubscription = this.store.select('alert').subscribe(state => {
      // if (this.dialogRef) {
      //   console.log('closing alert dialog');
      //   this.dialogRef.close();
      //   this.dialogRef = null;
      // }

      if (state.message) {
        this.store.dispatch(AllActions.setBusyMessage({ message: '' }));
        this.dialogRef = this.dialog.open<AlertDialogComponent, string>(AlertDialogComponent, {
          data: state.message,
          disableClose: true,
        });

        this.dialogRef.afterClosed().subscribe(next => {
          console.log('afterClosed', this.dialogRef, next);
          if (this.dialogRef) {
            this.store.dispatch(AllActions.showAlert({ message: '' }));
          }
          this.dialogRef = null;
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.alertSubscription) {
      this.alertSubscription.unsubscribe();
    }
  }
}
