import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";

export const authNet6ConfigCode: AuthConfig = {
  // Url of the Identity Provider
  issuer: 'https://devv2.fortify.lcptracker.net',

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/login/sso/net6/callback',

  responseType: 'code',

  // The SPA's id. The SPA is registered with this id at the auth-server
  clientId: 'LCPtrackerSPA.code',

  // set the scope for the permissions the client should request
  scope: 'openid profile email theidserveradminapi',

  //set post logout redirect uri
  postLogoutRedirectUri: `${environment.userPortal}/post-logout`,
}