import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { OtpEmailService } from './service/otp-email.service';
import { catchError, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MessageFromBackEnd } from './interfaces/response-message';
import {
  FullContractorAccount,
  FullContractorAccountResponse,
  FullContractorUserWithAccounts,
  GetAccountsFullContractorResponseData,
} from '../account-selector/interfaces/database-interface';
import { ContractorSelectorAccountService } from '../account-selector/service/contractor-selector-account.service';

@Component({
  selector: 'app-otp-email',
  templateUrl: './otp-email.component.html',
  styleUrls: ['./otp-email.component.scss'],
})
export class OtpEmailComponent implements OnInit, OnDestroy {
  codeEmailForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  messageBanner: string = `To validate the email address entered, we’ve sent an authentication code to your inbox. ﻿Please check your inbox/junk folder.
  Enter the authentication code below for validation.`;
  labelButton: string = 'Continue';
  attemptCounter: number = 0;
  userDataSet = JSON.parse(localStorage.getItem('formFullContractor'));
  isEmailContractorUser: false;

  codeToValidate: number | string = 0;
  btnToShowSendCode: boolean = false;
  _subscriptionToDestroy$ = new Subject();
  loading$ = this._otpEmailService.loadingSendEmailSubject$;
  private items: FullContractorAccount[] = [];
  fullContractorUserWithAccounts: FullContractorUserWithAccounts =
    Object.assign({});
  userName: string;
  fullContractorAccountResponse: FullContractorAccountResponse = Object.assign(
    {}
  );

  @HostListener('window:beforeunload', ['$event']) unloadNotification(
    $event: Event
  ): void {
    sessionStorage.setItem('isRefreshed', 'true');
  }

  constructor(
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _otpEmailService: OtpEmailService,
    private _snackBar: MatSnackBar,
    private _contractorSelectorAccountService: ContractorSelectorAccountService
  ) {
    this.isEmailContractorUser = JSON.parse(
      localStorage.getItem('IsEmailContractorUser')
    );
  }

  ngOnInit() {
    this.initForm();
    this.sendCodeToEmail();
    this.getAccountsForFullContractor();
  }

  initForm(): void {
    this.codeEmailForm = this._formBuilder.group({
      code: [
        { value: '', disabled: true },
        [Validators.required, Validators.maxLength(9)],
      ],
    });
  }

  incrementAttempt(): void {
    this.attemptCounter++;
  }

  goBack(): void {
    localStorage.removeItem('formFullContractor');
    sessionStorage.removeItem('isRefreshed');
    this._router.navigate(['v2/full-contractor']);
  }

  goToAccountSelector(): void {
    if (this.codeEmailForm.valid) {
      if (this.codeEmailForm.get('code').value !== this.codeToValidate) {
        this.incrementAttempt();
        this.messageBanner = `The verification code is incorrect, please try again (${this.attemptCounter} of 4 times)`;
      }
      if (this.attemptCounter <= 4) {
        if (this.codeEmailForm.get('code').value === this.codeToValidate) {
          this.attemptCounter = 0;
          if (!this.isEmailContractorUser) {
            this._router.navigate(['v2/full-contractor/account-selector']);
          } else {
            this.fullContractorUserWithAccounts.User = this.userDataSet;
            this.fullContractorUserWithAccounts.User.UserName =
              this._contractorSelectorAccountService.userNameStream.value;
            this.fullContractorUserWithAccounts.Accounts = this.items;
            this.loading$ = this._contractorSelectorAccountService.loadingSendInformationSubject$;
            this.codeEmailForm.disable();
            this._contractorSelectorAccountService
              .sendInformationFullContractor(
                this.fullContractorUserWithAccounts
              )
              .subscribe((_response: FullContractorAccountResponse) => {
                this._contractorSelectorAccountService.responseFullContractor.next(_response);
                sessionStorage.setItem('responseFullContractor',JSON.stringify(_response));
                this.codeEmailForm.enable();
                this._router.navigate(['v2/full-contractor/contractor-user-portal']);
              });
          }
        }
      } else {
        this.attemptCounter = 0;
        this.initForm();
        this.resendCodeToEmail();
      }
    }
  }

  resendCodeToEmail(): void {
    this.btnToShowSendCode = true;
    this.messageBanner = `To validate the email address entered, we’ve sent an authentication code to your inbox. ﻿Please check your inbox/junk folder.
    Enter the authentication code below for validation.`;
    sessionStorage.removeItem('isRefreshed');
    this.sendCodeToEmail();
  }

  sendCodeToEmail(): void {
    if (this.userDataSet != null) {
      if (!sessionStorage.getItem('isRefreshed')) {
        this._otpEmailService
          .sendCodeViaEmail(this.userDataSet.email)
          .pipe(
            take(1),
            takeUntil(this._subscriptionToDestroy$),
            catchError((error) => {
              this._snackBar
                .open(
                  'Oops, an error occurred when trying to send a code via email.',
                  'OK',
                  {
                    verticalPosition: 'top',
                    duration: 1500,
                  }
                )
                .afterOpened()
                .pipe(takeUntil(this._subscriptionToDestroy$))
                .subscribe(() => this.goBack());
              throw `Server side: ${JSON.stringify({ error })}`;
            })
          )
          .subscribe((value: MessageFromBackEnd): void => {
            if (value.status !== 404) {
              this._snackBar.open(
                this.btnToShowSendCode
                  ? 'A new code has been successfully sent to your email.'
                  : 'A code has been successfully sent to your email.',
                'OK',
                {
                  verticalPosition: 'top',
                  duration: 1500,
                }
              );
              this.btnToShowSendCode = false;
              this.codeToValidate = value.data;
              sessionStorage.setItem('codeEmail', this.codeToValidate);
              this._otpEmailService.loadingSendEmailSubject.next(false);
              this.codeEmailForm.get('code').enable();
            } else {
              this._snackBar.open(value.message, 'OK', {
                verticalPosition: 'top',
                duration: 1500,
              });
              this._otpEmailService.loadingSendEmailSubject.next(false);
            }
          });
      } else {
        this.codeToValidate = sessionStorage.getItem('codeEmail');
        this._otpEmailService.loadingSendEmailSubject.next(false);
        this.codeEmailForm.get('code').enable();
      }
    } else {
      this.goBack();
      this._otpEmailService.loadingSendEmailSubject.next(false);
    }
  }
  getAccountsForFullContractor(): void {
    this._contractorSelectorAccountService
      .getData()
      .subscribe((items: GetAccountsFullContractorResponseData[]): void => {
        if (items['Accounts'].length) {
          this.items = items['Accounts'];
        } else {
          this.items = [];
          this._router.navigate(['../login']);
        }
      },
      (_error): void => {
        setTimeout(() => {
          this._router.navigate(['../login']);
        }, 1000);
        this._snackBar.open(
          'Apologies for the issue retrieving your accounts. Please try again or contact support for assistance.',
          'OK',
          {
            verticalPosition: 'top',
            duration: 5000,
          }
        );
      });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    sessionStorage.removeItem('isRefreshed');
    sessionStorage.removeItem('codeEmail');
    this._subscriptionToDestroy$.next(true);
    this._subscriptionToDestroy$.complete();
  }
}

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
