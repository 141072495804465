<div *ngIf="hasFinishedMergingAccounts; else loadingAccounts">
  <!-- Empty account list banner -->
  <div *ngIf="!accountsMerged.length">
    <a class="go-back-no-matches" title="Go Back to Previous" (click)="onPrevious()">
      <i class="fa fa-chevron-circle-left"></i>
    </a>
    No available accounts were found
  </div>

  <!-- List of accounts -->
  <div *ngIf="!shouldPurchaseALicense; else purchaseALicenseBanner">
    <!-- Mobile -->
    <div class="filter-mobile" *ngIf="accountsMerged.length">
      <h5 class="project-header">
        <a class="go-back" title="Go Back to Previous" (click)="onPrevious()">
          <i class="fa fa-chevron-circle-left"></i>
        </a>
        Select an Account:
      </h5>
      <div class="filter-search">
        <i class="fa fa-search filter-search-icon" aria-hidden="true"></i>
        <input id="mobileFilterby" (keyup)="applyFilterMobile($event.target.value)" class="filter-search-input"
          placeholder="Filter Accounts" />
        <label for="mobileFilterby">Search Filter</label>
      </div>

      <ul id="filter-list-mobile" class="project-mobile-list">
        <!-- Due to the implementation of RoleId accounts coming from RBS Claims do not return RoleId-->
        <!-- RoleID is a required parameter that only comes from TrackerApi-->
        <ng-container *ngFor="let account of accountsMerged; let i = index;">
          <li *ngIf="account.RoleId">
            <a tabindex="0" (click)="selectAccount(account)"
              (keyup.enter)="selectAccount(account)">{{account.Name | uppercase}}</a>
          </li>
        </ng-container>

      </ul>
    </div>

    <!-- Desktop -->
    <div class="filter-desktop" *ngIf="accountsMerged.length">
      <h5 class="project-header">
        <a class="go-back" title="Go Back to Previous" (click)="onPrevious()">
          <i class="fa fa-chevron-circle-left"></i>
        </a>
        Select an Account:
      </h5>
      <div class="filter-search">
        <i class="fa fa-search filter-search-icon" aria-hidden="true"></i>
        <input id="desktopFilterby" (keyup)="applyFilterDesktop($event.target.value)" class="filter-search-input"
          placeholder="Filter Accounts" />
        <label for="desktopFilterby">Search Filter</label>
      </div>

      <ul id="filter-list-desktop" [ngClass]="{'project-list':true, 'multi-list': expand == true}">
        <!-- Due to the implementation of RoleId accounts coming from RBS Claims do not return RoleId-->
        <!-- RoleID is a required parameter that only comes from TrackerApi-->
        <ng-container *ngFor="let account of accountsMerged; let i = index;">
          <li *ngIf="account.RoleId">
            <a tabindex="0" (click)="selectAccount(account)"
              (keyup.enter)="selectAccount(account)">{{account.Name | uppercase}}</a>
          </li>
        </ng-container>
      </ul>

      <div *ngIf="showExpandArrows" class="expanding-arrow">
        <a tabindex="0" class="expand-button" (click)="toggleComplete()" (keyup.enter)="toggleComplete()">
          <i *ngIf="expand == false" class="fa fa-caret-up" aria-hidden="true"></i>
          <i *ngIf="expand == true" class="fa fa-caret-down" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>

  <app-tpa-info *ngIf="notTPASatisdfiedAccounts.length" [notTPASatisdfiedAccounts]="notTPASatisdfiedAccounts"></app-tpa-info>

  <ng-template #purchaseALicenseBanner>
    <app-purchase-license></app-purchase-license>
  </ng-template>
</div>

<!-- Loading accounts banner -->
<ng-template #loadingAccounts>
  <div class="accounts-load-banner">
    <i class="fas fa-spinner fa-pulse fa-3x"></i>
    <h6>Loading accounts</h6>
    <p>The account list load is in progress...</p>
  </div>
</ng-template>
