import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@angular/router";
import * as i3 from "../../../auth/fortify/fortify-oauth.service";
export class PurchaseCallbackGuard {
    constructor(store, router, fortifyOAuthService) {
        this.store = store;
        this.router = router;
        this.fortifyOAuthService = fortifyOAuthService;
    }
    canActivate(next, state) {
        if (this.fortifyOAuthService.hasValidAccessToken()) {
            return true;
        }
        else {
            this.router.navigateByUrl('login/account');
        }
    }
}
PurchaseCallbackGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PurchaseCallbackGuard_Factory() { return new PurchaseCallbackGuard(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.FortifyOAuthService)); }, token: PurchaseCallbackGuard, providedIn: "root" });
