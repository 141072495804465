import { createReducer, Action, on, props } from '@ngrx/store';
import { stateAccount } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';

const initialState: stateAccount = {
    accounts: [],
    accountType: '',
    requireMFA: false,
    tpaOrgCodes: ''
};

const accountReducer = createReducer(
    initialState,
    on(AllActions.getAccountsStart, (state) => ({ ...state, loading: true })),
    on(AllActions.getAccountsSuccess, (state, action) => ({ ...state, loading: false, accounts: action.accounts })),
    on(AllActions.getAccountsFail, (state, action) => ({ ...state, loading: false, errorMessage: action.errorMessage })),
    on(AllActions.setOrgCodeStart, (state) => ({ ...state, loading: true })),
    on(AllActions.setOrgCodeSuccess, (state, action) => ({ ...state, loading: false, accountType: action.accountType })),
    on(AllActions.setOrgCodeFail, (state, action) => ({ ...state, loading: false, errorMessage: action.errorMessage }))
);

export function reducer(state: stateAccount | undefined, action: Action) {
    return accountReducer(state, action);
}
