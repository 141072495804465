import { OAuthStorage } from 'angular-oauth2-oidc';

export class LcpTrackerOAuthStorage extends OAuthStorage {
    getItem(key: string): string | null {
        return localStorage.getItem(`LcpTracker_${key}`);
    }

    removeItem(key: string): void {
        localStorage.removeItem(`LcpTracker_${key}`);
    }

    setItem(key: string, data: string): void {
        localStorage.setItem(`LcpTracker_${key}`, data);
    }
}

export function LcpTrackerOAuthStorageFactory(): OAuthStorage { return new LcpTrackerOAuthStorage(); }
