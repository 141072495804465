import { Routes } from '@angular/router';
// Resolvers
import { AccountResolverService } from './routes/login/account/account-resolver.service';
import { ImpersonateResolverService } from './routes/login/impersonate/impersonate-resolver.service';
import { PasswordResolverService } from './routes/login/password/password-resolver.service';
import { ProductsResolverService } from './features/account-selector/fortify-v2-accounts/fortify-v2-accounts.resolver';
// Components
import { AccountComponent } from './routes/login/account/account.component';
import { FortifyDirectComponent } from './routes/fortify-direct/fortify-direct.component';
import { FortifyRedirectComponent } from './routes/login/fortify-redirect/fortify-redirect.component';
import { ImpersonateComponent } from './routes/login/impersonate/impersonate.component';
import { LcpTrackerRedirectComponent } from './routes/login/lcptracker-redirect/lcptracker-redirect.component';
import { LoginComponent } from './routes/login/login.component';
import { PasswordComponent } from './routes/login/password/password.component';
import { UsernameComponent } from './routes/login/username/username.component';
import { FortifyAccountsComponent } from './routes/fortify-accounts/fortify-accounts.component';
import { FortifyImpersonateComponent } from './routes/fortify-impersonate/fortify-impersonate.component';
import { FullContractorComponent } from './routes/login/full-contractor/full-contractor.component';
import { FortifyV2RedirectComponent } from './features/account-selector/fortify-v2-redirect/fortify-v2-redirect.component';
import { FortifyV2CallbackComponent } from './features/account-selector/fortify-v2-callback/fortify-v2-callback.component';
import { FortifyV2AccountsComponent } from './features/account-selector/fortify-v2-accounts/fortify-v2-accounts.component';
import { FortifyV2LogoutComponent } from './routes/logout/fortfy-v2-logout/fortify-v2-logout.component';
import { PurchaseCallbackComponent } from './routes/login/purchase-callback/purchase-callback.component';
import { PurchaseCallbackGuard } from './routes/login/purchase-callback/purchase-callback.guard';
import { SsoNet6Component } from './auth/net6/components/sso-net6/sso-net6.component';
import { SsoNet6AccountsComponent } from './auth/net6/components/sso-net6-accounts/sso-net6-accounts.component';
import { SsoNet6CallbackComponent } from './auth/net6/components/sso-net6-callback/sso-net6-callback.component';
import { FullContractorV2Component } from './auth/net6/components/full-contractor/full-contractor.component';
import { FullContractorFormComponent } from './auth/net6/components/full-contractor/full-contractor-form/full-contractor-form.component';
import { FullContractorAccountSelectorComponent } from './auth/net6/components/full-contractor/account-selector/account-selector.component';
import { ImpersonateV2Component } from './features/impersonate-v2/impersonate.component';
import { OtpEmailComponent } from './auth/net6/components/full-contractor/otp-email/otp-email.component';
import { ContractorUserPortalComponent } from './auth/net6/components/full-contractor/contractor-user-portal/contractor-user-portal.component';
const ɵ0 = ProductsResolverService;
const appRoutes = [
    { path: 'fortify-direct/account', component: FortifyAccountsComponent },
    { path: 'fortify-direct/impersonate', component: FortifyImpersonateComponent },
    { path: 'fortify-direct/:username', component: FortifyDirectComponent },
    { path: 'fortify-direct', component: FortifyDirectComponent },
    { path: 'logout-fortify', component: FortifyV2LogoutComponent },
    {
        path: 'login', component: LoginComponent, children: [
            { path: '', component: UsernameComponent, pathMatch: 'full' },
            /**
             * FortifyAuth Resolvers.
             */
            { path: 'fortify-direct', component: FortifyDirectComponent },
            { path: 'fortify-redirect', component: FortifyRedirectComponent },
            /**
             * LCPtrackerAuth Resolvers.
             */
            { path: 'lcptracker-redirect', component: LcpTrackerRedirectComponent },
            { path: 'account', resolve: { resolveData: AccountResolverService }, component: AccountComponent },
            { path: 'impersonate', resolve: { resolveData: ImpersonateResolverService }, component: ImpersonateComponent },
            /**
             * ONLY HOLDING LCPTRACKER AUTH WITH CALLS OF USERNAME. Other routes are empty headed.
             */
            { path: ':username/password', resolve: { resolveData: PasswordResolverService }, component: PasswordComponent },
            { path: ':username/enableFullContractor', component: FullContractorComponent },
            { path: 'purchaseCallback', component: PurchaseCallbackComponent, canActivate: [PurchaseCallbackGuard] },
            { path: ':username', component: UsernameComponent },
        ],
    },
    { path: 'v2/full-contractor', component: FullContractorV2Component, children: [
            {
                path: '',
                component: FullContractorFormComponent,
            },
            {
                path: 'otp-email',
                component: OtpEmailComponent
            },
            {
                path: 'account-selector',
                component: FullContractorAccountSelectorComponent
            },
            {
                path: 'contractor-user-portal',
                component: ContractorUserPortalComponent
            }
        ]
    },
    { path: 'sso/net6', component: SsoNet6Component },
    { path: 'sso/net6/callback', component: SsoNet6CallbackComponent },
    { path: 'sso/net6/accounts', component: SsoNet6AccountsComponent },
    { path: 'auth', component: FortifyV2RedirectComponent },
    { path: 'auth/callback', component: FortifyV2CallbackComponent },
    { path: 'auth/accounts',
        component: FortifyV2AccountsComponent,
        resolve: { data: ɵ0 }
    },
    { path: 'auth/impersonate', component: ImpersonateV2Component, resolve: { resolveData: ImpersonateResolverService }, },
    { path: '**', redirectTo: '/login' },
];
export class AppRoutingModule {
}
export { ɵ0 };
