<div class="sso-page-container">
    <div class="sso-page-card sm:mx-2.28">
        <img src="assets/logo.png" alt="LCPtracker Logo">

        <ng-container *ngIf="validated == true">
            <div class="progress-spinner-container">
                <mat-progress-spinner class="example-margin" color="primary" mode="indeterminate" diameter="50">
                </mat-progress-spinner>
            </div>
            <h4 *ngIf="!isLogout">Logging in</h4>
            <h4 *ngIf="isLogout">Logging Out</h4>
            <h5>&nbsp;{{username}}</h5>
            <p>{{statusDescription}}</p>
        </ng-container>

        <ng-container *ngIf="validated == false">
            <div class="error-actions">
                <h4>Oops!</h4>
                <p>
                  Sorry, there are no Agency Accounts associated with your Username.
                  Try contacting your Admin or Main Contact.
                </p>
                <div class="error-links">
                    <app-button (buttonClicked)="goBack()" (enterKeyUp)="goBack()" label="Dashboard" buttonType="flat" prefixIcon="arrow_back" ></app-button>
                    <app-button (buttonClicked)="retryAttempts = 0; validateToken()" (enterKeyUp)="retryAttempts = 0; validateToken()" label="Try Again" buttonType="flat" prefixIcon="refresh" ></app-button>
                    <!-- <app-button (buttonClicked)="logout()" (enterKeyUp)="logout()" label="Logout" buttonType="flat" prefixIcon="logout" ></app-button> -->
                </div>

            </div>
        </ng-container>


    </div>
    <footer></footer>
  </div>
