import * as AllActions from 'src/app/store/actions/index';
import { ofType } from '@ngrx/effects';
import { take, map } from 'rxjs/operators';
import { merge } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@ngrx/effects";
import * as i3 from "@angular/router";
export class AccountResolverService {
    constructor(store, actions$, router) {
        this.store = store;
        this.actions$ = actions$;
        this.router = router;
    }
    resolve(route, state) {
        console.log('Account Resolver ActivatedRouteSnapshow' + JSON.stringify(route.data));
        this.store.select('password').subscribe(loginInfo => {
            if (loginInfo.enableFullContractor) {
                localStorage.setItem('IsEmailContractorUser', JSON.stringify(loginInfo.isEmailContractorUser));
                this.router.navigate(['v2/full-contractor']);
            }
            else {
                this.store.dispatch(AllActions.getAccountsStart());
            }
        });
        const getAccountsSuccess$ = this.actions$
            .pipe(ofType(AllActions.getAccountsSuccess));
        const getAccountsFail$ = this.actions$.pipe(ofType(AllActions.getAccountsFail), map(() => ({ username: '', supportUsername: '', accounts: [] })));
        return merge(getAccountsSuccess$, getAccountsFail$).pipe(take(1));
    }
}
AccountResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountResolverService_Factory() { return new AccountResolverService(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.Actions), i0.ɵɵinject(i3.Router)); }, token: AccountResolverService, providedIn: "root" });
