import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export interface Claims {
    [x: string]: any;
    mfa_enabled: boolean;
    user_name: string;
}

const config = {
    discoveryDocumentUrl: `${environment.fortifyUrl}/.well-known/openid-configuration`,
    loginUrl: `${environment.fortifyUrl}/connect/authorize`,
    issuer: environment.fortifyUrl,
};

export const discoveryDocumentUrl = config.discoveryDocumentUrl;
export const loginUrl = config.loginUrl;

export const authConfig: AuthConfig = {
    issuer: config.issuer,
    redirectUri: `${window.location.origin}/login/login/fortify-redirect`,
    requestAccessToken: true,
    showDebugInformation: true,
    clientId: environment.fortifyClientId,
    responseType : 'id_token token',
    strictDiscoveryDocumentValidation: false,
    postLogoutRedirectUri: `${window.location.origin}/login/login`,
};

export const authConfigDirect: AuthConfig = {
    issuer: config.issuer,
    redirectUri: `${window.location.origin}/login/fortify-direct`,
    requestAccessToken: true,
    showDebugInformation: true,
    clientId: environment.fortifyClientId,
    responseType : 'id_token token',
    strictDiscoveryDocumentValidation: false,
    postLogoutRedirectUri: `${window.location.origin}/login/login`,
};