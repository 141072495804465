import { BehaviorSubject, Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class OtpEmailService {
    constructor(_http) {
        this._http = _http;
        this.loadingSendEmailSubject = new BehaviorSubject(true);
        this.loadingSendEmailSubject$ = this.loadingSendEmailSubject.asObservable();
    }
    /**
     * Send code called OTP via Email for Email Authenticated (Double check in UI)
     * @param email
     * @returns
     */
    sendCodeViaEmail(email) {
        const formData = new FormData();
        formData.append("emailaddress", email);
        this.loadingSendEmailSubject.next(true);
        return this._http
            .post(`${environment.fortifyV2APIUrl}/IPPolicy/GetVerificationCode`, formData)
            .pipe(tap(() => {
            this.loadingSendEmailSubject.next(false);
        }));
    }
}
OtpEmailService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OtpEmailService_Factory() { return new OtpEmailService(i0.ɵɵinject(i1.HttpClient)); }, token: OtpEmailService, providedIn: "root" });
