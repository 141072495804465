import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-busy-dialog',
  templateUrl: './busy-dialog.component.html',
  styleUrls: ['./busy-dialog.component.scss']
})
export class BusyDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<BusyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string) { }
}
