import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {

  constructor(
    private dialog: MatDialog,
  ) { }

  confirmationDialog(
    title?: string,
    body?: string | any,
    confirmButton?: string | null,
    cancelButtton?: string | null): MatDialogRef<DialogComponent> {
    return this.dialog.open(DialogComponent, {
      width: '430px',
      height: 'auto',
      autoFocus: false,
      disableClose:true,
      panelClass: 'custom-mat-dialog-container',
      data: {
        title,
        body,
        confirmButton,
        cancelButtton
      },
    });
  }
}
