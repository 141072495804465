/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./purchase-callback.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./purchase-callback.component";
import * as i8 from "@angular/router";
import * as i9 from "../../../auth/fortify/fortify-oauth.service";
import * as i10 from "../../../services/lcp/lcp.service";
import * as i11 from "../../../services/monetization/monetization.service";
import * as i12 from "@angular/material/snack-bar";
var styles_PurchaseCallbackComponent = [i0.styles];
var RenderType_PurchaseCallbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PurchaseCallbackComponent, data: {} });
export { RenderType_PurchaseCallbackComponent as RenderType_PurchaseCallbackComponent };
export function View_PurchaseCallbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "purchase-callback-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "spinner mat-spinner mat-progress-spinner"], ["color", "success"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(2, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"], diameter: [1, "diameter"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["SUCCESS!"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h5", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You were redirected from Chargify"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [["class", "description"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["And now we are just checking the status of your license. This may take several minutes..."]))], function (_ck, _v) { var currVal_3 = "success"; var currVal_4 = 50; _ck(_v, 2, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_PurchaseCallbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-purchase-callback", [], null, null, null, View_PurchaseCallbackComponent_0, RenderType_PurchaseCallbackComponent)), i1.ɵdid(1, 114688, null, 0, i7.PurchaseCallbackComponent, [i8.Router, i9.FortifyOAuthService, i10.LcpService, i11.MonetizationService, i12.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PurchaseCallbackComponentNgFactory = i1.ɵccf("app-purchase-callback", i7.PurchaseCallbackComponent, View_PurchaseCallbackComponent_Host_0, {}, {}, []);
export { PurchaseCallbackComponentNgFactory as PurchaseCallbackComponentNgFactory };
