









<ng-container *ngIf="fortifyDirect != '/login/fortify-direct'">
    <!-- <div class="greeting">Continue as: </div>
    <div class="label-user">
        <a tabindex="0" [attr.title]="'Sign in as: ' + usernameLabel"
            (click)="continue()"
            (keyup.enter)="continue()"
            >{{usernameLabel}}
        </a>
    </div> -->
</ng-container>


<ng-container *ngIf="fortifyDirect == '/login/fortify-direct'">


    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="login-duel">
                    <mat-progression-bar [show]="showProgressBar"></mat-progression-bar>
                    <div class="fortify-logout"
                        *ngIf="showFortifyLink == true">
                        <a tabindex="0" class="fortify-link"
                            (click)="logoutFortify()"
                            (keyup.enter)="logoutFortify()"
                            >
                            <i class="fa fa-caret-right fa-lg" aria-hidden="true"></i>
                            Switch Fortify User / Logout
                        </a>
                    </div>
                    <div class="row margin-0">
                        <div [ngClass]="{'col-12 col-md-6': true }">
                            <div class="company-logo">
                                <img src="./assets/logo.png" alt="Sign In to Lcptracker" />
                            </div>
                            <div class="instructions" [ngClass]="{'instructions': true, 'text-left': projectLayout == true}">
                                <p class="h6">For Electronic Submission and Management of Certified Payroll Reports</p>
                            </div>
                           <!-- <div class="greeting">Continue as: </div>
                             <div class="label-user">
                                <a tabindex="0" [attr.title]="'Sign in as: ' + usernameLabel"
                                    (click)="continue()"
                                    (keyup.enter)="continue()"
                                    >{{usernameLabel}}
                                </a>
                            </div> -->
                        </div>
                        <div [ngClass]="{'col-12 col-md-6': true }">
                            <a #marketingAd class="marketing-ad" tabindex="0" [href]="imgLink" target="blank">
                                <img [id]="imgId" [src]="imageURLAsset" [attr.alt]="imgLabel" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>




</ng-container>
