import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage/storage.service';
import { AuthService } from '../../auth.service';


@Component({
  selector: 'route-fortify-v2-redirect',
  templateUrl: './fortify-v2-redirect.component.html',
  styleUrls: ['./fortify-v2-redirect.component.scss']
})
export class FortifyV2RedirectComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private storage : StorageService,
    private router: Router
  ) { 

      sessionStorage.clear(); // to clear out the previous connections stored.
      console.warn('---------LOGGING IN ----------' );
      //
      // K.I.S.S approach. Longer wait time for security.
      this.auth.loginCode();
  }

  ngOnInit() {
    document.body.style.paddingTop = "0px"; // unit measured in Edge.
  }

  

}
