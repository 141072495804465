<a tabindex="0" id="errorBackground" class="background show"
    (click)="closeError()"
    (keyup.enter)="closeError()"
    >
    <div class="contain-message">
        <div class="symbol"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i></div>
        <div class="message-contain">
            <div class="message-heading">There was an Error</div>
            <div class="message-desc">You cannot use an employee email as a username</div>
            <!-- <div class="message-action">
                <button class="btn btn-primary"
                    (click)="altRoute()"
                    (keyup.enter)="altRoute()"
                    >Try different Route
                </button>
            </div> -->
        </div>   
    </div>

    <!-- 🧙 POOR GANDOLF   
    <div #contain class="contain-person">
        <div class="wizard">
            <img #wizard src="assets/images/wizard_480.png" />
        </div>
        <div class="aura">
            <img #aura src="assets/images/aura_480.png" />
        </div>
        <div class="shine">
            <img #shine src="assets/images/shine.png" />
        </div>
    </div>
    <div #statement class="statement">
        <span #you class="thouShall">YOU</span>
        <span #shall class="thouShall"> SHALL</span>
        <span #not class="not">NOT</span>
        <span #pass class="pass">PASS</span>
    </div>-->
</a>

