import { authNet6ConfigCode } from '../auth.net6.config.code';
import * as i0 from "@angular/core";
import * as i1 from "angular-oauth2-oidc";
export class AuthNet6CodeService {
    constructor(oauthService) {
        this.oauthService = oauthService;
    }
    login() {
        // Set the Configuration of the OIDC
        this.oauthService.configure(authNet6ConfigCode);
        // Inits the login
        this.oauthService.loadDiscoveryDocument()
            .then(res => {
            this.oauthService.initCodeFlow();
        });
    }
}
AuthNet6CodeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthNet6CodeService_Factory() { return new AuthNet6CodeService(i0.ɵɵinject(i1.OAuthService)); }, token: AuthNet6CodeService, providedIn: "root" });
