import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppState } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';
//
// ngrx / rxjs
//
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { map, take, tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import { SharedService } from 'src/app/services/shared/shared.service';

@Injectable({ providedIn: 'root' })
export class ImpersonateResolverService implements Resolve<{ supportUsername: string; username: string; }> {
    constructor(
        private store: Store<AppState>, 
        private actions$: Actions, 
        private shared : SharedService,
        private router: Router, 
        private location: Location
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('ImpersonateResolverService.resolve', route, state);
        this.shared.setProgressBar(false);
        this.store.dispatch(AllActions.getUsernameStart());

        const getUsernameSuccess$ = this.actions$.pipe(
            ofType(AllActions.getUsernameSuccess),
            map(response => ({ 
                supportUsername: response.supportUsername, 
                username: response.username, 
                isSupportAdmin: response.isSupportAdmin 
            })),
        );

        const getUsernameFail$ = this.actions$.pipe(
            ofType(AllActions.getUsernameFail),
            tap(action => {
                this.location.back();
            }),
            map(() => ({ supportUsername: '', username: '' })),
        );

        return merge(getUsernameSuccess$, getUsernameFail$).pipe(take(1));
    }
}
