import { RBS_EXTENDED_CLAIMS_KEYS } from './../../models/consts';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  AuthResponse,
  GetAccountsResponseData,
  GetUserNameData,
  GetUserResponseData,
  ImpersonateResponse,
  ValidatePasswordResponseData,
  RBSClaim,
} from 'src/app/models/interfaces';
import { flatMap, map } from 'rxjs/operators';

import { FullContractor, FullContractorResponse } from './../../models/interfaces';
import { Account } from 'src/app/models/account.model';
@Injectable({
  providedIn: 'root'
})
export class LcpService {
  token: any = '';

  constructor(
    private http: HttpClient,
  ) { }

  impersonateUser(action) {
    return this.http.put<ImpersonateResponse>(
      `${environment.webAppUrl}/api/login`,
      {
        Username: action.username,
        OverrideSupport: action.overrideSupport
      },
      { withCredentials: true }
    );
    //
    // Username: string;
    // SupportUsername: string;
    // Accounts: Account[];
    //
  }

  validateToken(token) {
    return this.http.post<AuthResponse>(
      `${environment.webAppUrl}/api/login`,
      { Token: token },
      { withCredentials: true }
    );
    //
    // username: action.username,
    // isSupportUser: response.IsSupportUser
    //
  }

  validatePassword(action) {
    return this.http.post<ValidatePasswordResponseData>(
      `${environment.webAppUrl}/api/login`,
      {
        Username: action.username,
        Password: action.password,
      },
      { withCredentials: true }
    );
    //
    // username: action.username,
    // isSupportUser: response.IsSupportUser
    //
  }

  /**
   * Gets user's information as UsesFortify and IsSupportUser
   * @param user
   * @returns user's information as UsesFortify and IsSupportUser
   */
  validateUserName(user: string): Observable<GetUserResponseData> {
    return this.http.post<GetUserResponseData>(`${environment.webAppUrl}/api/user`, { id : user });
  }

  getUserName() {
    return this.http.get<GetUserNameData>(
      `${environment.webAppUrl}/api/user`,
      { withCredentials: true }
    );
    //
    // username: response.Username,
    // supportUsername: response.SupportUsername,
    // isSupportAdmin: response.IsSupportAdmin
    //
  }

  getClaims(orgId: string, apiScope: string) {
    const options = { headers: this.getHeaders(orgId, apiScope) };
    return this.http.get<any>(
      `${environment.webAppUrl}/api/claims`, options);
  }

  getHeaders(
    orgId: string,
    apiScope: string,
    //impersonate : string
  ): HttpHeaders {
    //
    // Grab the current token
    //
    // this.store.select('fortifyAuth')
    // .pipe(
    //   take(1)
    // ).subscribe(state => {
    //   // a = state.accounts;
    //   this.token = state.idToken;
    // });
    //
    // Construct the header to use values.
    //
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      //'Authorization': `Bearer ${this.token}`,
      // 'Scope': scope,
      //
      //  TODO ::
      //
      'orgId': orgId,
      'apiScope': apiScope,
      //'Impersonate' : impersonate
    });
    return headers;
  }

  getAccounts() {
    return this.http.get<GetAccountsResponseData>(
      `${environment.webAppUrl}/api/accounts`,
      { withCredentials: true }
    );
    //
    // Username: string;
    // SupportUsername: string;
    // Accounts: Account[];
    //
  }

  /**
   * Makes the API request to send the selected account and the list of accounts
   * @param orgCode the selected account code
   * @param orgCodes the list of filtered account codes based on the rules to be displayed in LCPTracker
   * @returns
   */
  setOrgCode(orgCode: string, orgCodes: any[], roleId: string) {
    const mappedAccounts = orgCodes.map((org) => {
      return new Account(org.OrgCode, org.Name, org.FortifyEnabled, org.RoleId);
    });
    return this.http.post<any>(
      `${environment.webAppUrl}/api/accounts`,
      { orgCode, orgCodes: JSON.stringify(mappedAccounts),roleId },
      { withCredentials: true }
    );
  }

  /**
   * Calls the RBS Claims endpoint
   * @param authenticationTokenFromFortify
   * @returns An array of RBS claims
   */
  getRBSClaims(authenticationTokenFromFortify: string): Observable<RBSClaim[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authenticationTokenFromFortify}`,
    });

    const options = {
      headers
    }

    return this.http.get<any>(
      `${environment.fortifyAPIUrl}/TrackerSupport/RbsClaims/GetExtendedIdentityClaims`, options)
      .pipe(
        map(response => response.data),
        flatMap((claimData) => this.parseToPlainObjectsJSONValues(claimData)),
      )
  }

  /**
   * Converts to plain objects the strigyfied response from the RBS API
   * @param claims
   * @returns Plain RBS values objects
   */
  parseToPlainObjectsJSONValues(claims: RBSClaim[]): Observable<RBSClaim[]> {
    claims.forEach(claim => {
      if (this.isJson(claim.value)) {
        claim.value = JSON.parse(claim.value)
      }
    });

    return of(claims);
  }

  /**
   * Checks if a string is a stringified JSON
   */
  isJson(string): boolean {
    try {
      const obj = JSON.parse(string);
      if (obj && typeof obj === `object`) {
        return true;
      }
    } catch (err) {
      return false;
    }

    return false;
  }

  /**
   * Makes a request to LCP to link a professional email with a contractor license
   * @param fullContractor
   * @returns
   */
  enableFullContractorUser(fullContractor: FullContractor): Observable<FullContractorResponse> {
    return this.http.post<FullContractorResponse>(
      `${environment.webAppUrl}/api/fullContractorUser`,
      {
        UserName: fullContractor.UserName,
        FirstName: fullContractor.FirstName,
        LastName: fullContractor.LastName,
        PhoneNumber: fullContractor.PhoneNumber,
        Email: fullContractor.Email,
      },
      { withCredentials: true }
    );
  }
}
