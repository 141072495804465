import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';
import { CustomSnackBarComponent } from 'src/app/components/shared/custom-snack-bar/custom-snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private snackBar: MatSnackBar,
  ) {  }

  openSnackBar(message: string, action: string) {
    this.snackBar
      .open(message, action, {
        duration: 10000,
        verticalPosition: "top"
      })
      .onAction()
      .subscribe(() => {
        // Keep this here for when you want to "undo" a functionality.
      });
  }

  openCustomSnackBar(message: string) {
    this.snackBar.openFromComponent(CustomSnackBarComponent, {
      data: {html : message},
      duration: 0,
      verticalPosition: "top",
      panelClass: ['flex', 'flex-row']
    });
  }

  refreshScreen() {
    location.reload();
  }
  //
  // ERROR Screen and Clear User Name.
  //
  public error$ = new BehaviorSubject<any>(null);
  public getError() {
    return this.error$.asObservable();
  }
  public setError(value) {
    this.error$.next(value);
  }
  public clearUserName$ = new BehaviorSubject<any>(null);
  public getUserName() {
    return this.clearUserName$.asObservable();
  }
  public setUserName(value) {
    this.clearUserName$.next(value);
  }
  //
  // Progress Bar
  //
  public ProgressBar$ = new BehaviorSubject<any>(null);
  public getProgressBar() {
    return this.ProgressBar$.asObservable();
  }
  public setProgressBar(value) {
    this.ProgressBar$.next(value);
  }
  //
  // Projects List Layout
  //
  public layoutProject$ = new BehaviorSubject<any>(null);
  public getlayoutProject() {
    return this.layoutProject$.asObservable();
  }
  public setlayoutProject(value) {
    this.layoutProject$.next(value);
  }
  //
  // Fortify Hybrid Link
  //
  public linkFortifyLogout$ = new BehaviorSubject<any>(null);
  public getLinkFortifyLogout() {
    return this.linkFortifyLogout$.asObservable();
  }
  public setLinkFortifyLogout(value) {
    this.linkFortifyLogout$.next(value);
  }
  //
  // Microsoft Clarity
  //
  public clarityTracking() {
    (function (c, l, a, r, i, t, y) {
      c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
      t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
      t.onerror = function () { console.error('Failed to Load Microsoft Clarity'); };
      y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "npqosztbs2");
  }
}
