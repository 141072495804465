import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot, Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage/storage.service';
import { environment } from 'src/app/../environments/environment';
import { tap, catchError, switchMap, take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';
import { Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { AuthService } from 'src/app/features/auth.service';

import { AppState, GetUserResponseData } from 'src/app/models/interfaces';
import { ErrorService } from 'src/app/services/error/error.service';
import { LcpService } from '../../../services/lcp/lcp.service';
import * as AllActions from 'src/app/store/actions/index';

@Injectable({ providedIn: 'root' })
export class PasswordResolverService implements Resolve<{ username: string }> {
  constructor(
    private actions$: Actions,
    private error: ErrorService,
    private http: HttpClient,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<AppState>,
    private storage: StorageService,
    private lcp: LcpService,
    private auth: AuthService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let username = route.params.username;
    console.log('password-resolver', username);

    this.store.dispatch(AllActions.setBusyMessage({ message: 'Checking Username' }));

    return this.http.post<GetUserResponseData>(`${environment.webAppUrl}/api/user`, { id: username })
      .pipe(
      take(1),
      tap(() => this.store.dispatch(AllActions.setBusyMessage({ message: '' }))),
      switchMap((response: GetUserResponseData) => {
        //
        // what comes back on username call.
        this.storage.remove('auth-route');
        this.storage.setItem('auth-route', 'non-tpa');
        this.storage.setItem('tpa-login', false);
        console.log('HTTP.../api/user/${username}' + JSON.stringify(response));

        if (response.UsesFortify) {
          console.log(`%cUses Fortify: ${response.UsesFortify}`, "background-color: green; color: white; padding: 7px");
        } else {
          console.log(`%cUses Fortify: ${response.UsesFortify}`, "background-color: red; color: black; padding: 7px");
        }
        //
        // Suppport
        //
        if (response.IsSupportUser) {
          this.store.dispatch(AllActions.loginStartLcptracker({ username: "" }));
          return this.actions$.pipe(ofType(AllActions.loginSuccessLcptracker), take(1));
        }

        if (response.UsesFortify) {
          this.auth.loginCodeTwoUserPortal(username);
        }

        // non-fortify also needs this for the password screen.
        return of({ username });
      }),
      catchError(error => {
        console.log(error);
        let err = this.error.returnError('failed_usernameGet');
        this.snackBar
          .open(err, 'OK', { duration: 10000, verticalPosition: "top" })
          .onAction()
          .subscribe(() => {
            // Keep this here for when you want to "undo" a functionality.
          });
        this.store.dispatch(AllActions.setBusyMessage({ message: '' }));
        this.router.navigate(['/login']);
        return of({ username });
      }),
      take(1),
    );
  }
}
