import { createAction, props } from '@ngrx/store';
import { OAuthEvent } from 'angular-oauth2-oidc';
import { OrgCode } from 'src/app/models/interfaces';

//
// auth
//
export const setIdToken = createAction('[auth] setIdToken', 
    props<{ idToken: string }>()
    );
export const setUsername = createAction('[auth] setUsername', 
    props<{ username: string }>()
    );
export const setClaims = createAction('[auth] setClaims', 
    props<{ claims: object }>()
    );
export const setUsedMFA = createAction('[auth] setUsedMfa', 
    props<{ usedMFA: boolean }>()
    );
export const validateTokenStart = createAction('[auth] validateTokenStart', 
    props<{ token: string }>()
    );
export const validateTokenFail = createAction('[auth] validateTokenFail', 
    props<{ errorMessage: string }>()
    );
export const setTpaOrgCodes = createAction('[auth] setTpaOrgCodes', 
    props<{ tpaOrgCodes: string }>()
    );
export const setOrgCodes = createAction('[auth] setOrgCodes', 
    props<{ orgCodes: any }>()
    );
//
// fortifyAuth
//
export const logoutFortify = createAction('[fortify/auth] logout');
export const serviceFortifyOauth = createAction('[fortify/auth] serviceFortifyOauth', 
    props<{ oauthevent: OAuthEvent }>()
    );
export const loginStartFortify = createAction('[fortify/auth] loginStart', 
    props<{ username: string }>()
    );
export const preLogin = createAction('[fortify/auth] preLogin',    
    props<{ username: string }>()
    );
export const loginSuccessFortify = createAction('[fortify/auth] loginSuccess',
    props<{ idToken: string; claims: any; username: string; usedMFA: boolean; orgCodes: OrgCode[]; tpaOrgCodes: string; }>()
    );
export const authFortify = createAction('[fortify/auth] auth', 
    props<{ redirect: boolean }>()
    );
export const authEventFortify = createAction('[fortify/auth] authEvent', 
    props<{ oauthevent: OAuthEvent; redirect: boolean }>()
    );
export const authServiceEventFortify = createAction('[fortify/auth] authServiceEvent', 
    props<{ oauthevent: OAuthEvent }>()
    );
    





//
// lcptrackerAuth
//
export const logoutLcptracker = createAction('[lcptracker/auth] logout');

export const loginStartLcptracker = createAction('[lcptracker/auth] loginStart', 
    props<{ username: string }>()
    );
export const loginSuccessLcptracker = createAction('[lcptracker/auth] loginSuccess',
    props<{ idToken: string; claims: any; username: string; usedMFA: boolean; }>()
    );
export const authServiceEventLcptracker = createAction('[lcptracker/auth] authServiceEvent', 
    props<{ oauthevent: OAuthEvent }>()
    );
export const authLcptracker = createAction('[lcptracker/auth] auth', 
    props<{ redirect: boolean }>()
    );
export const authEventLcptracker = createAction('[lcptracker/auth] authEvent', 
    props<{ oauthevent: OAuthEvent; redirect: boolean }>()
    );