import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import * as AllActions from 'src/app/store/actions/index';
import { FullContractorResponse, AppState } from 'src/app/models/interfaces';
import { environment } from 'src/environments/environment';

const TIME_TO_AUTO_LOG_OUT_IN_MILI_SECS = 30000;
@Component({
  selector: 'app-response-banner',
  templateUrl: './response-banner.component.html',
  styleUrls: ['./response-banner.component.scss']
})
export class ResponseBannerComponent implements OnInit {
  @Input() responseFullContractor: FullContractorResponse = null;
  @Input() usesFortify: boolean = null;

  @Output() tryFormAgain = new EventEmitter<void>();

  imageUrl: string = '';
  title: string = '';
  subtitle: string = '';

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) { }

  ngOnInit() {
    this.initBannerWording();
  }

  /**
   * Initializes the banner texts based on the server response
   */
  initBannerWording(): void {
    this.imageUrl = (this.responseFullContractor.Success) ? './assets/success.svg' : './assets/error.svg';
    this.title = (this.responseFullContractor.Success) ? 'Success!' : 'Error!';
    this.subtitle = this.responseFullContractor.Message;
  }

  /**
   * Emits an event to the parent component to allow the user fill the form again
   */
  tryFormAgainEmitter(): void {
    this.tryFormAgain.emit();
  }

  /**
   * Logs the user out from Fortify to end the session and let the user use its new email to login
   */
  logoutFromFortify(): void {
    this.store.dispatch(AllActions.logoutFortify());
  }

  /**
   * Makes the redirection to the login page
   */
  goToLogin(): void {
    this.router.navigate(['/login']);
  }

  /**
   * Redirects the user to Fortify in order to complete the onboarding process
   */
  goToFortify(): void {
    window.location.href = `${environment.fortifyUrl}/Default/Account/Login`;
  }
}
