/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fortify-impersonate.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./fortify-impersonate.component";
var styles_FortifyImpersonateComponent = [i0.styles];
var RenderType_FortifyImpersonateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FortifyImpersonateComponent, data: {} });
export { RenderType_FortifyImpersonateComponent as RenderType_FortifyImpersonateComponent };
export function View_FortifyImpersonateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["fortify-impersonate works!"]))], null, null); }
export function View_FortifyImpersonateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fortify-impersonate", [], null, null, null, View_FortifyImpersonateComponent_0, RenderType_FortifyImpersonateComponent)), i1.ɵdid(1, 114688, null, 0, i2.FortifyImpersonateComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FortifyImpersonateComponentNgFactory = i1.ɵccf("fortify-impersonate", i2.FortifyImpersonateComponent, View_FortifyImpersonateComponent_Host_0, {}, {}, []);
export { FortifyImpersonateComponentNgFactory as FortifyImpersonateComponentNgFactory };
