import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emailHighlighter'
})
export class EmailHighlighterPipe implements PipeTransform {

  transform(value: string): string {

    if (!value) {

      return '';

    }

    const words = value.split(/\s+/); // Split string into words using whitespace as a separator

    // Process each word and check if it's an email

    const processedWords = words.map((word) => {

      if (this.isEmail(word)) {

        return `<strong>${word}</strong>`;

      }

      return word;

    });

    return processedWords.join(' '); // Join the processed words back into a single string with spaces

  }

  private isEmail(word: string): boolean {

    // Basic email validation using a simple regex

   const emailRegex = /^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.)?$/;

    return emailRegex.test(word);

  }

}
