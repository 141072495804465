<div class="purchase-banner">
  <h3>We haven't found a license<span class="highlight">*</span>.</h3>
  <p class="tagline">Purchase a license by clicking below in order to access your accounts.</p>

  <div class="contractors-wrapper">
    <span class="contractors">CONTRACTORS</span>
    <div *ngFor="let contractorAccount of contractorAccounts; let index = index" class="cta-row">
      <p>{{contractorAccount.name}}</p>
      <button type="button" class="btn btn-success purchase" (click)="purchaseLicense(contractorAccount.id, contractorAccount.name, index)" [disabled]="requestStatus.isSendingRequest" #purchaseALicenseButton>
        Purchase a license
      </button>
    </div>
  </div>

  <hr>

  <p class="info"><span class="highlight">*</span> If you have purchased your license already no more actions are required. We are processing the request and the status
    will be updated automatically.
  </p>
  <p class="info"><span class="highlight">*</span> If you think this is a mistake please contact support by emailing at support@lcptracker.com or by phone at (714) 669-0052 and select OPTION 4.</p>
</div>
