import { createReducer, Action, on } from '@ngrx/store';
import * as fromAuthConfig from 'src/app/auth/lcptracker/auth.config';
import { stateLcptrackerAuth } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';


const initialState: stateLcptrackerAuth = {
    authConfig: fromAuthConfig.authConfig,
    idToken: null,
    username: null,
    claims: null,
    usedMFA: false,
};

const authReducer = createReducer(
    initialState,
    on(AllActions.loginSuccessLcptracker, (state, action) =>
        ({ ...state, idToken: action.idToken, claims: action.claims, username: action.username, usedMFA: action.usedMFA })),
);

export function reducer(state: stateLcptrackerAuth | undefined, action: Action) {
    return authReducer(state, action);
}
