import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppState } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';
//
// ngrx / rxjs
//
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared/shared.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'app-fortify-redirect',
  templateUrl: './fortify-redirect.component.html',
  styleUrls: ['./fortify-redirect.component.css']
})
export class FortifyRedirectComponent implements OnInit {

  constructor(
    private shared : SharedService,
    private storage : StorageService,
    private store: Store<AppState>, 
    private route: ActivatedRoute
  ) { 
    this.shared.setlayoutProject(false);
    this.shared.setLinkFortifyLogout(true);
    this.storage.remove('auth-route');
    this.storage.setItem('auth-route', 'redirect');
  }

  ngOnInit() {
    console.log('FortifyRedirectComponent ngOnInit');
    console.log('route: ', this.route);
    this.store.dispatch(
      AllActions.authFortify(
        { redirect: false }
      )
    );
    this.store.select('fortifyAuth')
      .pipe(
        filter(state => !!state.idToken),
        take(1)
      )
      .subscribe(state => {
        console.log('auth in FortifyRedirectComponent', state);
        this.store.dispatch(AllActions.setIdToken({ idToken: state.idToken }));
        this.store.dispatch(AllActions.validateTokenStart({ token: state.idToken }));
      });
  }
}
