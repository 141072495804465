import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  storage = localStorage;
  constructor() { }
  //
  // General Storage 
  //
  public isExisted(key) {
    return this.storage.getItem(key) !== null;
  }
  public clearAll() {
    this.storage.clear();
  }
  public setItem(key, data) {
    this.storage.setItem(key, JSON.stringify(data));
    return data;
  }
  public getItem(key) {
    let result: any;
    if (this.isExisted(key)) {
      if(this.storage.getItem(key) != undefined){
        result = this.storage.getItem(key);
      } 
    }
    return result;
  }
  public remove(key) {
    let result = false;
    if (this.isExisted(key)) {
      this.storage.removeItem(key);
      result = true;
    }
    return result;
  }


}
