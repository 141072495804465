import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared/shared.service';
import { AppState } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';
//
// ngrx / rxjs
//
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';
import { ContentfulService } from 'src/app/services/contentful/contentful.service';
import { StorageService } from 'src/app/services/storage/storage.service';

@Component({
  selector: 'fortify-direct',
  templateUrl: './fortify-direct.component.html',
  styleUrls: ['./fortify-direct.component.scss']
})
export class FortifyDirectComponent implements OnInit {
  usernameLabel; string;
  idToken : string;
  fortifyDirect : string;

  //
  // TODO:: only on 'fortify-direct'
  //
  errorClose : boolean = false;
  showProgressBar : boolean = false;
  projectLayout : boolean = false;
  showFortifyLink : boolean = false;
  random : number = 0;
  img : string;
  imgId : string;
  imgLabel: string;
  imgLink : string;
  imageURLAsset: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private contentful : ContentfulService,
    private store: Store<AppState>,
    private shared : SharedService,
    private storage : StorageService
  ) {
    this.shared.setLinkFortifyLogout(true);
    this.shared.setProgressBar(true);
    this.storage.remove('auth-route');
    this.storage.setItem('auth-route', 'direct');
  }

  ngOnInit() {
    console.log('Fortify **Direct** ngOnInit');
    console.log('route: ', this.route);
    // const route = this.route.snapshot.url[0].path;
    const route = window.location.pathname;
    console.log('snapshot params : ' + JSON.stringify(route));
    this.fortifyDirect = route;
    if (this.fortifyDirect = '/login/fortify-direct' ){
      this.contentful.getAd().subscribe(data => {
        if ( data != (null || undefined)) {
          this.img = data.items[0].fields.image;
          this.imgId = data.items[0].fields.id;
          this.imgLabel = data.items[0].fields.label;
          this.imgLink = data.items[0].fields.link;
          this.imageURLAsset = data.items[0].fields.asset.fields.file.url;
        }
      })
      this.shared.getError().subscribe(data => {
        if ( data != (null || undefined)) {
          this.errorClose = data;
        }
      })
      this.shared.getLinkFortifyLogout().subscribe(data => {
        if ( data != (null || undefined)) {
          this.showFortifyLink = data;
        }
      })
      this.shared.getProgressBar().subscribe(data => {
        if ( data != (null || undefined)) {
          this.showProgressBar = data;
        }
      })
      this.shared.getlayoutProject().subscribe(data => {
        if ( data != (null || undefined)) {
          this.projectLayout = data;
        }
      })
    }
    else{

    }





    //
    // Grab the incoming Claim.. and find out for username.
    //
    // this.store.dispatch(AllActions.authFortify({ redirect: false}))
    this.store.dispatch(AllActions.preLogin({ username: '' }));
    // TODO :: Go through Resolvers, create new workflow, and Change authServiceEventFortify
    //
    this.store.select('fortifyAuth')
      .pipe(
        filter(state => !!state.idToken),
        take(1)
      )
      .subscribe(state => {
        console.log('auth in FortifyDirectComponent', state);
        this.usernameLabel = state.username;
        this.idToken = state.idToken;
        this.shared.setProgressBar(false);

        //
        // Check to see the Logout Flag ... *if so Logout!
        //
        if (this.storage.isExisted('logout-fortify')) {
          this.logoutFortify();
        }
        else {
          //
          // !!! This fails from NON-SSO members through Fortify !!!
          //
          this.store.dispatch(AllActions.setIdToken({ idToken: this.idToken }));
          this.store.dispatch(AllActions.validateTokenStart({ token: this.idToken }));
          //
          // Actions to observe workflow (more optional calls through oidc)
          //
          // this.store.dispatch(AllActions.loginStartFortify({ username: state.username}))
          // this.store.dispatch(AllActions.setIdToken({ idToken: state.idToken }));
          // this.store.dispatch(AllActions.setUsername({ username: state.username}));
          // this.store.dispatch(AllActions.setUsedMFA({ usedMFA: state.usedMFA}));
          // this.store.dispatch(AllActions.setClaims({ claims: state.claims}));;
        }
      });
  }


  continue() {
    // this.shared.setProgressBar(true);
    // this.router.navigate(['/fortify-direct','account']);
    //
    // LcptrackerAuth Situation.
    // TODO :::::: We need a proper error handling for 500's.
    //
    this.store.dispatch(AllActions.setIdToken({ idToken: this.idToken }));
    this.store.dispatch(AllActions.validateTokenStart({ token: this.idToken }));
  }
  logoutFortify() {
    //
    // upon /fortify-direct that flag to logout will not be addressed.
    // we have connection and know it's a fortify user.
    if ( this.storage.isExisted('logout-fortify')) {
      this.storage.remove('logout-fortify');
    }
    this.store.dispatch(AllActions.logoutFortify());
  }




}
