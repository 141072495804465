/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./top-page.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./top-page.component";
var styles_TopPageComponent = [i0.styles];
var RenderType_TopPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TopPageComponent, data: {} });
export { RenderType_TopPageComponent as RenderType_TopPageComponent };
export function View_TopPageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { _selector: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["TopPageComponent", 1]], null, 0, "i", [["class", "fa fa-angle-up back-top ba-back-top"], ["id", "TopPage"], ["title", "Back to Top"]], null, null, null, null, null))], null, null); }
export function View_TopPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "top-page", [], null, [[null, "click"], ["window", "scroll"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._onClick() !== false);
        ad = (pd_0 && ad);
    } if (("window:scroll" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1)._onWindowScroll() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_TopPageComponent_0, RenderType_TopPageComponent)), i1.ɵdid(1, 114688, null, 0, i2.TopPageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TopPageComponentNgFactory = i1.ɵccf("top-page", i2.TopPageComponent, View_TopPageComponent_Host_0, { position: "position", showSpeed: "showSpeed", moveSpeed: "moveSpeed" }, {}, []);
export { TopPageComponentNgFactory as TopPageComponentNgFactory };
