import { Injectable } from '@angular/core';
import { AppState } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';
//
// ngrx / rxjs
//
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap, switchMap, map, catchError, takeWhile, timeout } from 'rxjs/operators';
import { of } from 'rxjs';
//
// oidc services
//
import * as fromAuthConfig from 'src/app/auth/lcptracker/auth.config';
import { OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { SignatureValidationHandler } from 'src/app/services/oidc/signature-validation-handler';
import { LcptrackerOAuthService } from 'src/app/auth/lcptracker/lcptracker-oauth.service';


@Injectable()
export class LcpTrackerAuthEffects {
    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private lcptrackerOAuthService: LcptrackerOAuthService) { }

    login$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.loginStartLcptracker),
            tap(action => console.log('login$', action)),
            switchMap(action => {
                this.lcptrackerOAuthService.configure(fromAuthConfig.authConfig);
                this.lcptrackerOAuthService.customQueryParams = { domain_hint: 'lcptracker.com', login_hint: action.username };
                this.lcptrackerOAuthService.tokenValidationHandler = new SignatureValidationHandler();
                this.lcptrackerOAuthService
                    .loadDiscoveryDocument(fromAuthConfig.discoveryDocumentUrl)
                    .then(() => this.lcptrackerOAuthService.tryLoginImplicitFlow());

                this.lcptrackerOAuthService.responseType = 'id_token';
                this.lcptrackerOAuthService.scope = 'openid email profile';
                this.lcptrackerOAuthService.loginUrl = fromAuthConfig.loginUrl;
                return this.lcptrackerOAuthService.events
                    .pipe(
                        tap(event => console.log('lcptrackerAuth$ event', event)),
                        map(event => {
                            return AllActions.authServiceEventLcptracker({ oauthevent: event });
                        })
                    );
            })
        )
    );

    auth$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.authLcptracker),
            tap(action => {
                console.log('auth$', action);
                this.lcptrackerOAuthService.configure(fromAuthConfig.authConfig);
                this.lcptrackerOAuthService.customQueryParams = { domain_hint: 'lcptracker.com' };
                this.lcptrackerOAuthService.tokenValidationHandler = new SignatureValidationHandler();
                this.lcptrackerOAuthService
                    .loadDiscoveryDocument(fromAuthConfig.discoveryDocumentUrl)
                    .then(() => this.lcptrackerOAuthService.tryLoginImplicitFlow());

                this.lcptrackerOAuthService.responseType = 'id_token';
                this.lcptrackerOAuthService.scope = 'openid email profile';
                this.lcptrackerOAuthService.loginUrl = fromAuthConfig.loginUrl;
                this.lcptrackerOAuthService.events.pipe(
                    tap(event => {
                        console.log('lcptrackerOAuthService$ event', event);
                        if (this.lcptrackerOAuthService.hasValidIdToken()) {
                            const claims = this.lcptrackerOAuthService.getIdentityClaims() as fromAuthConfig.Claims;
                            this.store.dispatch(AllActions.loginSuccessLcptracker({
                                idToken: this.lcptrackerOAuthService.getIdToken(),
                                claims,
                                username: claims.upn,
                                usedMFA: claims.amr.findIndex(claim => claim === 'mfa') !== -1,
                            }));
                        }
                    }),
                    takeWhile(() => !this.lcptrackerOAuthService.hasValidIdToken()),
                    timeout(2000),
                ).subscribe();
            })
        ), { dispatch: false }
    );

    authServiceEvent$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.authServiceEventLcptracker),
            tap(action => console.log('lcptrackerAuthServiceEvent', action)),
            switchMap(action => {
                if (action.oauthevent.type === 'discovery_document_loaded') {
                    const successEvent = action.oauthevent as OAuthSuccessEvent;
                    if (successEvent.info !== null) {
                        this.lcptrackerOAuthService.tryLoginImplicitFlow().then(value => {
                            console.log('tryLoginImplicitFlow idToken', value, this.lcptrackerOAuthService.getIdToken());
                            if (!value) {
                                this.lcptrackerOAuthService.initImplicitFlow();
                            }
                        });
                        if (this.lcptrackerOAuthService.getIdToken() !== null) {
                            const claims = this.lcptrackerOAuthService.getIdentityClaims() as fromAuthConfig.Claims;
                            return of(AllActions.loginSuccessLcptracker({
                                idToken: this.lcptrackerOAuthService.getIdToken(),
                                claims,
                                username: claims.upn,
                                usedMFA: false, // claims.amr.findIndex(claim => claim === 'mfa') !== -1,
                            }));
                        }
                    }
                    return of(AllActions.loginSuccessLcptracker({
                        idToken: null,
                        claims: null,
                        username: null,
                        usedMFA: false,
                    }));
                } else if (action.oauthevent.type === 'token_received') {
                    const claims = this.lcptrackerOAuthService.getIdentityClaims() as fromAuthConfig.Claims;
                    return of(AllActions.loginSuccessLcptracker({
                        idToken: this.lcptrackerOAuthService.getIdToken(),
                        claims,
                        username: claims.upn,
                        usedMFA: claims.amr.findIndex(claim => claim === 'mfa') !== -1,
                    }));
                } else {
                    return of(AllActions.loginSuccessLcptracker({
                        idToken: 'token2',
                        claims: 'claims2',
                        username: 'action.username2',
                        usedMFA: true,
                    }));
                }
            })
        )
    );

    logout$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.logoutLcptracker),
            tap(action => {
                console.log('logout lcptracker', action);
                this.lcptrackerOAuthService.logOut(false);
            }),
        ), { dispatch: false }
    );
}
