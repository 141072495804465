/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./full-contractor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./full-contractor.component";
var styles_FullContractorV2Component = [i0.styles];
var RenderType_FullContractorV2Component = i1.ɵcrt({ encapsulation: 0, styles: styles_FullContractorV2Component, data: {} });
export { RenderType_FullContractorV2Component as RenderType_FullContractorV2Component };
export function View_FullContractorV2Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "full-contractor-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "footer", [], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_FullContractorV2Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-full-contractor-v2", [], null, null, null, View_FullContractorV2Component_0, RenderType_FullContractorV2Component)), i1.ɵdid(1, 114688, null, 0, i3.FullContractorV2Component, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FullContractorV2ComponentNgFactory = i1.ɵccf("app-full-contractor-v2", i3.FullContractorV2Component, View_FullContractorV2Component_Host_0, {}, {}, []);
export { FullContractorV2ComponentNgFactory as FullContractorV2ComponentNgFactory };
