import { createAction, props } from '@ngrx/store';

export const showAlert = createAction('[alert] showAlert', 
    props<{ message: string }>()
    );
export const setBusyMessage = createAction('[busy] setBusyMessage', 
    props<{ message: string }>()
    );








