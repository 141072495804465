import { forkJoin, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../../auth.service";
import * as i2 from "../../../services/lcp/lcp.service";
export class ProductsResolverService {
    constructor(auth, lcpService) {
        this.auth = auth;
        this.lcpService = lcpService;
    }
    resolve(route) {
        console.log('Fetching Accounts in route resolver...', route);
        //
        // When it's ready for the forkJoin... test more error handling
        //
        const claimsRBS$ = this.auth.rbsClaims().pipe(mergeMap(((claimData) => this.lcpService.parseToPlainObjectsJSONValues(claimData))));
        const account$ = this.lcpService.getAccounts().pipe(catchError(error => of(error)));
        return forkJoin([claimsRBS$, account$]);
    }
}
ProductsResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductsResolverService_Factory() { return new ProductsResolverService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.LcpService)); }, token: ProductsResolverService, providedIn: "root" });
