import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector    : 'app-button',
  templateUrl :  './button.component.html',
  styleUrls   : ['./button.component.scss'],
})
export class ButtonComponent {

  /**
   * This is the type of button being rendered
   */
  @Input()
  buttonType: 'primary' | 'secondary' | 'flat' | '' = 'primary';

  /**
   * Switches colors of the button
   */
  @Input()
  isNegative: boolean = false;

  /**
   * Sets a fixed width for the container of the button when the value is true and
   * sets the width to 100% when false so the button follows its parent width instead
   */
  @Input()
  isDemo: boolean = false;

  /**
   * Enables or disables button
   */
  @Input()
  isDisabled: boolean = false;

  /**
   * Button contents
   *
   * @required
   */
  @Input()
  label: string = 'Button';

  /**
   * Contains the prefix icon for the button
   */
  @Input()
  prefixIcon: string = '';

  /**
   * Contains the suffix icon for the button
   */
  @Input()
  suffixIcon: string = '';

  /**
   * Emits when the button was clicked
   */
  @Output()
  buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Emits when the enter key was pressed
   */
  @Output()
  enterKeyUp: EventEmitter<void> = new EventEmitter<void>();

  public get classes(): string[] {
    let buttonTypeClass = '';

    switch (this.buttonType) {
      case 'primary':
        buttonTypeClass = this.isNegative ? 'storybook-button--primary-negative'
                                          : 'storybook-button--primary';
        break;
      case 'secondary':
        buttonTypeClass = this.isNegative ? 'storybook-button--secondary-negative'
                                          : 'storybook-button--secondary';
        break;
      case 'flat':
        buttonTypeClass = this.isNegative ? 'storybook-button--flat-negative'
                                          : 'storybook-button--flat';
        break;
      default:
        break;
    }

    return ['storybook-button', buttonTypeClass];
  }

  emitButtonWasClicked(): void {
    this.buttonClicked.emit();
  }

  emitEnterKeyWasPressed(): void {
    this.enterKeyUp.emit();
  }

}
