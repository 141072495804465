import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import { DataSource, SelectionModel } from "@angular/cdk/collections";
import { MatSnackBar, MatTableDataSource } from "@angular/material";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { takeUntil } from "rxjs/operators";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { ConfirmationDialogService } from "../../../shared/dialog/services/confirmation-dialog.service";
import { ContractorSelectorAccountService } from "./service/contractor-selector-account.service";
import { SharedService } from "src/app/services/shared/shared.service";
import { FullContractorAccount, FullContractorAccountResponse, GetAccountsFullContractorResponseData} from "./interfaces/database-interface";
import { customSnackBarMessages } from "src/app/components/shared/custom-snack-bar/custom-snackbar-messages";

@Component({
  selector: "app-full-contractor-account-selector",
  templateUrl: "./account-selector.component.html",
  styleUrls: ["./account-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullContractorAccountSelectorComponent
  implements OnInit, OnDestroy {
  @ViewChild(CdkVirtualScrollViewport, { static: false })
  viewPort: CdkVirtualScrollViewport;

  checkboxLegalAgreements = false;
  isCountButtonToConfirm = false;
  userEmail: string;
  columnTitles: string[] = ["select", "position", "database"];
  labelSelectAll: string;
  selectedItems: number;
  userAccounts: number;

  itemsDataSource = new AccountsDataSource(
    this._accountFullContractorService,
    this._router,
    this._snackBar
  );
  dataItemsSource: MatTableDataSource<FullContractorAccount>;

  rowsSelection = new SelectionModel<FullContractorAccount>(true, []);
  loadingSubject$ = this._accountFullContractorService.loadingSubject$;
  loadingSendInformationSubject$ =
    this._accountFullContractorService.loadingSubject$;

  messageBackEnd: string;
  showMessageFromBackEnd$ = new BehaviorSubject(false);
  _subscriptionToDestroy$ = new Subject();

  constructor(
    private _dialogService: ConfirmationDialogService,
    private _router: Router,
    private _accountFullContractorService: ContractorSelectorAccountService,
    private _snackBar: MatSnackBar,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.isCountButtonToConfirm = true;
    const user = JSON.parse(
      localStorage.getItem("formFullContractor")
    );
    this.userEmail = user.email;
    this._accountFullContractorService.dataCacheStream$
      .pipe(takeUntil(this._subscriptionToDestroy$))
      .subscribe((items: GetAccountsFullContractorResponseData[]) => {
        this.dataItemsSource = new MatTableDataSource(items["Accounts"]);
        this.dataItemsSource.data.sort(this.compareName);
        this.userAccounts = this.dataItemsSource.data.length;
      });
    if (JSON.parse(localStorage.getItem('IsEmailContractorUser'))) {
      this._router.navigate(['../login']);
    }
  }

    // Sort on Name
    compareName(a: any, b: any) {
      if (a.Name < b.Name) return -1;
      if (a.Name > b.Name) return 1;
      return 0;
    }

  /** Whether the number of selected elements matches the total number of rows. **/
  isAllSelected() {
    const selectedCount = this.rowsSelection.selected.length;
    const rowsCount = this.dataItemsSource.data.length;
    this.selectedItems = this.rowsSelection.selected.length;

    if (selectedCount) {
      if (this.checkboxLegalAgreements) {
        this.isCountButtonToConfirm = false;
      } else {
        this.isCountButtonToConfirm = true;
      }
    } else {
      this.isCountButtonToConfirm = true;
    }
    return selectedCount === rowsCount;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. **/
  toggleAllRows() {
    console.log(this.rowsSelection);
    if (this.isAllSelected()) {
      this.rowsSelection.clear();
      return;
    }

    this.rowsSelection.select(...this.dataItemsSource.data);
  }

  /** The label for the checkbox on the passed row **/
  checkboxColumnTitle(row?: any): string {
    if (!row) {
      return `${this.isAllSelected()
        ? (this.labelSelectAll = "Unselect all")
        : (this.labelSelectAll = "Select all")
        }`;
    }
    return `${this.rowsSelection.isSelected(row) ? "unselect" : "select"} row ${row.id + 1
      }`;
  }

  confirmSelectionToSendDB() {
    const selectedCount = this.rowsSelection.selected.length;
    const selectedItems = this.rowsSelection.selected;

    this._accountFullContractorService.loadingSendInformationSubject.next(true);
    this._accountFullContractorService.loadingSubject.next(true);
    this.isCountButtonToConfirm = true;
    console.log("ITEM COUNT SELECTED", selectedCount);
    console.log("ITEM SELECTED", selectedItems);
    this._dialogService
      .confirmationDialog(
        "Confirm selection",
        `This action can't be undone,
       By clicking Confirm you are Accepting these are the only accounts you
       must have access to with your email Username.  You will no longer have access
       to unselected accounts.`,
        "Accept",
        "Cancel"
      )
      .afterClosed()
      .subscribe((result: boolean): void => {
        if (result) {
          const userDataSet = JSON.parse(
            localStorage.getItem("formFullContractor")
          );
          if (userDataSet !== null) {
            userDataSet.UserName =
              this._accountFullContractorService.userNameStream.value;
            this._accountFullContractorService
              .sendInformationAndAccountsFromFullContractorSelector(
                userDataSet,
                selectedItems
              )
              .pipe(takeUntil(this._subscriptionToDestroy$))
              .subscribe(
                (
                  responseFullContractorAccount: FullContractorAccountResponse
                ): void => {
                  console.log("Label Response", responseFullContractorAccount);
                  this.showMessageFromBackEnd$.next(
                    responseFullContractorAccount.Success
                  );
                  if (responseFullContractorAccount.Success === true) {
                    this.messageBackEnd = responseFullContractorAccount.Message;
                  } else {
                    this.showMessageFromBackEnd$.next(
                      responseFullContractorAccount.Success
                    );
                    const messages = responseFullContractorAccount.Message.includes('This email address has already been registered for a different Contractor License/Phone Number (Username).') ? customSnackBarMessages.emailRegistered :responseFullContractorAccount.Message;
                    this.sharedService.openCustomSnackBar(messages)
                    this._accountFullContractorService.loadingSendInformationSubject.next(
                      false
                    );
                    this._accountFullContractorService.loadingSubject.next(
                      false
                    );
                    this.isCountButtonToConfirm = false;
                    this._router.navigate(["v2/full-contractor"]);
                  }
                }
              );
          } else {
            this.showMessageFromBackEnd$.next(false);
            this._router.navigate(["v2/full-contractor"]);
          }
        } else {
          this.showMessageFromBackEnd$.next(false);
          this._accountFullContractorService.loadingSendInformationSubject.next(
            false
          );
          this._accountFullContractorService.loadingSubject.next(false);
          this.isCountButtonToConfirm = false;
        }
      });
  }

  cancelAccountSelection(): void {
    this._router.navigate(["v2/full-contractor"]);
  }

  acceptLegalAgreementsChange(): void {
    this.isCountButtonToConfirm = this.checkboxLegalAgreements;
  }

  returnToLoginPage(): void {
    this._router.navigate(["../login"]);
  }

  goToUserPortal(): void {
    window.location.href = environment.userPortal;
  }

  get inverseOfTranslation(): string {
    if (!this.viewPort) {
      return '-0px';
    }
    const offset = this.viewPort.getOffsetToRenderedContentStart();

    return `-${offset}px`;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    localStorage.removeItem("formFullContractor");
    this._subscriptionToDestroy$.next(true);
    this._subscriptionToDestroy$.complete();
  }
}

export class AccountsDataSource extends DataSource<FullContractorAccount> {
  private items: FullContractorAccount[];
  private dataStream = new BehaviorSubject<FullContractorAccount[]>([]);
  private readonly _subscription = new Subscription();

  constructor(
    private _contractorSelectorAccountService: ContractorSelectorAccountService,
    private _router: Router,
    private _snackBar: MatSnackBar
  ) {
    super();
  }

  connect() {
    this._subscription.add(
      this._contractorSelectorAccountService.getData().subscribe(
        (items: GetAccountsFullContractorResponseData[]): void => {
          if (items['Accounts'].length) {
            this.items = items['Accounts'];
            this.dataStream.next(this.items);
            this._contractorSelectorAccountService.loadingSubject.next(false);
          } else {
            this.items = [];
            this._router.navigate(["../login"]);
          }
        },
        (error) => {
          setTimeout(() => {
            this._router.navigate(["../login"]);
          }, 1000);
          this._snackBar.open(
            "Apologies for the issue retrieving your accounts. Please try again or contact support for assistance.",
            "Ok",
            {
              verticalPosition: "top",
              duration: 5000,
            }
          );
        }
      )
    );

    return this.dataStream;
  }

  disconnect(): void {
    this._subscription.unsubscribe();
  }
}
