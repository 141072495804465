import { of } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { flatMap, map } from 'rxjs/operators';
import { Account } from 'src/app/models/account.model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class LcpService {
    constructor(http) {
        this.http = http;
        this.token = '';
    }
    impersonateUser(action) {
        return this.http.put(`${environment.webAppUrl}/api/login`, {
            Username: action.username,
            OverrideSupport: action.overrideSupport
        }, { withCredentials: true });
        //
        // Username: string;
        // SupportUsername: string;
        // Accounts: Account[];
        //
    }
    validateToken(token) {
        return this.http.post(`${environment.webAppUrl}/api/login`, { Token: token }, { withCredentials: true });
        //
        // username: action.username,
        // isSupportUser: response.IsSupportUser
        //
    }
    validatePassword(action) {
        return this.http.post(`${environment.webAppUrl}/api/login`, {
            Username: action.username,
            Password: action.password,
        }, { withCredentials: true });
        //
        // username: action.username,
        // isSupportUser: response.IsSupportUser
        //
    }
    /**
     * Gets user's information as UsesFortify and IsSupportUser
     * @param user
     * @returns user's information as UsesFortify and IsSupportUser
     */
    validateUserName(user) {
        return this.http.post(`${environment.webAppUrl}/api/user`, { id: user });
    }
    getUserName() {
        return this.http.get(`${environment.webAppUrl}/api/user`, { withCredentials: true });
        //
        // username: response.Username,
        // supportUsername: response.SupportUsername,
        // isSupportAdmin: response.IsSupportAdmin
        //
    }
    getClaims(orgId, apiScope) {
        const options = { headers: this.getHeaders(orgId, apiScope) };
        return this.http.get(`${environment.webAppUrl}/api/claims`, options);
    }
    getHeaders(orgId, apiScope) {
        //
        // Grab the current token
        //
        // this.store.select('fortifyAuth')
        // .pipe(
        //   take(1)
        // ).subscribe(state => {
        //   // a = state.accounts;
        //   this.token = state.idToken;
        // });
        //
        // Construct the header to use values.
        //
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            //'Authorization': `Bearer ${this.token}`,
            // 'Scope': scope,
            //
            //  TODO ::
            //
            'orgId': orgId,
            'apiScope': apiScope,
        });
        return headers;
    }
    getAccounts() {
        return this.http.get(`${environment.webAppUrl}/api/accounts`, { withCredentials: true });
        //
        // Username: string;
        // SupportUsername: string;
        // Accounts: Account[];
        //
    }
    /**
     * Makes the API request to send the selected account and the list of accounts
     * @param orgCode the selected account code
     * @param orgCodes the list of filtered account codes based on the rules to be displayed in LCPTracker
     * @returns
     */
    setOrgCode(orgCode, orgCodes, roleId) {
        const mappedAccounts = orgCodes.map((org) => {
            return new Account(org.OrgCode, org.Name, org.FortifyEnabled, org.RoleId);
        });
        return this.http.post(`${environment.webAppUrl}/api/accounts`, { orgCode, orgCodes: JSON.stringify(mappedAccounts), roleId }, { withCredentials: true });
    }
    /**
     * Calls the RBS Claims endpoint
     * @param authenticationTokenFromFortify
     * @returns An array of RBS claims
     */
    getRBSClaims(authenticationTokenFromFortify) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authenticationTokenFromFortify}`,
        });
        const options = {
            headers
        };
        return this.http.get(`${environment.fortifyAPIUrl}/TrackerSupport/RbsClaims/GetExtendedIdentityClaims`, options)
            .pipe(map(response => response.data), flatMap((claimData) => this.parseToPlainObjectsJSONValues(claimData)));
    }
    /**
     * Converts to plain objects the strigyfied response from the RBS API
     * @param claims
     * @returns Plain RBS values objects
     */
    parseToPlainObjectsJSONValues(claims) {
        claims.forEach(claim => {
            if (this.isJson(claim.value)) {
                claim.value = JSON.parse(claim.value);
            }
        });
        return of(claims);
    }
    /**
     * Checks if a string is a stringified JSON
     */
    isJson(string) {
        try {
            const obj = JSON.parse(string);
            if (obj && typeof obj === `object`) {
                return true;
            }
        }
        catch (err) {
            return false;
        }
        return false;
    }
    /**
     * Makes a request to LCP to link a professional email with a contractor license
     * @param fullContractor
     * @returns
     */
    enableFullContractorUser(fullContractor) {
        return this.http.post(`${environment.webAppUrl}/api/fullContractorUser`, {
            UserName: fullContractor.UserName,
            FirstName: fullContractor.FirstName,
            LastName: fullContractor.LastName,
            PhoneNumber: fullContractor.PhoneNumber,
            Email: fullContractor.Email,
        }, { withCredentials: true });
    }
}
LcpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LcpService_Factory() { return new LcpService(i0.ɵɵinject(i1.HttpClient)); }, token: LcpService, providedIn: "root" });
