import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { LcpService } from 'src/app/services/lcp/lcp.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth.service';

interface ValidateTokenResponse {
  Success: boolean;
  IsSupportUser: boolean;
  Message?: string;
}

@Component({
  selector: 'route-fortify-v2-callback',
  templateUrl: './fortify-v2-callback.component.html',
  styleUrls: ['./fortify-v2-callback.component.scss']
})
export class FortifyV2CallbackComponent implements OnInit {
  token: string;
  identity: any;
  email: string;
  username: string;
  validated: boolean = true;
  isLogout: boolean = false;
  subscription!: Subscription;
  validateSubscription!: Subscription;
  statusDescription: string = 'You will be redirected in a moment...';
  errorDescription: string = '';
  retryTimeout: ReturnType<typeof setTimeout> | null = null;  // Variable to store the timeout ID
  retryAttempts: number = 0; //Counter to track the number of attempts
  maxRetries: number = 10;    // Maximum number of retry attempts

  constructor(
    private auth: AuthService,
    private storage: StorageService,
    private lcpService: LcpService,
    private router: Router
  ) {
    //
    //  1. Check for Logout and Change in storage.
    //
    if (this.storage.isExisted('logout-fortify-v2')) {
      this.isLogout = this.storage.getItem('logout-fortify-v2');
    }

    // IMPORTANT: THIS WAS ADDED BASED ON THE #110505 AND 142283 PLEASE, DO NOT REMOVE OR YOU CAN BREAK THE APP.
    // Check if we are coming from the logout component by evaluating the sessionStorage item.
    if (sessionStorage.getItem('logout-from-tracker-pro')) {
      this.isLogout = true;
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////

    //
    //  2. Subscribe to Auth Service token$.
    // 
    this.subscription =
      this.auth.getToken$()
        .subscribe(res => {
          if (res != (undefined || null)) {
            //
            // Handle the logout when discovery occurs.
            //if (this.isLogout) {
            //  this.storage.remove('logout-fortify-v2');
            //  this.auth.logout();
            //  return;
            //}
            //
            // Token received ready to call api/login now.
            console.log('this.auth.getToken$() : ' + res)
            this.token = res;
            this.setPageOptions();
            this.validateToken();
          }
        })
    //
    //  3. check for existing AccessToken, if not discover the doc for the token.
    //  
    let accessToken = this.auth.getAccessToken();
    if (accessToken != null) {
      this.token = accessToken;
      this.setPageOptions();
      this.validateToken();
    }
    if (accessToken == null) {
      this.identity = this.auth.discoverCode();
    }


  }

  ngOnInit() {
    document.body.style.paddingTop = "0px"; // unit measured in Edge.
  }
  ngOnDestroy() {
    if (this.subscription) { this.subscription.unsubscribe(); }
    if (this.validateSubscription) { this.validateSubscription.unsubscribe(); }
  }

  setPageOptions(): void {
    this.identity = this.auth.getIdentityClaims();
    console.log(this.identity);
    this.email = this.identity['email'];
    console.log(this.email);
    this.username = this.identity['name'];
    console.log(this.username);
    this.statusDescription = sessionStorage.getItem('logout-from-tracker-pro') ? 'Logging out...' : 'looking into your account(s)...';
  }

  validateToken() {
    const token = this.auth.getAccessToken();
    console.log('token : ' + token); // if null we will need a retry/log back in policy
    this.validated = true; // to show them the loading screen section again.

    this.validateSubscription = this.lcpService.validateToken(token).subscribe((res: ValidateTokenResponse) => {
      console.log('validateUserName : ' + JSON.stringify(res));

      if (res.Success == true) {
        this.validated = true;
        this.retryAttempts = 0; //Reset retry counter on success
        if (res.Success === true && res.IsSupportUser === true) {

          // IMPORTANT: Based on requirement #110505, handle the logout scenario.
          // Check if we are coming from the logout component by evaluating the sessionStorage item.
          if (sessionStorage.getItem('logout-from-tracker-pro')) {
            // If true, log out the user.
            this.auth.logout();
          } else {
            // Otherwise, navigate to the accounts page.
            this.router.navigateByUrl('/auth/impersonate');
          }
        } else {

          // IMPORTANT: Based on requirement #110505, handle the logout scenario.
          // Check if we are coming from the logout component by evaluating the sessionStorage item.
          if (sessionStorage.getItem('logout-from-tracker-pro')) {
            // If true, log out the user.
            this.auth.logout();
          } else {
            // Otherwise, navigate to the accounts page.
            this.router.navigateByUrl('/auth/accounts');
          }
        }
      }
      else {
        this.retryValidation(res.Message);
      }
    },
      error => {
        console.log('validationUserName error :', error);
        this.validated = false;
        this.errorDescription = error.message;
        this.retryValidation(error.message);
      }
    );
  }

  retryValidation(errorMessage: string) {
    console.log('False Success Error : ' + errorMessage);

    // exits out before the setTimeOut to call again, and flags the error template
    if (this.retryAttempts >= this.maxRetries) {
      this.validated = false;
      this.errorDescription = errorMessage;
      return; //Skip if the previous operation is still running or max retries reached
    }
    // retryAttempts have not maxed out so Schedule the next validation attempt
    this.retryAttempts++;  // Increment retry counter
    this.retryTimeout = setTimeout(() => {
      this.validateToken(); //Retry validation
    }, 2000);
  }

  // Method to clear the retry timeout if necessary
  clearRetryTimeout() {
    if (this.retryTimeout != null) {
      clearTimeout(this.retryTimeout);
    }
  }

  goBack() {
    window.location.href = environment.userPortal;
  }
}
