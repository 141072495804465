import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { LcpService } from 'src/app/services/lcp/lcp.service';
import { AuthService } from '../../auth.service';
 
@Injectable({
  providedIn: 'root'
})
export class ProductsResolverService implements Resolve<any> {

  constructor(
    private auth: AuthService,
    private lcpService : LcpService
    ) {}
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    console.log('Fetching Accounts in route resolver...', route);

    //
    // When it's ready for the forkJoin... test more error handling
    //
    const claimsRBS$ = this.auth.rbsClaims().pipe(
      mergeMap(((claimData) => this.lcpService.parseToPlainObjectsJSONValues(claimData)))
    );
    const account$ = this.lcpService.getAccounts().pipe(
      catchError(error => of(error))
    );

    return forkJoin([claimsRBS$, account$])

  }

}