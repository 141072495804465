/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./response-banner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./response-banner.component";
import * as i4 from "@ngrx/store";
import * as i5 from "@angular/router";
var styles_ResponseBannerComponent = [i0.styles];
var RenderType_ResponseBannerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResponseBannerComponent, data: {} });
export { RenderType_ResponseBannerComponent as RenderType_ResponseBannerComponent };
function View_ResponseBannerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-lg btn-info"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.logoutFromFortify() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Logout"]))], null, null); }
function View_ResponseBannerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-lg btn-info"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.tryFormAgainEmitter() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Try again"]))], null, null); }
function View_ResponseBannerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResponseBannerComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["errorTemplate", 2]], null, 0, null, View_ResponseBannerComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.responseFullContractor.Success; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ResponseBannerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-lg btn-info"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToLogin() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Login"]))], null, null); }
function View_ResponseBannerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["exitingFortifyUser", 2]], null, 0, null, View_ResponseBannerComponent_6))], null, null); }
function View_ResponseBannerComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-lg btn-info"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.tryFormAgainEmitter() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Try again"]))], null, null); }
function View_ResponseBannerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResponseBannerComponent_5)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["errorTemplate", 2]], null, 0, null, View_ResponseBannerComponent_7))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.responseFullContractor.Success; var currVal_1 = i1.ɵnov(_v, 2); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ResponseBannerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "confirmation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["class", "icon"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["class", "confirmation-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "confirmation-subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResponseBannerComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["notFortifyTemplate", 2]], null, 0, null, View_ResponseBannerComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.usesFortify; var currVal_4 = i1.ɵnov(_v, 9); _ck(_v, 8, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageUrl; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.subtitle; _ck(_v, 5, 0, currVal_2); }); }
export function View_ResponseBannerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-response-banner", [], null, null, null, View_ResponseBannerComponent_0, RenderType_ResponseBannerComponent)), i1.ɵdid(1, 114688, null, 0, i3.ResponseBannerComponent, [i4.Store, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResponseBannerComponentNgFactory = i1.ɵccf("app-response-banner", i3.ResponseBannerComponent, View_ResponseBannerComponent_Host_0, { responseFullContractor: "responseFullContractor", usesFortify: "usesFortify" }, { tryFormAgain: "tryFormAgain" }, []);
export { ResponseBannerComponentNgFactory as ResponseBannerComponentNgFactory };
