/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alert-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./alert-dialog.component";
import * as i7 from "@angular/material/dialog";
var styles_AlertDialogComponent = [i0.styles];
var RenderType_AlertDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertDialogComponent, data: {} });
export { RenderType_AlertDialogComponent as RenderType_AlertDialogComponent };
export function View_AlertDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "alert-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["mat-dialog-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["mat-dialog-actions", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "btn btn-primary"], ["mat-button", ""], ["mat-dialog-close", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Close"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 5).disabled || null); var currVal_2 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_1, currVal_2); }); }
export function View_AlertDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-alert-dialog", [], null, null, null, View_AlertDialogComponent_0, RenderType_AlertDialogComponent)), i1.ɵdid(1, 49152, null, 0, i6.AlertDialogComponent, [i7.MatDialogRef, i7.MAT_DIALOG_DATA], null, null)], null, null); }
var AlertDialogComponentNgFactory = i1.ɵccf("app-alert-dialog", i6.AlertDialogComponent, View_AlertDialogComponent_Host_0, {}, {}, []);
export { AlertDialogComponentNgFactory as AlertDialogComponentNgFactory };
