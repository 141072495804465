import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatBottomSheetModule,
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatProgressBarModule,
} from "@angular/material";
import { ButtonComponent } from "./button/button.component";
import { DialogComponent } from "./dialog/dialog.component";
import { InputComponent } from "./input/input.component";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";
import { ConfirmationDialogService } from "./dialog/services/confirmation-dialog.service";
import { FullContractorAccountSelectorComponent } from "../components/full-contractor/account-selector/account-selector.component";
import { ContractorSelectorAccountService } from "../components/full-contractor/account-selector/service/contractor-selector-account.service";
import { OpenDialogComponent } from './dialog/open-dialog/open-dialog.component';
import { EmailHighlighterPipe } from './pipes/email-highlighter.pipe';
import { OtpEmailService } from '../components/full-contractor/otp-email/service/otp-email.service';
import { ContractorUserPortalComponent } from '../components/full-contractor/contractor-user-portal/contractor-user-portal.component';

@NgModule({
  declarations: [
    ButtonComponent,
    DialogComponent,
    InputComponent,
    SafeHtmlPipe,
    FullContractorAccountSelectorComponent,
    OpenDialogComponent,
    EmailHighlighterPipe,
    ContractorUserPortalComponent
  ],
  exports: [ButtonComponent, DialogComponent, InputComponent, OpenDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatBottomSheetModule,
    MatCheckboxModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    ReactiveFormsModule,
    ScrollingModule,
    MatProgressBarModule
  ],
  providers: [
  ConfirmationDialogService,
  ContractorSelectorAccountService,
  OtpEmailService
  ],
  entryComponents: [DialogComponent],
})
export class Net6SharedModule {}
