import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  @Input() title = 'Title';
  @Input() confirmButton = 'Accept';
  @Input() cancelButtton = 'Cancel';
  htmlContent!: string;


  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      body: string;
      confirmButton: string;
      cancelButtton: string;
    },
    public activeModal: MatDialogRef<DialogComponent>,
  ) { }

  ngOnInit(): void {
    this.title = this.data.title;
    this.htmlContent = this.data.body;
    this.confirmButton = this.data.confirmButton;
    this.cancelButtton = this.data.cancelButtton;
  }

  onConfirm(): void {
    this.activeModal.close(true);
  }

  onClose(): void {
    this.activeModal.close(false);
  }

}
