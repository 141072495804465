import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppState } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';
//
// ngrx / rxjs
//
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { tap, take, map } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss']
})
export class ImpersonateV2Component implements OnInit {
  impersonateForm: FormGroup;
  supportUsername: string;
  username: string;
  isSupportAdmin: boolean;
  overrideSupport: boolean;
  impersonateSubscription: Subscription;


  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private fb: FormBuilder,
    private store: Store<AppState>, 
    private shared : SharedService
  ) { 
    this.shared.setlayoutProject(false);
  }

  ngOnInit() {
    document.body.style.padding = "0";

    //
    // Listen to route and assign local variables.
    //
    this.route.data.pipe(
      map(data => data.resolveData),
      tap((data: { 
            supportUsername: string; 
            username: string; 
            isSupportAdmin: boolean; 
        }) => {
        console.log('data.username = ', data.username);
        this.supportUsername = data.supportUsername;
        this.isSupportAdmin = data.isSupportAdmin;
        if (data.username) {
          this.username = data.username;
          this.overrideSupport = true;
        } else { this.username = 'fmiadmin'; }
      }),
      take(1)
    ).subscribe();
    //
    // Setup up Form
    //
    this.impersonateForm = this.fb.group({
      username : [this.username, Validators.required],
      overrideSupport: this.overrideSupport
    });
    //
    // if overrideSupport value exist
    //
    if ( this.overrideSupport ) this.onSubmit();
  }
  onPrevious() {
    this.router.navigate(['/login', this.supportUsername]);
  }

  onSubmit() {
    this.store.dispatch(AllActions.impersonateUserStart({ 
      username: this.impersonateForm.value.username,
      overrideSupport: this.impersonateForm.value.overrideSupport 
    }));
  }

  goBack() {
    window.location.href = environment.userPortal;
    }
}
