import { createReducer, Action, on } from '@ngrx/store';
import { stateAuth } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';

const initialState: stateAuth = {
    idToken: null,
    username: null,
    claims: null,
    usedMFA: false,
    tpaOrgCodes: null
};

const authReducer = createReducer(
    initialState,
    on(AllActions.setIdToken, (state, action) => ({ ...state, idToken: action.idToken })),
    on(AllActions.setUsername, (state, action) => ({ ...state, username: action.username })),
    on(AllActions.setClaims, (state, action) => ({ ...state, claims: action.claims })),
    on(AllActions.setUsedMFA, (state, action) => ({ ...state, usedMFA: action.usedMFA })),
    on(AllActions.setTpaOrgCodes, (state, action) => ({ ...state, tpaOrgCodes: action.tpaOrgCodes })),
);

export function reducer(state: stateAuth | undefined, action: Action) {
    return authReducer(state, action);
}
