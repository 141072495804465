import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-full-contractor-v2',
  templateUrl: './full-contractor.component.html',
  styleUrls: ['./full-contractor.component.scss']
})
export class FullContractorV2Component implements OnInit {

  constructor() { }

  ngOnInit() {
    document.body.style.paddingTop = "0px"; // unit measured in Edge.
  }

}
