import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from "src/environments/environment";


export const trackerConfigCode: AuthConfig = {
    // Url of the Identity Provider
    issuer: environment.fortifyV2Url,
    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin  + '/login/auth/callback',
    // when you logout where it sends you.
    postLogoutRedirectUri: `${environment.userPortal}/post-logout`,
    // type of oidc response
    responseType: 'code',
    // The SPA's id. The SPA is registered with this id at the auth-server
    clientId: 'LCPtrackerSPA.code',
    // set the scope for the permissions the client should request
    scope: 'openid profile email theidserveradminapi',
    // Activate Session Checks:
    sessionChecksEnabled: true,
}

export const trackerConfigImplicit: AuthConfig = {
    // Url of the Identity Provider
    issuer: environment.fortifyV2Url,
    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin  + '/login/auth/callback',
    // type of oidc response
    responseType: 'id_token token',
    // The SPA's id. The SPA is registered with this id at the auth-server
    clientId: 'LCPtrackerSPA.implicit',
    // set the scope for the permissions the client should request
    // The first three are defined by OIDC. The 4th is a usecase-specific one
    scope: 'openid profile email',
    
  }

  export const trackerLogoutCode: AuthConfig = {
    // Url of the Identity Provider
    issuer: environment.fortifyV2Url,
    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin  + '/login/auth/callback',
    // when you logout where it sends you.
    postLogoutRedirectUri: `${environment.userPortal}/post-logout`,
    // type of oidc response
    responseType: 'code',
    // The SPA's id. The SPA is registered with this id at the auth-server
    clientId: 'LCPtrackerSPA.code',
    // set the scope for the permissions the client should request
    scope: 'openid profile email theidserveradminapi',
    // Activate Session Checks:
    sessionChecksEnabled: true,
}
//user portal configuration
export const userPortalConfigCode: AuthConfig = {
 // Url of the Identity Provider
issuer: environment.fortifyV2Url,
 // URL of the SPA to redirect the user to after login
redirectUri: environment.userPortal + '/login/sso',
  // when you logout where it sends you.
postLogoutRedirectUri: `${environment.userPortal}/post-logout`, 
// type of oidc response
 responseType: 'code',
  // The SPA's id. The SPA is registered with this id at the auth-server
  clientId: 'LCPtrackerSPA.code',
 // set the scope for the permissions the client should request
   scope: 'openid profile email theidserveradminapi',
 // Activate Session Checks:
sessionChecksEnabled: true, 
  }
