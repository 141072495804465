import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { authNet6ConfigCode } from '../auth.net6.config.code';

@Injectable({
  providedIn: 'root'
})
export class AuthNet6CodeService {
  constructor(private oauthService: OAuthService) { }

  login() {
    // Set the Configuration of the OIDC
    this.oauthService.configure(authNet6ConfigCode);

    // Inits the login
    this.oauthService.loadDiscoveryDocument()
      .then(res => {
        this.oauthService.initCodeFlow();
      });
  }
}
