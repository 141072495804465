<div class="confirmation">
  <img [src]="imageUrl" alt="" class="icon">
  <h4 class="confirmation-title">{{title}}</h4>
  <p class="confirmation-subtitle">{{subtitle}}</p>
  <br>

  <div *ngIf="usesFortify; else notFortifyTemplate">
    <button *ngIf="responseFullContractor.Success; else errorTemplate" type="button" class="btn btn-lg btn-info" (click)="logoutFromFortify()">Logout</button>
    <ng-template #errorTemplate>
      <button type="button" class="btn btn-lg btn-info" (click)="tryFormAgainEmitter()">Try again</button>
    </ng-template>
  </div>
  <ng-template #notFortifyTemplate>
    <div *ngIf="responseFullContractor.Success; else errorTemplate">
      <ng-template #exitingFortifyUser>
        <button type="button" class="btn btn-lg btn-info" (click)="goToLogin()">Login</button>
      </ng-template>
    </div>
    <ng-template #errorTemplate>
      <button type="button" class="btn btn-lg btn-info" (click)="tryFormAgainEmitter()">Try again</button>
    </ng-template>
  </ng-template>
</div>
