import { createReducer, Action, on } from '@ngrx/store';
import { stateAlert } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';


const initialState: stateAlert = {
    message: '',
};

const alertReducer = createReducer(
    initialState,
    on(AllActions.showAlert, (state, action) => ({ ...state, message: action.message })),
);

export function reducer(state: stateAlert | undefined, action: Action) {
    return alertReducer(state, action);
}
