import { LcpService } from 'src/app/services/lcp/lcp.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorService } from 'src/app/services/error/error.service';
import { environment } from 'src/app/../environments/environment';
import { AppState, GetAccountsResponseData} from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';
//
// ngrx / rxjs
//
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, catchError, map, tap, take } from 'rxjs/operators';
import { SharedService } from 'src/app/services/shared/shared.service';

@Injectable()
export class AccountEffects {
    constructor(
        private actions$: Actions,
        private error: ErrorService,
        private http: HttpClient,
        private router: Router,
        private shared : SharedService,
        private snackBar: MatSnackBar,
        private store: Store<AppState>,
        private lcpService: LcpService
    ) { }

    getAccounts$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.getAccountsStart),
            tap(() => {
               this.shared.setProgressBar(true);
            }),
            switchMap(() =>
                this.http.get<GetAccountsResponseData>(
                    `${environment.webAppUrl}/api/accounts`, {
                    withCredentials: true,
                }).pipe(
                    take(1),
                    map(response => AllActions.getAccountsSuccess(
                        {
                            username: response.Username,
                            supportUsername: response.SupportUsername,
                            accounts: response.Accounts,
                        })),
                    catchError((errorResponse: HttpErrorResponse) => {
                        console.log('accounts fail', errorResponse);
                        let message = errorResponse.error.Message;
                        if (errorResponse.status === 403) {
                            message = 'Unauthorized';
                        }
                        return of(AllActions.getAccountsFail({ errorMessage: message }));
                    })
                )
            )
        )
    );

    getAccountsSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.getAccountsSuccess),
            tap(action => {
                if (action.accounts.length > 0 ) {
                    this.shared.setProgressBar(false);
                } else {
                    this.store.dispatch(AllActions.showAlert({ message: 'User has no accounts assigned' }));
                    if (action.supportUsername) {
                        this.router.navigate(['/login', 'impersonate']);
                    } else {
                        this.router.navigate(['/login']);
                    }
                }
            })
        ), { dispatch: false }
    );

    getAccountsFail$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.getAccountsFail),
            tap(action => {
                console.log('getAccountsFail$ :', action);
                let err = this.error.returnError('failed_account');
                this.snackBar
                .open(err, 'OK', { duration: 10000, verticalPosition: "top"})
                .onAction()
                .subscribe(() => {
                    // Keep this here for when you want to "undo" a functionality.
                });
                this.store.dispatch(AllActions.setBusyMessage({ message: '' }));
                this.router.navigate(['/login']);
            }),
        ), { dispatch: false }
    );

    setOrgCode$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.setOrgCodeStart),
            tap(() => this.store.dispatch(AllActions.setBusyMessage({ message: 'Logging in...' }))),
            switchMap(action => {
                console.log('setOrgCodeStart variables : ' + JSON.stringify(action))
                
                 return this.lcpService.setOrgCode(action.orgCode, action.orgCodes, action.RoleId)
          
                  .pipe(
                    take(1),
                    map(response => AllActions.setOrgCodeSuccess({
                        accountType: response.AccountType,
                    })),
                    catchError((errorResponse: HttpErrorResponse) => {
                        console.log('setOrgCode', errorResponse);
                        return of(AllActions.setOrgCodeFail({ errorMessage: errorResponse.error.Message }));
                    })
                );
            })
        )
    );

    setOrgCodeSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.setOrgCodeSuccess),
            tap(action => {
                this.store.dispatch(AllActions.setBusyMessage({ message: 'You have successfuly Set Org Have a cookie now' }));
                if (action.accountType === 'Admin') {
                    this.store.dispatch(AllActions.setBusyMessage({ message: 'Logging in Administrator...' }));
                    window.location.href = `${environment.webAppUrl}/Projects/UserAgreement`;
                } else if (action.accountType === 'Contractor') {
                    this.store.dispatch(AllActions.setBusyMessage({ message: 'Logging in Contractor...' }));
                    window.location.href = `${environment.webAppUrl}/Projects/UserAgreement`;
                }
            })
        ), { dispatch: false }
    );

    setOrgCodeFail$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.setOrgCodeFail),
            take(1),
            tap(action => {
                console.log('setOrgCodeFail', action);
                //
                // Check for MFA - 'Multifactor Authentication (MFA) is required to access this account'
                //
                let err;
                if ( action.errorMessage == 'Multifactor Authentication (MFA) is required to access this account') {
                    err = this.error.returnError('failed_mfaRequired');
                } else {
                    err = this.error.returnError('failed_setOrg');
                }
                this.snackBar
                .open(err, 'OK', { duration: 10000, verticalPosition: "top"})
                .onAction()
                .subscribe(() => {
                    // Keep this here for when you want to "undo" a functionality.
                });
                this.store.dispatch(AllActions.setBusyMessage({ message: '' }));
                this.router.navigate(['/login']);
            }),
        ), { dispatch: true }
    );
}
