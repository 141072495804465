import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export interface Claims {
    amr: string[];
    upn: string;
}

const config = environment.aadVersion === 1 ? {
    discoveryDocumentUrl: `https://login.microsoftonline.com/${environment.lcptrackerTenantGuid}/.well-known/openid-configuration`,
    loginUrl: `https://login.microsoftonline.com/${environment.lcptrackerTenantGuid}/oauth2/authorize`,
    issuer: `https://sts.windows.net/${environment.lcptrackerTenantGuid}/`,
} : {
    discoveryDocumentUrl: `https://login.microsoftonline.com/${environment.lcptrackerTenantGuid}/v2.0/.well-known/openid-configuration`,
    loginUrl: `https://login.microsoftonline.com/${environment.lcptrackerTenantGuid}/oauth2/v2.0/authorize`,
    issuer: `https://login.microsoftonline.com/${environment.lcptrackerTenantGuid}/v2.0`,
};

export const discoveryDocumentUrl = config.discoveryDocumentUrl;
export const loginUrl = config.loginUrl;

export const authConfig: AuthConfig = {
    issuer: config.issuer,
    redirectUri: `${window.location.origin}/login/login/lcptracker-redirect`,
    requestAccessToken: false,
    showDebugInformation: true,
    clientId: environment.lcptrackerClientId,
    strictDiscoveryDocumentValidation: false,
    postLogoutRedirectUri: `${window.location.origin}/login/login`,
};
