import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { AppState, AuthResponse } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';
//
// ngrx / rxjs
//
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap, switchMap, map, catchError, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { ErrorService } from 'src/app/services/error/error.service';

@Injectable()
export class AuthEffects {
    constructor(
        private actions$: Actions,
        private error: ErrorService,
        private http: HttpClient,
        private snackBar: MatSnackBar,
        private store: Store<AppState>,
        private router: Router
    ) { }


    validateToken$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.validateTokenStart),
            tap(() => this.store.dispatch(AllActions.setBusyMessage({ message: 'Getting user information' }))),
            switchMap(action =>
                this.http.post<AuthResponse>(`${environment.webAppUrl}/api/login`, {
                    Token: action.token,
                }, {
                    withCredentials: true,
                }).pipe(
                    take(1),
                    map(response => {
                        //
                        // Figure out what comes back after validateTokenStart
                        //
                        console.log('validateTokenStart : ' + JSON.stringify(response));
                        if (response.Success) {
                            return AllActions.validatePasswordSuccess({
                                username: response.Username,
                                isSupportUser: response.IsSupportUser,
                                enableFullContractor: response.EnableFullContractor,
                                isEmailContractorUser:response.IsEmailContractorUser
                            });
                        } else {
                            return AllActions.validateTokenFail({ errorMessage: response.Message });
                        }
                    }),
                    catchError(error => {
                        return of(AllActions.validateTokenFail({ errorMessage: error.Message }));
                    })
                )
            )
        )
    );

    validateTokenFail$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.validateTokenFail),
            take(1),
            tap(action => {
                console.log('validateTokenFail$ :', action);
                let err = this.error.returnError('failed_token');
                this.snackBar
                .open(err, 'Refresh', { duration: 25000, verticalPosition: "top" })
                .onAction()
                .subscribe(() => {
                    // Keep this here for when you want to "undo" a functionality.
                    location.reload();
                });
                this.store.dispatch(AllActions.setBusyMessage({ message: '' }));
                this.router.navigate(['/login']);
            }),
        ), { dispatch: true }
    );
}
