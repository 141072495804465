<form class="no-margin" (ngSubmit)="onSubmit()" [formGroup]="usernameForm">
  <div class="login">
      <div class="field-group">
        <input type="text" name="username" id="username" formControlName="username" appAutoFocus/>
        <label for="username">Username</label>
      </div>
      <div class="button-group">
        <button type="submit" id="next" class="btn btn-lg btn-info" [disabled]="usernameForm.invalid">Next <i class="fa fa-chevron-right"></i></button>
      </div>
      <div class="link-group">
        <a tabindex="0" title="Other External Logins" 
          (click)="fortifyExternalProviders()"
          (keyup.enter)="fortifyExternalProviders()"
          >
            <i class="fa fa-caret-right" aria-hidden="true"></i>
            Login with an External Provider<span class="legal">* </span>
            <i class="fa fa-external-link" aria-hidden="true"></i>
        </a>
      </div>
      <span class="legal-note"><span class="legal">*</span> Only required for certain providers and may not apply to you.</span>
  </div>
</form>