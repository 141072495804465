import { Injectable } from '@angular/core';
import { AppState, OrgCode, stateFortifyAuth, RBSClaim } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';
import { SharedService } from 'src/app/services/shared/shared.service';
//
// ngrx / rxjs
//
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { tap, take, filter, switchMap, map, takeWhile, timeout, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
//
// oidc services
//
import * as fromFortifyAuthConfig from 'src/app/auth/fortify/auth.config';
import { OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { SignatureValidationHandler } from 'src/app/services/oidc/signature-validation-handler';
import { FortifyOAuthService } from 'src/app/auth/fortify/fortify-oauth.service';

import { LcpService } from 'src/app/services/lcp/lcp.service';

@Injectable()
export class FortifyAuthEffects {
    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private fortifyOAuthService: FortifyOAuthService,
        private shared : SharedService,
        private lcpService: LcpService
    ) { }

    //
    // Setup the OIDC service
    //
    configureFortifyOIDC() {
        this.fortifyOAuthService.configure(fromFortifyAuthConfig.authConfigDirect);
        this.fortifyOAuthService.customQueryParams = { useTPA: true };
        this.fortifyOAuthService.tokenValidationHandler = new SignatureValidationHandler();
        
        this.fortifyOAuthService.responseType = 'id_token token';
        // this.fortifyOAuthService.scope = 'openid profile';
        this.fortifyOAuthService.scope = 'openid profile trackerIntegrationApi geographyApi wageDataApi projectApi';
        // this.fortifyOAuthService.scope = 'openid profile trackerIntegrationApi geographyApi wageDataApi';

        this.fortifyOAuthService.loginUrl = fromFortifyAuthConfig.loginUrl;

        this.fortifyOAuthService
            .loadDiscoveryDocument(fromFortifyAuthConfig.discoveryDocumentUrl)
            .then(() => this.fortifyOAuthService.tryLoginImplicitFlow());

    }

    //
    // TODO :: Refactoring action.oauthevent.type === 
    // 'discovery_document_loaded'
    discovery_document_loaded(action) {
        console.log('%c ACTION ===> discovery_document_loaded : ', 'color: #8DB9E3; font-weight: bold; background-color: black;');
        console.log(JSON.stringify(action));
    }
    // 'token_received'
    token_received(action) {
        console.log('%c ACTION ===> token_received : ', 'color: #8DB9E3; font-weight: bold; background-color: black;');
    }
    // 'token_expires'
    token_expires(action) {
        console.log('%c ACTION ===> token_expires : ', 'color: #8DB9E3; font-weight: bold; background-color: black;');
        console.log(JSON.stringify(action));
    }
    // token_validation_error
    token_validation_error(action) {
        console.log('%c ACTION ===> token_expires : ', 'color: #8DB9E3; font-weight: bold; background-color: black;');
        console.log(JSON.stringify(action));
    }

    //
    // Extended List of oauthevent commands:
    //
    // 'discovery_document_loaded' | 
    // 'received_first_token' | 
    // 'jwks_load_error' | 
    // 'invalid_nonce_in_state' | 
    // 'discovery_document_load_error' | 
    // 'discovery_document_validation_error' | 
    // 'user_profile_loaded' | 
    // 'user_profile_load_error' | 
    // 'token_received' | 
    // 'token_error' | 
    // 'code_error' | 
    // 'token_refreshed' | 
    // 'token_refresh_error' | 
    // 'silent_refresh_error' | 
    // 'silently_refreshed' | 
    // 'silent_refresh_timeout' | 
    // 'token_validation_error' | 
    // 'token_expires' | 
    // 'session_changed' | 
    // 'session_error' | 
    // 'session_terminated' | 
    // 'logout';

    //
    // START PERSISTANT CHECKS
    //
    fortifyOAuthService$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.serviceFortifyOauth),
            // tap(action => console.log('fortifyOAuthService', action)),
            switchMap(action => {
                if (action.oauthevent.type === 'discovery_document_loaded') {
                    //this.discovery_document_loaded(action);
                    const successEvent = action.oauthevent as OAuthSuccessEvent; 
                    console.log('%c OAuthSuccessEvent : ','color: #8DB9E3; font-weight: bold; background-color: black;');
                    console.log(successEvent);
                    if (successEvent.info !== null) {
                        this.fortifyOAuthService.tryLoginImplicitFlow().then(value => {
                            console.log('tryLoginImplicitFlow idToken == ', value, this.fortifyOAuthService.getIdToken());
                            if (!value) { // keep trying until the ImplicitFlow comes back with a value.
                                this.fortifyOAuthService.initImplicitFlow();
                            }
                        });
                        if (this.fortifyOAuthService.getIdToken() !== null) {
                          const claims = this.fortifyOAuthService.getIdentityClaims() as fromFortifyAuthConfig.Claims;

                          return this.ofLoginSuccessFortify(claims);
                        }
                    }

                    return of(AllActions.loginSuccessFortify({
                        idToken: null,
                        claims: null,
                        username: null,
                        usedMFA: false,
                        orgCodes: null,
                        tpaOrgCodes: null
                    }));
                } 
                else if (action.oauthevent.type === 'token_received') {
                    const claims = this.fortifyOAuthService.getIdentityClaims() as fromFortifyAuthConfig.Claims;
                    const tokens = this.fortifyOAuthService.getIdToken();

                    console.log( 'tpaOrgCodes : ' + JSON.stringify(claims) + tokens);

                    console.log('%c -PATH B [token_received]-','color: black; background-color: orange; padding: 6px');

                    if ( tokens != (null || undefined )) {
                      return this.ofLoginSuccessFortify(claims);
                    } else {
                        location.reload();
                    }
                }
                else if (action.oauthevent.type === 'token_expires') {
                  // TODO: A reload can work?
                }
                else {
                    return of(AllActions.loginSuccessFortify({
                        idToken: null,
                        claims: null,
                        username: null,
                        usedMFA: false,
                        orgCodes: null,
                        tpaOrgCodes: null
                    }));
                }
            })
        )
    );

    ofLoginSuccessFortify(claims): Observable<any> {
      return of(AllActions.loginSuccessFortify({
        idToken: this.fortifyOAuthService.getIdToken(),
        claims,
        username: claims.user_name,
        usedMFA: claims.mfa_enabled,
        orgCodes: null,
        tpaOrgCodes: claims.tpa_org_codes
      }));
    }


    // -----------------------------------------------------------------------------
    // ------------------------   FORTIFY-DIRECT  ----------------------------------
    // -----------------------------------------------------------------------------


    //  
    // Login through username with Fortify Flag.
    //
    loginDirect$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.preLogin),
            tap(() => this.shared.setProgressBar(true)),
            switchMap(action => {
                console.log(JSON.stringify(action));
                //
                // Setup the OIDC service
                this.configureFortifyOIDC();

                return this.fortifyOAuthService.events
                    .pipe(
                        tap(event => console.log('serviceFortifyOauth : ', JSON.stringify(event))),
                        map(event => {
                            if ( event.type == 'token_expires') {
                                console.log('%c Token Expired', 'color: red; font-weight: bold; font-size: 2em; display:inline-block; padding: 25px; background: black;');
                                // location.reload();
                            }
                            return AllActions.serviceFortifyOauth({ oauthevent: event });  
                        })
                    );
            })
        )
    );





    // -----------------------------------------------------------------------------
    // ------------------------   FORTIFY-REDIRECT  --------------------------------
    // -----------------------------------------------------------------------------

    //
    // Login through username with Fortify Flag.
    //
    login$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.loginStartFortify),
            tap(action => console.log('login$', action)),
            switchMap(action => {
                this.fortifyOAuthService.configure(fromFortifyAuthConfig.authConfig);
                this.fortifyOAuthService.customQueryParams = { as: action.username };
                this.fortifyOAuthService.tokenValidationHandler = new SignatureValidationHandler();
                

                this.fortifyOAuthService.responseType = 'id_token token';
                //this.fortifyOAuthService.scope = 'openid profile';
                this.fortifyOAuthService.scope = 'openid profile trackerIntegrationApi geographyApi wageDataApi projectApi';
                this.fortifyOAuthService.loginUrl = fromFortifyAuthConfig.loginUrl;

                this.fortifyOAuthService
                    .loadDiscoveryDocument(fromFortifyAuthConfig.discoveryDocumentUrl)
                    .then(() => this.fortifyOAuthService.tryLoginImplicitFlow());



                return this.fortifyOAuthService.events
                    .pipe(
                        tap(event => console.log('authServiceEventFortify :', event)),
                        map(event => {
                            return AllActions.authServiceEventFortify({ oauthevent: event });
                        })
                    );
            })
        )
    );
         
    authServiceEvent$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.authServiceEventFortify),
            tap(action => console.log('authServiceEvent', action)),
            switchMap(action => {
                if (action.oauthevent.type === 'discovery_document_loaded') {
                    const successEvent = action.oauthevent as OAuthSuccessEvent; 
                    if (successEvent.info !== null) {
                        this.fortifyOAuthService.tryLoginImplicitFlow().then(value => {
                            console.log('tryLoginImplicitFlow idToken == ', value, this.fortifyOAuthService.getIdToken());
                            if (!value) { // keep trying until the ImplicitFlow comes back with a value.
                                this.fortifyOAuthService.initImplicitFlow();
                            }
                        });

                        if (this.fortifyOAuthService.getIdToken() !== null) {
                          const claims = this.fortifyOAuthService.getIdentityClaims() as fromFortifyAuthConfig.Claims;

                          console.log('%c -PATH C [discovery_document_loaded]-','color: black; background-color: orange; padding: 6px');

                          return this.ofLoginSuccessFortify(claims);
                        }
                    }
                    console.warn('org codes NULL')
                    return of(AllActions.loginSuccessFortify({
                        idToken: null,
                        claims: null,
                        username: null,
                        usedMFA: false,
                        orgCodes: [null, null],
                        tpaOrgCodes: null
                    }));
                } else if (action.oauthevent.type === 'token_received') {
                  // this.fortifyOAuthService.setupAutomaticSilentRefresh();
                  const claims = this.fortifyOAuthService.getIdentityClaims() as fromFortifyAuthConfig.Claims;

                  console.log( 'tpaOrgCodes : ' + claims.tpa_org_codes);
                  console.log('%c -PATH D [token_received]-','color: black; background-color: orange; padding: 6px');

                  return this.ofLoginSuccessFortify(claims);

                } else if (action.oauthevent.type === 'token_expires') {
                    console.log('%c Token Expired', 'color: var(--red); background: black;')
                    // this.fortifyOAuthService.silentRefresh()
                    // .then(info => console.debug('refresh ok', info))
                    // .catch(err => console.error('refresh error', err));
                    //return location.reload();
                }
                else {
                    return of(AllActions.loginSuccessFortify({
                        idToken: null,
                        claims: null,
                        username: null,
                        usedMFA: false,
                        orgCodes: [null, null, null],
                        tpaOrgCodes: null
                    }));
                }
            })
        )
    );

    //
    // fortifyOAuthService.hasValidIdToken()
    //        
    auth$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.authFortify),
            tap(action => {
                console.log('authFortify auth$', action);
                this.fortifyOAuthService.configure(fromFortifyAuthConfig.authConfig);

                this.fortifyOAuthService
                  .loadDiscoveryDocument(fromFortifyAuthConfig.discoveryDocumentUrl)
                  .then(() => this.fortifyOAuthService.tryLoginImplicitFlow());

                this.fortifyOAuthService.events
                  .pipe(
                    tap(event => {
                        console.log('fortifyOAuthService$ event', event);
                        if (this.fortifyOAuthService.hasValidIdToken()) {
                          const claims = this.fortifyOAuthService.getIdentityClaims() as fromFortifyAuthConfig.Claims;
                          
                          this.dispatchLoginSuccessFortifyAction(claims)

                        } else {
                            console.log('DID NOT AUTHENTICATE')
                        }
                    }),
                    takeWhile(() => !this.fortifyOAuthService.hasValidIdToken()),
                    timeout(2000),
                ).subscribe();
            })
        ), { dispatch: false }
    );

    logout$ = createEffect(
        () => this.actions$.pipe(
            ofType(AllActions.logoutFortify),
            tap(action => {
                console.log('logout fortify', action);
                this.fortifyOAuthService.logOut(false);
            }),
        ), { dispatch: false }
    );

  /**
   * Sets the authFortify store object with the claims from the API
   * @param claims
   * @param orgCodes
   */
  dispatchLoginSuccessFortifyAction(claims) {
    this.store.dispatch(AllActions.loginSuccessFortify({
      idToken: this.fortifyOAuthService.getIdToken(),
      claims,
      username: claims.user_name,
      usedMFA: claims.mfa_enabled,
      orgCodes: null,
      tpaOrgCodes: claims.tpa_org_codes
    }));
  }

  /**
   * Returns the specified portion of the state
   * @param featureState
   * @returns the specified portion of the state
   */
  getState(featureState) {
    let selectedState;
    this.store.select(featureState)
      .pipe(
        filter(state => !!state.idToken),
        take(1)
      ).subscribe(stateResponse => {
        selectedState = stateResponse;
      });

    return selectedState;
  }
}
