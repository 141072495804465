export const environment = {
  production: true,
  webAppUrl: `https://${window.location.hostname.replace('-cdn', '')}`,
  aadVersion: 1,
  lcptrackerTenantGuid: '5fbdf4e1-b5f1-4bdf-8650-f6b3ba607710',
  lcptrackerClientId: 'e558250f-71f5-41e7-a3a9-c6e3ce2aa0ac',
  fortifyClientId: 'LcpTrackerUX',
  fortifyUrl: 'https://prod.fortify.lcptracker.net',
  fortifyAPIUrl: 'https://prodapi.fortify.lcptracker.net/',
  fortifyV2Url: 'https://fortifyv2.lcptracker.net',
  fortifyV2APIUrl: 'https://fortifyv2.lcptracker.net/api',
  chargifyUrl: 'https://production-lcptracker.chargifypay.com/subscribe/5m5vh2mgzvs7',
  lcpCertifiedUrl: 'https://certified.lcptracker.net/',
  hideConsole : false,
  contentful: {
    spaceId : 'zo193z3agqwx',
    token : 'iwkuroWPEIAcQu3T4b6r_PNIVQAfbtc7KacffUFr2wI'
  },
  userPortal: 'https://userportal.fortifyv2.lcptracker.net/#',
  EnableClarityTracking : true,
  env: 'p'
};
