import { Component, OnInit } from '@angular/core';
import { JwksValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { Subscription } from 'rxjs/internal/Subscription';
import { trackerLogoutCode } from 'src/app/features/v2.config';

@Component({
  selector: 'route-fortify-v2-logout',
  templateUrl: './fortify-v2-logout.component.html',
  styleUrls: ['./fortify-v2-logout.component.scss']
})
export class FortifyV2LogoutComponent implements OnInit {

  subscription!: Subscription;

  constructor(
    private oAuthService: OAuthService
  ) { }

  ngOnInit() {
    // Set the initial padding style for the body element (measured in Edge).
    document.body.style.paddingTop = "0px";

    // Execute the following block of code after 5 seconds.
    setTimeout(() => {
      // Check if 'id_token_claims_obj' exists in sessionStorage.
      if (sessionStorage.getItem('id_token_claims_obj')) {
        // If found, automatically log the user out according to OIDC protocols.
        this.oAuthService.logOut();

        // Reload the page to ensure all changes are applied.
        location.reload();
        console.warn('Cleaning Session'); // Log a message indicating the session is being cleaned.
      } else {
        // If not found, proceed with the OAuth service configuration.
        console.warn('Closing Session'); // Log a message indicating the session is closing.
        // Configure the OAuth service with necessary settings.
        this.oAuthService.configure(trackerLogoutCode);

        // Set the token validation handler for OIDC.
        this.oAuthService.tokenValidationHandler = new JwksValidationHandler();

        // Load the discovery document and attempt to login.
        this.oAuthService.loadDiscoveryDocumentAndTryLogin()
          .then(res => {
            // Log the result of the login attempt.
            console.log('oAuthService.loginCode(trackerLogoutCode)' + res);

            // Set a flag in sessionStorage indicating logout from the tracker.
            sessionStorage.setItem('logout-from-tracker-pro', 'true');

            // Initiate the OAuth code flow.
            this.oAuthService.initCodeFlow();
          });
      }
    }, 5000); // Wait for 5 seconds before executing the above block.
  }
}
