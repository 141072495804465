<div class="tpa-info">
  <div class="icon-wrapper">
    <i class="fa fa-lock fa-xl" aria-hidden="true"></i>
  </div>

  <div class="info-wrapper">
    <h6 class="title">Security enhancement</h6>

    <div *ngIf="notTPASatisdfiedAccounts.length > 1; else singularNote">
      <p>The agencies below require enhanced security:</p>
      <ul *ngFor="let account of notTPASatisdfiedAccounts">
        <li><b>{{account}}</b></li>
      </ul>
    </div>
    <ng-template #singularNote>
      <p>Your agency <b>{{notTPASatisdfiedAccounts[0]}}</b> requires enhanced security.</p>
    </ng-template>
    <p>Please update your Account Settings by clicking <a [href]="externalLoginsLink">here</a> or reach out to your admin.</p>
  </div>
</div>