import { environment } from 'src/app/../environments/environment';
import { tap, catchError, switchMap, take } from 'rxjs/operators';
import { ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import * as AllActions from 'src/app/store/actions/index';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/effects";
import * as i2 from "../../../services/error/error.service";
import * as i3 from "@angular/common/http";
import * as i4 from "@angular/router";
import * as i5 from "@angular/material/snack-bar";
import * as i6 from "@ngrx/store";
import * as i7 from "../../../services/storage/storage.service";
import * as i8 from "../../../services/lcp/lcp.service";
import * as i9 from "../../../features/auth.service";
export class PasswordResolverService {
    constructor(actions$, error, http, router, snackBar, store, storage, lcp, auth) {
        this.actions$ = actions$;
        this.error = error;
        this.http = http;
        this.router = router;
        this.snackBar = snackBar;
        this.store = store;
        this.storage = storage;
        this.lcp = lcp;
        this.auth = auth;
    }
    resolve(route, state) {
        let username = route.params.username;
        console.log('password-resolver', username);
        this.store.dispatch(AllActions.setBusyMessage({ message: 'Checking Username' }));
        return this.http.post(`${environment.webAppUrl}/api/user`, { id: username })
            .pipe(take(1), tap(() => this.store.dispatch(AllActions.setBusyMessage({ message: '' }))), switchMap((response) => {
            //
            // what comes back on username call.
            this.storage.remove('auth-route');
            this.storage.setItem('auth-route', 'non-tpa');
            this.storage.setItem('tpa-login', false);
            console.log('HTTP.../api/user/${username}' + JSON.stringify(response));
            if (response.UsesFortify) {
                console.log(`%cUses Fortify: ${response.UsesFortify}`, "background-color: green; color: white; padding: 7px");
            }
            else {
                console.log(`%cUses Fortify: ${response.UsesFortify}`, "background-color: red; color: black; padding: 7px");
            }
            //
            // Suppport
            //
            if (response.IsSupportUser) {
                this.store.dispatch(AllActions.loginStartLcptracker({ username: "" }));
                return this.actions$.pipe(ofType(AllActions.loginSuccessLcptracker), take(1));
            }
            if (response.UsesFortify) {
                this.auth.loginCodeTwoUserPortal(username);
            }
            // non-fortify also needs this for the password screen.
            return of({ username });
        }), catchError(error => {
            console.log(error);
            let err = this.error.returnError('failed_usernameGet');
            this.snackBar
                .open(err, 'OK', { duration: 10000, verticalPosition: "top" })
                .onAction()
                .subscribe(() => {
                // Keep this here for when you want to "undo" a functionality.
            });
            this.store.dispatch(AllActions.setBusyMessage({ message: '' }));
            this.router.navigate(['/login']);
            return of({ username });
        }), take(1));
    }
}
PasswordResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PasswordResolverService_Factory() { return new PasswordResolverService(i0.ɵɵinject(i1.Actions), i0.ɵɵinject(i2.ErrorService), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.MatSnackBar), i0.ɵɵinject(i6.Store), i0.ɵɵinject(i7.StorageService), i0.ɵɵinject(i8.LcpService), i0.ɵɵinject(i9.AuthService)); }, token: PasswordResolverService, providedIn: "root" });
