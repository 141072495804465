/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./contractor-user-portal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/pipes/email-highlighter.pipe";
import * as i3 from "./contractor-user-portal.component";
import * as i4 from "../../../shared/dialog/services/confirmation-dialog.service";
import * as i5 from "@angular/router";
import * as i6 from "../account-selector/service/contractor-selector-account.service";
import * as i7 from "@angular/material/snack-bar";
var styles_ContractorUserPortalComponent = [i0.styles];
var RenderType_ContractorUserPortalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ContractorUserPortalComponent, data: {} });
export { RenderType_ContractorUserPortalComponent as RenderType_ContractorUserPortalComponent };
export function View_ContractorUserPortalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.EmailHighlighterPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "account-selector-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "LCPtracker Logo"], ["src", "assets/logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "container-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 1, "a", [], [[8, "tabIndex", 0]], [[null, "keydown.enter"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown.enter" === en)) {
        var pd_0 = (_co.goToUserPortal() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.goToUserPortal() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Go to User Portal"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), _co.messageBackEnd)); _ck(_v, 5, 0, currVal_0); var currVal_1 = 1; _ck(_v, 7, 0, currVal_1); }); }
export function View_ContractorUserPortalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-contractor-user-portal", [], null, null, null, View_ContractorUserPortalComponent_0, RenderType_ContractorUserPortalComponent)), i1.ɵdid(1, 245760, null, 0, i3.ContractorUserPortalComponent, [i4.ConfirmationDialogService, i5.Router, i6.ContractorSelectorAccountService, i7.MatSnackBar], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ContractorUserPortalComponentNgFactory = i1.ɵccf("app-contractor-user-portal", i3.ContractorUserPortalComponent, View_ContractorUserPortalComponent_Host_0, {}, {}, []);
export { ContractorUserPortalComponentNgFactory as ContractorUserPortalComponentNgFactory };
