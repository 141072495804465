import * as AllActions from 'src/app/store/actions/index';
import { ofType } from '@ngrx/effects';
import { map, take, tap } from 'rxjs/operators';
import { merge } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@ngrx/effects";
import * as i3 from "../../../services/shared/shared.service";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
export class ImpersonateResolverService {
    constructor(store, actions$, shared, router, location) {
        this.store = store;
        this.actions$ = actions$;
        this.shared = shared;
        this.router = router;
        this.location = location;
    }
    resolve(route, state) {
        console.log('ImpersonateResolverService.resolve', route, state);
        this.shared.setProgressBar(false);
        this.store.dispatch(AllActions.getUsernameStart());
        const getUsernameSuccess$ = this.actions$.pipe(ofType(AllActions.getUsernameSuccess), map(response => ({
            supportUsername: response.supportUsername,
            username: response.username,
            isSupportAdmin: response.isSupportAdmin
        })));
        const getUsernameFail$ = this.actions$.pipe(ofType(AllActions.getUsernameFail), tap(action => {
            this.location.back();
        }), map(() => ({ supportUsername: '', username: '' })));
        return merge(getUsernameSuccess$, getUsernameFail$).pipe(take(1));
    }
}
ImpersonateResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImpersonateResolverService_Factory() { return new ImpersonateResolverService(i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.Actions), i0.ɵɵinject(i3.SharedService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.Location)); }, token: ImpersonateResolverService, providedIn: "root" });
