import * as i0 from "@angular/core";
export class StorageService {
    constructor() {
        this.storage = localStorage;
    }
    //
    // General Storage 
    //
    isExisted(key) {
        return this.storage.getItem(key) !== null;
    }
    clearAll() {
        this.storage.clear();
    }
    setItem(key, data) {
        this.storage.setItem(key, JSON.stringify(data));
        return data;
    }
    getItem(key) {
        let result;
        if (this.isExisted(key)) {
            if (this.storage.getItem(key) != undefined) {
                result = this.storage.getItem(key);
            }
        }
        return result;
    }
    remove(key) {
        let result = false;
        if (this.isExisted(key)) {
            this.storage.removeItem(key);
            result = true;
        }
        return result;
    }
}
StorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageService_Factory() { return new StorageService(); }, token: StorageService, providedIn: "root" });
