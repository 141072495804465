import { createReducer, on, Action } from '@ngrx/store';
import { stateImpersonate } from 'src/app/models/interfaces';
import * as AllActions from 'src/app/store/actions/index';

const initialState: stateImpersonate = {
    loading: false,
    errorMessage: '',
    supportUsername: '',
    username: '',
};

const impersonateReducer = createReducer(
    initialState,

    on(AllActions.getUsernameStart, state => ({ ...state, loading: true, errorMessage: '', supportUsername: '', username: '' })),
    on(AllActions.getUsernameSuccess, (state, action) => ({ ...state, loading: false, supportUsername: action.supportUsername, isSupportAdmin: action.isSupportAdmin })),
    on(AllActions.getUsernameFail, (state, action) => ({ ...state, loading: false, errorMessage: action.errorMessage })),

    on(AllActions.impersonateUserStart, state => ({ ...state, loading: true, errorMessage: '' })),
    on(AllActions.impersonateUserSuccess, (state, action) => ({ ...state, loading: false, username: action.username })),
    on(AllActions.impersonateUserFail, (state, action) => ({ ...state, loading: false, errorMessage: action.errorMessage })),
);

export function reducer(state: stateImpersonate | undefined, action: Action) {
    return impersonateReducer(state, action);
}
