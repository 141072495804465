<div class="sso-page-container">
  <!-- 
    // TODO : Testing username, eventually remove when testing is completed.
    <div class="flex ">
    <pre><b>email:</b> {{email | json}}</pre>
    <pre><b>name:</b> {{name | json}}</pre>
    <pre><b>preferred_username:</b> {{preferred_username | json}}</pre>
    <pre><b>RBS_EXTENDED_CLAIMS:</b>  {{orgCodesFromFortify | json}}</pre>
  </div> -->
  <div class="sso-page-card sm:mx-2.28">
      <img src="assets/logo.png" alt="LCPtracker Logo">
      <h4>Accounts</h4>
      <div class="progress-spinner-container">
          <mat-progress-spinner *ngIf="loading === true" 
            class="example-margin" 
            color="primary" 
            mode="indeterminate" 
            diameter="50"
            >
          </mat-progress-spinner>
      </div>
      <div class="list-container">
        <ul class="table-style" *ngIf="loading === false && orgList.length > 0">
          <li class="table-row" *ngFor="let org of orgList; let i = index;">
            <a tabindex="0" class="account-list lcp-hyperlink"
              (click)="setOrgCode(org)"
              (keyup.enter)="setOrgCode(org)"
              >{{org.Name}}
            </a>
          </li>
        </ul>
      </div>
  </div>
  <footer></footer>
</div>
