import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tpa-info',
  templateUrl: './tpa-info.component.html',
  styleUrls: ['./tpa-info.component.scss']
})
export class TpaInfoComponent implements OnInit {

  @Input() notTPASatisdfiedAccounts: string[];

  externalLoginsLink: string = `${environment.fortifyUrl}/Home/Index`;

  constructor() {}

  ngOnInit() {}

}
