/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../button/button.component.ngfactory";
import * as i4 from "../button/button.component";
import * as i5 from "../pipes/safe-html.pipe";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/common";
import * as i8 from "./dialog.component";
var styles_DialogComponent = [i0.styles];
var RenderType_DialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogComponent, data: {} });
export { RenderType_DialogComponent as RenderType_DialogComponent };
function View_DialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "buttons-row mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-button", [["buttonType", "flat"], ["label", "Cancel"]], null, [[null, "buttonClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClicked" === en)) {
        var pd_0 = (_co.onClose() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i1.ɵdid(3, 49152, null, 0, i4.ButtonComponent, [], { buttonType: [0, "buttonType"], label: [1, "label"] }, { buttonClicked: "buttonClicked" }), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-button", [["buttonType", "primary"], ["label", "Confirm"]], null, [[null, "buttonClicked"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClicked" === en)) {
        var pd_0 = (_co.onConfirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ButtonComponent_0, i3.RenderType_ButtonComponent)), i1.ɵdid(5, 49152, null, 0, i4.ButtonComponent, [], { buttonType: [0, "buttonType"], label: [1, "label"] }, { buttonClicked: "buttonClicked" })], function (_ck, _v) { var currVal_0 = "flat"; var currVal_1 = "Cancel"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = "primary"; var currVal_3 = "Confirm"; _ck(_v, 5, 0, currVal_2, currVal_3); }, null); }
export function View_DialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.SafeHtmlPipe, [i6.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "modal-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "main", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h5", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i2.MatDialogContent, [], null, null), i1.ɵppd(7, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogComponent_1)), i1.ɵdid(9, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.cancelButtton || _co.confirmButton); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), _co.title)); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), _co.htmlContent)); _ck(_v, 5, 0, currVal_1); }); }
export function View_DialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog", [], null, null, null, View_DialogComponent_0, RenderType_DialogComponent)), i1.ɵdid(1, 114688, null, 0, i8.DialogComponent, [i2.MAT_DIALOG_DATA, i2.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DialogComponentNgFactory = i1.ɵccf("app-dialog", i8.DialogComponent, View_DialogComponent_Host_0, { title: "title", confirmButton: "confirmButton", cancelButtton: "cancelButtton" }, {}, []);
export { DialogComponentNgFactory as DialogComponentNgFactory };
