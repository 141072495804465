<form class="no-margin" (ngSubmit)="onSubmit()" [formGroup]="impersonateForm">
    <div class="login">
        <div class="field-group">
          <input type="text" name="username" id="username" appAutoFocus formControlName="username" />
          <span>
              <a class="go-back" title="Go Back to Previous" (click)="onPrevious()">
                  <i class="fa fa-chevron-circle-left"></i>
              </a>
              <label for="username">Impersonate Username</label>
            </span>
        </div>
        <div class="form-check">
          <input class="login-check" type="checkbox" name="overrideSupport" id="overrideSupport" formControlName="overrideSupport"/>
          <label class="login-check-label" for="overrideSupport">Standard User</label>
        </div>
        <div class="button-group">
          <button id="next" type="submit" class="btn btn-lg btn-info" [disabled]="impersonateForm.invalid">Next <i class="fa fa-chevron-right"></i></button>
        </div>
    </div>
  </form>
