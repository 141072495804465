<div class="username">
    <a class="go-back" title="Go Back to Previous" (click)="onPrevious()" ><i class="fa fa-chevron-circle-left"></i></a>
    <div class="username-label">
      {{username}}
    </div>
</div>
<div *ngIf="!isLoadingUserInfo">
  <form class="no-margin" (ngSubmit)="onSubmit()" [formGroup]="passwordForm" *ngIf="!userUsesFortify; else willBeRedirectedBanner">
    <div class="login">
        <div class="field-group password">
          <label for="password">Password</label>
          <input [type]="showHide ? 'text' : 'password'" name="password" id="password" class="password" appAutoFocus formControlName="password" />
        </div>
        <a class="show-hide"
          title="Click to {{ showHide ? 'HIDE' : 'SHOW' }} password"
          (click)="showHide = !showHide">
          <i [class]="'fa ' + (showHide ? 'fa-eye' : 'fa-eye-slash')"></i>
        </a>
        <div class="button-group">
          <button type="submit" id="next" class="btn btn-lg btn-info" [disabled]="passwordForm.invalid">Next <i class="fa fa-chevron-right"></i></button>
        </div>
        <div class="link-group">
          <a href="{{ webAppUrl }}/WebForms/PasswordHelp.aspx">
            <i class="fa fa-caret-right"></i>
            Forgot Password
          </a>
        </div>
    </div>
  </form>
  <ng-template #willBeRedirectedBanner >
    <div class="redirection-banner">
      <i class="fas fa-spinner fa-pulse fa-2x"></i>
      <h6>Waiting redirection to login into Fortify ...</h6>
      <p>You will be redirected soon...</p>
    </div>
  </ng-template>
</div>
