import { MatSnackBar } from '@angular/material/snack-bar';
import { FortifyOAuthService } from './../../../auth/fortify/fortify-oauth.service';
import { AppState } from './../../../models/interfaces';
import { Store } from '@ngrx/store';
import { take, filter, pairwise } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, RoutesRecognized } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PurchaseCallbackGuard implements CanActivate {
  isSupportUser: boolean;
  constructor(
    private store: Store<AppState>,
    private router: Router,
    private fortifyOAuthService: FortifyOAuthService,
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.fortifyOAuthService.hasValidAccessToken()) {
        return true;
      } else {
        this.router.navigateByUrl('login/account')
      }
  }
}
