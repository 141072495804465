/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sso-net6-accounts.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-spinner";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/common";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./sso-net6-accounts.component";
import * as i8 from "../../services/net6-accounts.service";
import * as i9 from "../../services/auth-net-6-implicit.service";
import * as i10 from "angular-oauth2-oidc";
import * as i11 from "../../../../services/lcp/lcp.service";
var styles_SsoNet6AccountsComponent = [i0.styles];
var RenderType_SsoNet6AccountsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SsoNet6AccountsComponent, data: {} });
export { RenderType_SsoNet6AccountsComponent as RenderType_SsoNet6AccountsComponent };
function View_SsoNet6AccountsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-spinner", [["class", "example-margin mat-progress-spinner"], ["color", "primary"], ["diameter", "50"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"], [1, "aria-valuemin", 0], [1, "aria-valuemax", 0], [1, "aria-valuenow", 0], [1, "mode", 0]], null, null, i2.View_MatProgressSpinner_0, i2.RenderType_MatProgressSpinner)), i1.ɵdid(1, 114688, null, 0, i3.MatProgressSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"], diameter: [1, "diameter"], mode: [2, "mode"] }, null)], function (_ck, _v) { var currVal_7 = "primary"; var currVal_8 = "50"; var currVal_9 = "indeterminate"; _ck(_v, 1, 0, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 1).diameter; var currVal_2 = i1.ɵnov(_v, 1).diameter; var currVal_3 = ((i1.ɵnov(_v, 1).mode === "determinate") ? 0 : null); var currVal_4 = ((i1.ɵnov(_v, 1).mode === "determinate") ? 100 : null); var currVal_5 = ((i1.ɵnov(_v, 1).mode === "determinate") ? i1.ɵnov(_v, 1).value : null); var currVal_6 = i1.ɵnov(_v, 1).mode; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_SsoNet6AccountsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["class", "table-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [["class", "account-list lcp-hyperlink"], ["tabindex", "0"]], null, [[null, "click"], [null, "keyup.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setOrgCode(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("keyup.enter" === en)) {
        var pd_1 = (_co.setOrgCode(_v.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.Name; _ck(_v, 2, 0, currVal_0); }); }
function View_SsoNet6AccountsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "table-style"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SsoNet6AccountsComponent_3)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.orgList; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SsoNet6AccountsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "sso-page-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "sso-page-card sm:mx-2.28"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", "LCPtracker Logo"], ["src", "assets/logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Accounts"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "progress-spinner-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SsoNet6AccountsComponent_1)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "list-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SsoNet6AccountsComponent_2)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 0, "footer", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loading === true); _ck(_v, 7, 0, currVal_0); var currVal_1 = ((_co.loading === false) && (_co.orgList.length > 0)); _ck(_v, 10, 0, currVal_1); }, null); }
export function View_SsoNet6AccountsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sso-net6-accounts", [], null, null, null, View_SsoNet6AccountsComponent_0, RenderType_SsoNet6AccountsComponent)), i1.ɵdid(1, 114688, null, 0, i7.SsoNet6AccountsComponent, [i8.Net6AccountsService, i9.AuthNet6ImplicitService, i10.OAuthService, i11.LcpService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SsoNet6AccountsComponentNgFactory = i1.ɵccf("app-sso-net6-accounts", i7.SsoNet6AccountsComponent, View_SsoNet6AccountsComponent_Host_0, {}, {}, []);
export { SsoNet6AccountsComponentNgFactory as SsoNet6AccountsComponentNgFactory };
