import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { authNet6ConfigImplicit } from '../auth.net6.config.implicit';

@Injectable({
  providedIn: 'root'
})
export class AuthNet6ImplicitService {
  constructor(private oauthService: OAuthService) { }

  login() {
    this.oauthService.configure(authNet6ConfigImplicit);
    this.oauthService.loadDiscoveryDocument().then(res => {
      this.oauthService.initImplicitFlow();
    })
  }
}
