import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tpa-info-banner',
  templateUrl: './tpa-info-banner.component.html',
  styleUrls: ['./tpa-info-banner.component.scss']
})
export class TpaInfoBannerComponent {

  @Input()
  organizationName: string = 'Organization Name';
  redirectionLink: string = 'https://fortifyv2.lcptracker.net/Identity/Account/Manage/ExternalLogins';

  constructor() { }

}
