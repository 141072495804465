import { NgZone, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CryptoHandler } from 'src/app/models/crypto-handler';
import { LcpTrackerOAuthStorage } from './lcptracker-oauth-storage';
import { 
    OAuthService, 
    OAuthLogger, 
    UrlHelperService, 
    OAuthStorage,
    ValidationHandler, 
    AuthConfig 
} from 'angular-oauth2-oidc';

@Injectable({
    providedIn: 'root'
})
export class LcptrackerOAuthService extends OAuthService {
    constructor(
        ngZone: NgZone,
        http: HttpClient,
        @Optional() storage: LcpTrackerOAuthStorage,
        @Optional() tokenValidationHandler: ValidationHandler,
        @Optional() protected config: AuthConfig,
        urlHelper: UrlHelperService, logger: OAuthLogger, crypto: CryptoHandler) {
        super(ngZone, http, storage, tokenValidationHandler, config, urlHelper, logger, crypto);
    }
}
