/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tpa-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./tpa-info.component";
var styles_TpaInfoComponent = [i0.styles];
var RenderType_TpaInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TpaInfoComponent, data: {} });
export { RenderType_TpaInfoComponent as RenderType_TpaInfoComponent };
function View_TpaInfoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 3, 0, currVal_0); }); }
function View_TpaInfoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["The agencies below require enhanced security:"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TpaInfoComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notTPASatisdfiedAccounts; _ck(_v, 4, 0, currVal_0); }, null); }
function View_TpaInfoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Your agency "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" requires enhanced security."]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notTPASatisdfiedAccounts[0]; _ck(_v, 3, 0, currVal_0); }); }
export function View_TpaInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "tpa-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "icon-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-lock fa-xl"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 10, "div", [["class", "info-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h6", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Security enhancement"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TpaInfoComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["singularNote", 2]], null, 0, null, View_TpaInfoComponent_3)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please update your Account Settings by clicking "])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["here"])), (_l()(), i1.ɵted(-1, null, [" or reach out to your admin."]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.notTPASatisdfiedAccounts.length > 1); var currVal_1 = i1.ɵnov(_v, 8); _ck(_v, 7, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.externalLoginsLink; _ck(_v, 11, 0, currVal_2); }); }
export function View_TpaInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tpa-info", [], null, null, null, View_TpaInfoComponent_0, RenderType_TpaInfoComponent)), i1.ɵdid(1, 114688, null, 0, i3.TpaInfoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TpaInfoComponentNgFactory = i1.ɵccf("app-tpa-info", i3.TpaInfoComponent, View_TpaInfoComponent_Host_0, { notTPASatisdfiedAccounts: "notTPASatisdfiedAccounts" }, {}, []);
export { TpaInfoComponentNgFactory as TpaInfoComponentNgFactory };
