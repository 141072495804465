<div class="sso-page-container">
    <div class="sso-page-card sm:mx-2.28">
        <img src="assets/logo.png" alt="LCPtracker Logo">
        <div class="progress-spinner-container">
            <mat-progress-spinner class="example-margin" color="primary" mode="indeterminate" diameter="50">
            </mat-progress-spinner>
        </div>
        <h4>Verification</h4>
        <p>We are verifying your identity...</p>
        
    </div>
    <footer></footer>
</div>  